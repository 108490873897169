<template>
  <label class="commonInput">
    <div class="header" v-if="!!$slots.default || label || maxLength">
      <div class="label">
        <slot>
          {{ label }}
        </slot>
      </div>

      <div v-if="!!maxLength" class="maxLength">
        <b
          :class="{ maxLength_hasError: maxLength < String(modelValue).length }"
        >
          {{ String(modelValue).length }}
        </b>
        /{{ maxLength }}
      </div>
    </div>

    <div class="inputBox">
      <div v-if="!!$slots.prefix" :class="['inputPrefix', inputPrefixClass]">
        <slot name="prefix" />
      </div>

      <div v-if="!!$slots.suffix" :class="['inputSuffix', inputSuffixClass]">
        <slot name="suffix" />
      </div>

      <input
        :style="inputStyle"
        :class="inputClass"
        :value="modelValue"
        @input="updateInput"
        @keydown="keydown"
        :type="type"
        :maxlength="maxLength"
        :placeholder="placeholder"
        :required="required"
        :pattern="pattern"
        :autocomplete="autocomplete"
        v-maska="mask"
        v-bind="$attrs"
      />
    </div>

    <div v-if="!!error" class="errorBox">
      {{ error }}
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";

export default defineComponent({
  name: "CommonInput",
  components: {},

  emits: ["update:modelValue", "keydown", "innerRef"],
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  props: {
    modelValue: {
      type: [String, Number] as PropType<string | number>,
      required: true,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    height: {
      type: [Number, String],
      required: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    error: {
      type: String,
      required: false,
      default: "",
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorLabel: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    paddingLeft: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
    },
    mask: {
      type: [String, Object],
      // default: () => ({ mask: "Z*", tokens: { Z: { pattern: /[\s\S]/ } } }),
      default: () => null,
    },
    fontWeight: {
      type: [Number, String],
    },
    opacityText: {
      type: Boolean,
      default: false,
    },
    autocomplete: String,
    inputPrefixClass: String,
    inputSuffixClass: String,
    label: String,
  },
  setup(props, { emit, slots }) {
    const inputStyle = computed(() => ({
      height: props.height || "",
      fontWeight: props.fontWeight || "",
    }));
    const inputClass = computed(() => [
      "input",
      {
        hasError:
          props.hasError ||
          !!props.error ||
          (props.maxLength &&
            props.maxLength < String(props.modelValue).length),
        withPrefix: !!slots.prefix,
        withSuffix: !!slots.suffix,
        opacityText: props.opacityText,
      },
    ]);

    return {
      updateInput(e: any) {
        emit("update:modelValue", e.target.value);
      },
      keydown(e: any) {
        emit("keydown", e);
      },
      inputClass,
      inputStyle,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonInput {
  display: block;
  width: 100%;
}

.header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.label {
  getFont("text_16");
}

.maxLength {
  margin-left: auto;
  transition: all 0.1s;
}

.maxLength_hasError {
  color: $colorDanger;
}

.inputBox {
  width: 100%;
  position: relative;
}

.inputPrefix, .inputSuffix {
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  opacity: 0.4;
  getFontText();
}

.inputPrefix {
  left: 12px;
}

.inputSuffix {
  display: flex;
  align-items: center;
  right: 12px;
}

.input {
  display: block;
  width: 100%;
  border-radius: 10px;
  color: $colorFontBase;
  border: 1px solid $colorBlack;
  padding: 12px 18px;
  transition: all 0.1s;
  background-color: $colorFiller;
  getFont("text_24")

  &:focus {
    outline: none;
    border: 2px solid $colorPrimary;
  }

  &.withPrefix {
    padding-left: 30px;
  }

  &.withSuffix {
    padding-right: 20px;
  }

  &.hasError {
    border: 2px solid $colorDanger;

    &:not(:last-child) {
      border-radius: 4px 4px 0 0;
    }
  }

  &.opacityText {
    color: $colorDarkGrey;
  }

  +mediaTablet() {
    padding: 1.1vw 1.3vw;
  }
}

.dpo .input {
  &:focus {
    border: 2px solid $colorPrimaryDpo;
  }
}

.errorBox {
  margin-top: 9px;
  color: $colorDanger;
  getFont("text_13");
  text-align: left;
}
</style>
