<template>
  <div class="CommonHomeworkStatus__statusBox">
    <h5 class="CommonHomeworkStatus__contentTitle">
      Статус домашнего задания:
    </h5>

    <div class="CommonHomeworkStatus__status">
      <div
        class="CommonHomeworkStatus__statusItem"
        v-for="(status, i) in statuses"
        :key="`status-${i}`"
      >
        <p
          :class="[
            'CommonHomeworkStatus__statusName',
            { CommonHomeworkStatus__statusName_active: status.isActive },
          ]"
        >
          {{ status.label }}
        </p>

        <div class="CommonHomeworkStatus__statusProgress">
          <div
            v-if="i === 0"
            class="CommonHomeworkStatus__statusProgressCircle CommonHomeworkStatus__statusProgressCircle_left"
          />

          <div
            :class="[
              'CommonHomeworkStatus__statusProgressLine',
              {
                CommonHomeworkStatus__statusProgressLine_active:
                  status.isActive,
              },
            ]"
          ></div>
          <div class="CommonHomeworkStatus__statusProgressCircle" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CommonEducationStatusProps } from "./types";

export default defineComponent({
  name: "CommonHomeworkStatus",

  props: {
    label: {
      type: String as PropType<CommonEducationStatusProps["label"]>,
    },
    subLabel: {
      type: String as PropType<CommonEducationStatusProps["subLabel"]>,
    },
    status: {
      type: String as PropType<CommonEducationStatusProps["status"]>,
    },
    date: {
      type: String as PropType<CommonEducationStatusProps["date"]>,
    },
  },

  components: {},

  setup() {
    const statuses = [
      {
        label: "Задание не выполнено",
        isActive: true,
      },
      {
        label: "Задание выполнено",
        isActive: false,
      },
    ];

    return {
      statuses,
    };
  },
});
</script>

<style lang="stylus" scoped>
.CommonHomeworkStatus {
  &__status {
    display: flex;
  }

  &__statusItem {
    flex-grow: 1;
  }

  &__statusName {
    text-align: center;
    font-weight: bold;

    &_active {
      color: $colorPrimary;
    }
  }

  &__statusProgress {
    position: relative;
  }

  &__statusProgressLine {
    width: 100%;
    height: 22px;
    border-radius: 10px;
    border: 2px solid $colorPrimary;
    background-color: $colorFiller;

    &_active {
      background-color: $colorPrimary;
    }
  }

  &__statusProgressCircle {
    position: absolute;
    top: -8.5px;
    right: -17px;
    width: 34px;
    height: 34px;
    border-radius: 100px;
    border: 2px solid $colorPrimary;
    background-color: $colorFiller;
    z-index: 1;

    &_left {
      left: -17px;
    }
  }
}
.dpo .CommonHomeworkStatus {
  &__statusName {
    &_active {
      color: $colorPrimaryDpo;
    }
  }

  &__statusProgressLine {
    border: 2px solid $colorPrimaryDpo;
    background-color: $colorFiller;

    &_active {
      background-color: $colorPrimaryDpo;
    }
  }

  &__statusProgressCircle {
    border: 2px solid $colorPrimaryDpo;
    background-color: $colorFiller;
  }
}
</style>
