import { RouteName } from "@/constants";

export const navList = [
  {
    id: 1,
    label: "Объявления",
    pathName: RouteName.ADVERT,
  },
  {
    id: 2,
    label: "Нормативные документы",
    pathName: RouteName.DOCS,
  },
];
