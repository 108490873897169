<template>
  <LayoutBase v-if="hasInformationMain">
    <div class="informationPage">
      <CommonNavigation :nav-list="navList" />
    </div>
  </LayoutBase>

  <router-view v-else />
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { navList } from "./constants";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants/RouteName";
import { CommonNavigation } from "@/components/CommonNavigation";

export default defineComponent({
  name: "InformationPage",

  components: {
    LayoutBase,
    CommonNavigation,
  },

  setup() {
    const route = useRoute();

    const hasInformationMain = computed(
      () => route?.name === RouteName.INFORMATION
    );

    return {
      navList,
      hasInformationMain,
    };
  },
});
</script>

<style lang="stylus" scoped>
.informationPage {
}
</style>
