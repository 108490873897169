<template>
  <LayoutBase>
    <div class="portfolioPage">
      <CommonDropdown
        :label="dropdown.label"
        isSelectGrey
        v-for="(dropdown, index) in dropdownsList"
        :key="dropdown.label"
      >
        <div
          class="portfolioPage__linkBox"
          v-for="(subLabel, i) in dropdown.subLabelsList"
          :key="subLabel.title"
        >
          <div class="portfolioPage__link" v-if="index === 2">
            <CommonDownloadFile label="Прикрепить файл" file="#" />
          </div>

          <CommonDropdownSecond
            :label="subLabel.title"
            class="portfolioPage__link"
            v-else
          >
            <div v-if="index === 0">
              <EducationWorks v-if="i === 0" />

              <PracticeReports v-else-if="i === 1" />

              <GraduationWork v-else />
            </div>

            <div v-else-if="index === 1">
              <PublicationsWorks v-if="i === 0" />

              <OtherWorks v-else-if="i === 1" />
            </div>

            <div v-else>
              <CommonDownloadFile label="Прикрепить файл" file="#" />
            </div>
          </CommonDropdownSecond>

          <hr
            class="portfolioPage__linkHr"
            v-if="
              i !== dropdown.subLabelsList.length - 1 &&
              dropdown.subLabelsList.length > 1
            "
          />
        </div>
      </CommonDropdown>
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { CommonDropdown } from "@/components/CommonDropdown";
import { dropdownsList } from "./constants";
import { CommonDropdownSecond } from "@/components/CommonDropdownSecond";
import { CommonDownloadFile } from "@/components/CommonDownloadFile";
import { EducationWorks } from "./components/EducationWorks";
import { PracticeReports } from "./components/PracticeReports";
import { GraduationWork } from "./components/GraduationWork";
import { PublicationsWorks } from "./components/PublicationsWorks";
import { OtherWorks } from "./components/OtherWorks";

export default defineComponent({
  name: "PortfolioPage",

  components: {
    LayoutBase,
    CommonDropdown,
    CommonDropdownSecond,
    CommonDownloadFile,
    EducationWorks,
    PracticeReports,
    GraduationWork,
    PublicationsWorks,
    OtherWorks,
  },

  setup() {
    return {
      dropdownsList,
    };
  },
});
</script>

<style lang="stylus" scoped>
.portfolioPage {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__linkBox {
    padding: 10px 30px 0 30px;
    &:hover {
      background-color: $colorLightGrey;
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding-bottom: 10px;
  }

  &__linkHr {
    margin: 0;
    width: 100%;
    height: 3px;
    background-color: $colorDarkGrey;
    border: none;
  }

  +mediaTablet() {
    &__linkBox {
      padding: 1vw 1vw 0 1vw;
    }
    &__link {
      padding-bottom: 1vw;
    }
  }
}
</style>
