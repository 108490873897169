<template>
  <div class="commonBannerCheckDocks">
    <div class="commonBannerCheckDocks__wrap" />
    <div class="commonBannerCheckDocks__content">
      <svg class="commonBannerCheckDocks__icon">
        <use xlink:href="#check-docs-icon--sprite" />
      </svg>

      <h6 class="commonBannerCheckDocks__title">
        Вы можете<br />проверить свои<br />документы<br />в ФИС ФРДО
      </h6>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import "@/assets/img/icons/check-docs-icon.svg?sprite";

export default defineComponent({
  name: "CommonBannerCheckDocks",
});
</script>

<style lang="stylus" scoped>
.commonBannerCheckDocks {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  gap: 1vw;
  padding: 50px;
  height: 541px;
  background-image: url("@/assets/img/check-docs-img.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 30px;
  color: $colorFiller;

  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorFillBlue;
    opacity: .85;
    border-radius: 30px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vw;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__icon {
    width: 145px;
    height: 109px;
  }

  &__title {
    text-align: center;
    font-weight: bold;
  }
}
</style>
