export enum HomeworkStatuses {
  assigned = "Назначено",
  sent = "Отправлено",
  examination = "Проверка",
  notComplete = "Не выполнено",
  complete = "Выполнено",
  remake = "Переделать",
}

export const homeworks = [
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "assigned",
    statusLabel: HomeworkStatuses.assigned,
    id: 23,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "sent",
    statusLabel: HomeworkStatuses.sent,
    id: 24,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "examination",
    statusLabel: HomeworkStatuses.examination,
    id: 25,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "notComplete",
    statusLabel: HomeworkStatuses.notComplete,
    id: 26,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "complete",
    statusLabel: HomeworkStatuses.complete,
    id: 27,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "remake",
    statusLabel: HomeworkStatuses.remake,
    id: 28,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "assigned",
    statusLabel: HomeworkStatuses.assigned,
    id: 29,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "notComplete",
    statusLabel: HomeworkStatuses.notComplete,
    id: 30,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "complete",
    statusLabel: HomeworkStatuses.complete,
    id: 31,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "remake",
    statusLabel: HomeworkStatuses.remake,
    id: 32,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "assigned",
    statusLabel: HomeworkStatuses.assigned,
    id: 33,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "notComplete",
    statusLabel: HomeworkStatuses.notComplete,
    id: 34,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "complete",
    statusLabel: HomeworkStatuses.complete,
    id: 35,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "remake",
    statusLabel: HomeworkStatuses.remake,
    id: 36,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "assigned",
    statusLabel: HomeworkStatuses.assigned,
    id: 37,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "notComplete",
    statusLabel: HomeworkStatuses.notComplete,
    id: 38,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "complete",
    statusLabel: HomeworkStatuses.complete,
    id: 39,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "remake",
    statusLabel: HomeworkStatuses.remake,
    id: 40,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "assigned",
    statusLabel: HomeworkStatuses.assigned,
    id: 41,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "notComplete",
    statusLabel: HomeworkStatuses.notComplete,
    id: 42,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "complete",
    statusLabel: HomeworkStatuses.complete,
    id: 43,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "remake",
    statusLabel: HomeworkStatuses.remake,
    id: 44,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "assigned",
    statusLabel: HomeworkStatuses.assigned,
    id: 45,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "notComplete",
    statusLabel: HomeworkStatuses.notComplete,
    id: 46,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "complete",
    statusLabel: HomeworkStatuses.complete,
    id: 47,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "remake",
    statusLabel: HomeworkStatuses.remake,
    id: 48,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "assigned",
    statusLabel: HomeworkStatuses.assigned,
    id: 49,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "notComplete",
    statusLabel: HomeworkStatuses.notComplete,
    id: 50,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "complete",
    statusLabel: HomeworkStatuses.complete,
    id: 51,
  },
  {
    label: "Домашнее задание №1",
    deadline: "9.08 — 11.08",
    status: "remake",
    statusLabel: HomeworkStatuses.remake,
    id: 52,
  },
];
