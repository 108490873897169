import {
  getFetchState,
  getFetchMutations,
  basicFetch,
} from "@/store/storeUtils";
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

import { defineModule } from "direct-vuex";
import { modActionCtx, modGetterCtx } from "@/store/index";
import { api } from "@/utils/apiInstance";
import { path } from "rambda";

import { Statuses } from "@/constants/statuses";

const programsStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState(),
    listData: [],
    pickedProgramId: null,
  },

  getters: {
    data(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.response);

      return result;
    },
    listData(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.listData);

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = Statuses.INIT;
    },
    SET_DATA(state, resp) {
      state.response = resp;
    },
    SET_LIST_DATA(state, resp) {
      state.listData = resp;
    },
    SET_PICKED_PROGRAM(state, id) {
      state.pickedProgramId = id;
    },
  },
  actions: {
    async getPrograms(
      ctx,
      payload: {
        program_id?: number;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      if (payload.program_id !== state.pickedProgramId) {
        commit.SET_PICKED_PROGRAM(payload.program_id);

        await basicFetch({
          method: () =>
            api.sendByGet(
              window.location.hostname === "localhost"
                ? `/api/programs/${payload.program_id}`
                : `/backend/api/programs/${payload.program_id}`
            ),
          setState: ({ fetchState }) => {
            console.log(fetchState);
          },
          callback: (status, resp) => {
            commit.SET_DATA(resp);
          },
        });
      }
    },

    async getDegreeList(
      ctx,
      payload: {
        degree_id?: number;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: () =>
          api.sendByGet(
            window.location.hostname === "localhost"
              ? `/api/programs?filter[degree_id]=${payload.degree_id}`
              : `/backend/api/programs?filter[degree_id]=${payload.degree_id}`
          ),
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: (status, resp) => {
          commit.SET_LIST_DATA(resp);
        },
      });
    },
  },
});

export default programsStore;
const getterCtx = (args: [any, any, any, any]) =>
  modGetterCtx(args, programsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, programsStore);
