<template>
  <div class="commonCalendar">
    <h3 class="commonCalendar__title">Расписание</h3>

    <div class="commonCalendar__content">
      <div class="commonCalendar__calendar">
        <VCalendar
          transparent
          borderless
          expanded
          :attributes="attrs"
          class="commonCalendar__calendarrrr"
        >
          <template #footer>
            <a href="#" class="commonCalendar__download">
              Скачать расписание на 1 семестр

              <svg class="commonCalendar__icon">
                <use xlink:href="#file-icon--sprite" />
              </svg>
            </a>
          </template>
        </VCalendar>
      </div>

      <div class="commonCalendar__events">
        <h4 class="commonCalendar__eventsTitle">
          Дисциплина: Математическое<br />и компьютерное моделирование
        </h4>

        <hr class="commonCalendar__hr" />

        <h6 class="commonCalendar__eventsSecondTitle">
          ПРАКТИЧЕСКОЕ ЗАНЯТИЕ (ОНЛАЙН) + ЗАЧЁТ
        </h6>

        <div class="commonCalendar__eventsList">
          <div
            class="commonCalendar__eventsItem"
            v-for="event in eventsList"
            :key="event.name"
          >
            <p class="commonCalendar__eventTime">{{ event.time }}</p>

            <p class="commonCalendar__eventName">{{ event.name }}</p>
          </div>
        </div>

        <div class="commonCalendar__eventsButtonsBox" v-if="isShowButtons">
          <CommonButtonPrimary @click="goToLesson">
            Подключиться к занятию
          </CommonButtonPrimary>

          <CommonButtonSecondary @click="goToHomeworkpage">
            Список домашних заданий
          </CommonButtonSecondary>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType } from "vue";
import type { CommonCalendarProps } from "./types";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { CommonButtonSecondary } from "@/components/CommonButtonSecondary";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants";

import "@/assets/img/icons/file-icon.svg?sprite";

export default defineComponent({
  name: "CommonCalendar",

  components: {
    CommonButtonPrimary,
    CommonButtonSecondary,
  },

  props: {
    eventsList: {
      type: Array as PropType<CommonCalendarProps["eventsList"]>,
      default: () => [],
      required: true,
    },
    lessonId: {
      type: Number as PropType<CommonCalendarProps["lessonId"]>,
      required: true,
    },
    isShowButtons: {
      type: Boolean as PropType<CommonCalendarProps["isShowButtons"]>,
      default: true,
    },
  },

  setup(props) {
    const router = useRouter();

    const attrs = ref([
      {
        key: "today",
        highlight: {
          class: "calendar__today_highlight",
        },
        dates: new Date(),
      },
      {
        key: "eventDay",
        highlight: {
          class: "calendar__eventDay_highlight",
        },
        content: {
          class: "calendar__eventDay_content",
        },
        dates: [
          new Date(2024, 7, 7),
          new Date(2024, 7, 13),
          new Date(2024, 7, 22),
        ],
      },
    ]);

    const goToHomeworkpage = () => router.push({ name: RouteName.HOMEWORKS });
    const goToLesson = () =>
      router.push({ name: RouteName.LESSON, query: { id: props.lessonId } });

    return {
      attrs,
      goToHomeworkpage,
      goToLesson,
    };
  },
});
</script>

<style lang="stylus">
.vc-title {
  text-transform: uppercase;
  getFontH4();
}
.vc-weeks {
  padding: 30px!important;

  +mediaTablet() {
    padding: 3vw!important;
  }
}

.calendar__today_highlight {
  background-color: $colorPrimary!important;
}
.dpo .calendar__today_highlight {
  background-color: $colorPrimaryDpo!important;
}

.calendar__eventDay_highlight {
  background-color: $colorFiller!important;
  border: 1px solid $colorFontBase;
}

.calendar__eventDay_content {
  color: $colorFontBase!important;
}

.vc-arrow {
  position: relative;
  top: 130px;
  z-index: 1;
}

.vc-weekday-7,
.vc-weekday-1,
.weekday-7 .vc-day-content,
.weekday-1 .vc-day-content {
  color: $colorDanger!important;
}
</style>

<style lang="stylus" scoped>
.commonCalendar {
  &__title {
    padding: 35px 0;
    text-align: center;
    font-weight: normal;
    background-color: $colorPrimary;
    color: $colorFiller;
  }

  &__calendar {
    width: 50%;
  }

  &__content {
    padding: 30px 0;
    display: flex;
    gap: 4vw;
    background-color: $colorBgGrey;
  }

  &__calendar,
  &__events {
    flex-grow: 1;
  }

  &__download {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 30px;
    text-decoration: none;
    text-wrap: nowrap;
    color: $colorPrimary;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__events {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__eventsTitle {
    color: $colorPrimary;
  }

  &__hr {
    width: 100%;
    height: .5vw;
    background-color: $colorPrimary;
    border: none;
  }

  &__eventsItem {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__eventTime {
    font-weight: bold;
    color: $colorPrimary;
  }

  &__eventsButtonsBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  +mediaTablet() {
    &__content {
      padding: 3vw 0;
    }

    &__events {
      gap: 1vw;
    }

    &__eventsTitle {
      padding-right: 3vw;
    }

    &__download {
      padding: 0 3vw;
    }

    &__eventsItem,
    &__eventsButtonsBox {
      padding-right: 3vw;
      gap: .8vw;
    }
  }
}

.dpo .commonCalendar {
  &__title {
    background-color: $colorPrimaryDpo;
  }

  &__download {
    color: $colorPrimaryDpo;
  }

  &__eventsTitle {
    color: $colorPrimaryDpo;
  }

  &__hr {
    background-color: $colorPrimaryDpo;
  }

  &__eventTime {
    color: $colorPrimaryDpo;
  }
}
</style>
