export const words = ["Афины", "Колорадо", "Америка", "Индия", "Франция"];
export const questions = [
  "Где находится Акрополь?",
  "Где расположена столица Большого каньона?",
  "Где находится Статуя Свободы?",
  "Где находится Тадж-Махал?",
  "Где находится Эйфелева башня?",
];
export const pageQuestion =
  "Перед вами тест с пропусками слов. Вам необходимо вписать в пропуски недостающие слова и нажать на кнопку «отправить ответ». Система сразу подскажет правильно ли вы выполнили задание. На выполнение вам дается неограниченное кол-во попыток. Выполнить задание можно в любое время, но до наступления итогового теста.";
