<template>
  <div class="commonChatItem">
    <CommonDropdownSecond class="commonChatItem__dropdown" :label="label">
      <slot />
    </CommonDropdownSecond>

    <div class="commonChatItem__unreadCount" v-if="unreadCount !== 0">
      {{ unreadCount }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType } from "vue";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/RouteName";
import { CommonDropdownSecond } from "@/components/CommonDropdownSecond";
import type { CommonChatItemProps } from "./types";

export default defineComponent({
  name: "CommonChatItem",

  props: {
    label: {
      type: String as PropType<CommonChatItemProps["label"]>,
      default: "",
    },
    unreadCount: {
      type: Number as PropType<CommonChatItemProps["unreadCount"]>,
      default: 0,
    },
  },

  components: {
    CommonDropdownSecond,
  },

  setup() {
    const router = useRouter();

    const isShowTextForVideo = ref<boolean>(false);

    const toggleTextForVideo = () => {
      isShowTextForVideo.value = !isShowTextForVideo.value;
    };

    const goToTest = () => router.push({ name: RouteName.TEST });

    return {
      isShowTextForVideo,
      toggleTextForVideo,
      goToTest,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonChatItem {
  position: relative;

  &__dropdown {
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
      background-color: $colorLightGrey;
    }
  }

  &__unreadCount {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    right: calc(42px + 15px);
    top: 10px;
    color: $colorFiller;
    background-color: $colorDanger;
    border-radius: 100px;
  }

  +mediaTablet() {
    &__dropdown {
      padding: 1vw 1.5vw;
    }

    &__unreadCount {
      right: calc(42px + 1.5vw);
      top: 1vw;
    }
  }
}
</style>
