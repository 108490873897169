<template>
  <div class="commonDownloadFile">
    <a :href="file" class="commonDownloadFile__download">
      {{ label }}

      <svg class="commonDownloadFile__icon">
        <use xlink:href="#file-icon--sprite" />
      </svg>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CommonDownloadFileProps } from "./types";

import "@/assets/img/icons/arrow-second-top-icon.svg?sprite";
import "@/assets/img/icons/arrow-second-bottom-icon.svg?sprite";

export default defineComponent({
  name: "CommonDownloadFile",

  props: {
    label: {
      type: String as PropType<CommonDownloadFileProps["label"]>,
    },
    file: {
      type: String as PropType<CommonDownloadFileProps["file"]>,
    },
  },
});
</script>

<style lang="stylus" scoped>
.commonDownloadFile {
  &__download {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    text-wrap: nowrap;
    color: $colorPrimary;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}

.dpo .commonDownloadFile {
  color: $colorPrimaryDpo;
}
</style>
