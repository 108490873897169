export const bannerTitle = "Уважаемый слушатель!";
export const bannerText = `
  О готовности вашего удостоверения и возможности его получения я сообщу вам лично на почту. Документ вы можете скачать в электронном виде. Однако помните, что он является не действительным без наличия бумажного бланка!
`;

export const docsItems = [
  {
    label: "Практический онлайн-курс для руководителей и топ-менеджеров",
    subLabel: "23/01/2023 | 185.95 KB | mov",
  },
  {
    label: "Практический онлайн-курс для руководителей и топ-менеджеров",
    subLabel: "23/01/2023 | 185.95 KB | mov",
  },
  {
    label: "Практический онлайн-курс для руководителей и топ-менеджеров",
    subLabel: "23/01/2023 | 185.95 KB | mov",
  },
];

export const questionsList = [
  {
    question: "Что нужно иметь при себе для получения удостоверения о ПК? ",
    answer:
      "После завершения обучения по программе ординатуры выпускники могут работать на следующих должностях:",
  },
  {
    question: "Что нужно иметь при себе для получения удостоверения о ПК? ",
    answer:
      "После завершения обучения по программе ординатуры выпускники могут работать на следующих должностях:",
  },
  {
    question: "Может ли получить мое удостоверение другой человек?",
    answer:
      "После завершения обучения по программе ординатуры выпускники могут работать на следующих должностях:",
  },
  {
    question: "Можно ли получить документ по почте?",
    answer:
      "После завершения обучения по программе ординатуры выпускники могут работать на следующих должностях:",
  },
  {
    question: "К кому обращаться по вопросам выдачи?",
    answer:
      "После завершения обучения по программе ординатуры выпускники могут работать на следующих должностях:",
  },
];
