<template>
  <main class="profileDocsDpo">
    <div class="profileDocsDpo__left">
      <CommonBannerIiMessage :title="bannerTitle" :text="bannerText" />

      <CommonButtonPrimary class="profileDocsDpo__btn">
        Сформировать отчет по документам
      </CommonButtonPrimary>

      <div class="profileDocsDpo__container">
        <div class="profileDocsDpo__item">
          <h6 class="profileDocsDpo__title">Удостоверения</h6>

          <hr class="profileDocsDpo__hr" />

          <div
            class="profileDocsDpo__doc"
            v-for="doc in docsItems"
            :key="doc.label"
          >
            <CommonDocsDownload
              :label="doc.label"
              :sub-label="doc.subLabel"
              class="profileDocsDpo__commonDocsDownload"
            />

            <hr class="profileDocsDpo__hr" />
          </div>
        </div>

        <div class="profileDocsDpo__item">
          <h6 class="profileDocsDpo__title">Сертификаты</h6>

          <hr class="profileDocsDpo__hr" />

          <div
            class="profileDocsDpo__doc"
            v-for="doc in docsItems"
            :key="doc.label"
          >
            <CommonDocsDownload
              :label="doc.label"
              :sub-label="doc.subLabel"
              class="profileDocsDpo__commonDocsDownload"
            />

            <hr class="profileDocsDpo__hr" />
          </div>
        </div>

        <div class="profileDocsDpo__item">
          <h6 class="profileDocsDpo__title">Дипломы</h6>

          <hr class="profileDocsDpo__hr" />

          <div
            class="profileDocsDpo__doc"
            v-for="doc in docsItems"
            :key="doc.label"
          >
            <CommonDocsDownload
              :label="doc.label"
              :sub-label="doc.subLabel"
              class="profileDocsDpo__commonDocsDownload"
            />

            <hr class="profileDocsDpo__hr" />
          </div>
        </div>
      </div>

      <CommonLocation />

      <CommonQuestion
        :question="question.question"
        :answer="question.answer"
        v-for="question in questionsList"
        :key="question.question"
      />
    </div>

    <div class="profileDocsDpo__right">
      <CommonBannerYourPoints v-if="0" :pointsCount="24" />

      <CommonBannerChatBot />

      <CommonBannerYourMentor />

      <CommonBannerCheckDocks />
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CommonBannerIiMessage } from "@/components/CommonBannerIiMessage";
import { CommonDocsDownload } from "@/components/CommonDocsDownload";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import CommonBannerChatBot from "@/components/CommonBannerChatBot/index.vue";
import CommonBannerYourMentor from "@/components/CommonBannerYourMentor/index.vue";
import { CommonBannerYourPoints } from "@/components/CommonBannerYourPoints";
import { CommonBannerCheckDocks } from "@/components/CommonBannerCheckDocks";
import { CommonLocation } from "@/components/CommonLocation";
import { docsItems, bannerTitle, bannerText, questionsList } from "./constants";
import { CommonQuestion } from "@/components/CommonQuestion";

export default defineComponent({
  name: "ProfileDocsDpo",

  components: {
    CommonBannerYourPoints,
    CommonBannerChatBot,
    CommonBannerYourMentor,
    CommonBannerCheckDocks,
    CommonBannerIiMessage,
    CommonDocsDownload,
    CommonButtonPrimary,
    CommonLocation,
    CommonQuestion,
  },

  setup() {
    return {
      docsItems,
      bannerTitle,
      bannerText,
      questionsList,
    };
  },
});
</script>

<style lang="stylus">
.profileDocsDpo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5vw;

  &__left,
  &__right {
    flex-grow: 1;
  }

  &__btn {
    width: fit-content;
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    max-width: 315px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 25px;

    +mediaTablet() {
      gap: 2.7vw;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__hr {
    margin: 10px 0;
  }

  +mediaTablet() {
    &__container {
      gap: 2.8vw;
    }

    &__title {
      margin-bottom: 1.2vw;
    }

    &__hr {
      margin: .9vw 0;
    }
  }
}
</style>
