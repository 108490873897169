<template>
  <div class="commonCrossword">
    <div id="ctnr" />

    <div class="commonCrossword__questionsContainer">
      <div
        class="commonCrossword__questionBox"
        v-for="(question, i) in questions"
        :key="question"
      >
        <p class="commonCrossword__question">{{ i + 1 }}. {{ question }}</p>

        <input
          class="commonCrossword__answer"
          v-model="values[i].value"
          readonly
          placeholder="Ответ"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import CWG from "cwg";
import { g } from "gelerator";

export default defineComponent({
  name: "CommonCrossword",

  components: {},

  props: {
    words: {
      type: Array,
      required: true,
    },

    questions: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    // const words = ["do", "not"];

    // const result = CWG(words);
    // console.log(result);

    const values = ref([]);

    values.value = props.words.map((item) => {
      return {
        word: item,
        valuesObj: {},
        value: undefined,
      };
    });

    const gen = () => {
      const keyEl = document.getElementById("key");
      const ctnrEl = document.getElementById("ctnr");

      const result = CWG(props.words);

      ctnrEl.style = `height: ${40 * result.height + 4}px; width: ${
        30 * result.width + 4
      } px`;

      const hint = g({ id: "hint" })();
      document.body.appendChild(hint);

      result.ownerMap?.length &&
        result.ownerMap.forEach((line, y, index) => {
          line?.length &&
            line.forEach((obj, x) => {
              let startValue;
              let elemValue = {
                xValue: null,
                yValue: null,
              };

              result?.positionObjArr.forEach((item, i) => {
                if (!startValue) {
                  if (
                    String(item.xNum) === String(x) &&
                    String(item.yNum) === String(y)
                  ) {
                    startValue = {
                      hasStart: true,
                      count: i,
                    };
                  }
                }

                if (item.isHorizon) {
                  if (String(item.yNum) === String(y)) {
                    elemValue.yValue = i;
                  }
                } else {
                  if (String(item.xNum) === String(x)) {
                    elemValue.xValue = i;
                  }
                }
              });

              ctnrEl.appendChild(
                g(
                  {
                    id: x + "" + y,
                    "data-v": obj.v === 0 ? "0" : obj.v,
                    "data-h": obj.h === 0 ? "0" : obj.h,
                    "data-x": String(x),
                    "data-y": String(y),
                    "data-vidx": String(obj.vIdx),
                    "data-hidx": String(obj.hIdx),
                    "data-xWord":
                      elemValue?.xValue === 0 ? "0" : elemValue?.xValue,
                    "data-yWord":
                      elemValue?.yValue === 0 ? "0" : elemValue?.yValue,
                    maxlength: 1,
                    autocomplete: "disabled",
                    // value: startValue?.hasStart ? startValue?.count + 1 : "",
                    class: "commonCrossword__crosswordItem",
                    style: {
                      left: `${40 * x}px`,
                      top: `${40 * y}px`,
                    },
                    ...(!elemValue?.isHorizon
                      ? {
                          "data-xValue": elemValue?.value,
                        }
                      : {
                          "data-yValue": elemValue?.value,
                        }),
                  },
                  "input"
                )()
              );

              if (startValue?.hasStart) {
                ctnrEl.appendChild(
                  g({
                    id: `answer-${x}${y}`,
                    class: "commonCrossword__answerCount",
                    style: {
                      left: `${40 * x}px`,
                      top: `${40 * y}px`,
                      zIndex: 1000,
                    },
                  })(),
                  "div"
                );
              }

              const countDiv = document.getElementById(`answer-${x}${y}`);
              if (countDiv) {
                countDiv.textContent = startValue?.count + 1;
              }
            });
        });

      let doinVertically = null;

      ctnrEl.addEventListener("click", (e) => {
        const el = e.target;
        if (el.nodeName !== "INPUT") return (hint.innerHTML = "");
        const x = +el.dataset.x;
        const y = +el.dataset.y;

        const afterVerticalElem = document.getElementById(`${x}${y + 1}`);
        const afterHorisontalElem = document.getElementById(`${x + 1}${y}`);

        if (afterVerticalElem) doinVertically = true;
        else if (afterHorisontalElem) doinVertically = false;
        else doinVertically = null;
      });

      ctnrEl.addEventListener("input", (e) => {
        const el = e.target;
        const x = +el.dataset.x;
        const y = +el.dataset.y;

        if (el.dataset.yword) {
          if (values.value[el.dataset.yword]) {
            values.value[el.dataset.yword].valuesObj[el.dataset.hidx] =
              el.value;

            values.value[el.dataset.yword].value = Object.values(
              values.value[el.dataset.yword].valuesObj
            ).join("");
          }
        }

        if (el.dataset.xword) {
          if (values.value[el.dataset.xword]) {
            values.value[el.dataset.xword].valuesObj[el.dataset.vidx] =
              el.value;

            values.value[el.dataset.xword].value = Object.values(
              values.value[el.dataset.xword].valuesObj
            ).join("");
          }
        }

        const findNextEl = (x, y, v) => {
          const rtnEl = document.getElementById(`${x + +!v}${y + +v}`);
          if (!rtnEl) return el.blur();
          if (rtnEl.value) return findNextEl(x + +!v, y + +v, v);
          return rtnEl;
        };
        const nextEl = findNextEl(x, y, doinVertically);
        if (nextEl) {
          nextEl.focus();
        }
      });
    };

    onMounted(() => gen());

    return {
      values,
      gen,
    };
  },
});
</script>

<style>
.commonCrossword__answerCount {
  position: absolute;
  width: 20px;
  height: 20px;
}
.commonCrossword__crosswordItem {
  box-sizing: border-box;
  display: block;
  position: absolute;
  font-size: 20px;
  line-height: 20px;
  height: 39px;
  width: 39px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  border: 1px solid #000;
  background-color: #fff;
  padding: 0;
}

.commonCrossword__crosswordItem:focus {
  z-index: 2;
  outline: 0 0 4px #fff;
  border-color: #91b9ff;
}

#ctnr {
  width: 40%;
  box-sizing: border-box;
  position: relative;
  background-color: #252b44;
}

.hint {
  padding: 20px;
  font-size: 20px;
}
</style>

<style lang="stylus" scoped>
.commonCrossword {
  display: flex;
  gap: 30px;

  &__questionBox {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__question {
    width: 200px;
  }

  &__answer {
    padding: 10px 15px;
    background-color: $colorFiller;
    border-radius: 10px;
    border: 1px solid $colorBlack;
  }
}
</style>
