<template>
  <div class="homeworkSingle">
    <h6 class="homeworkSingle__secondTitle">
      Дисциплина: Математическое и компьютерное<br />моделирование
    </h6>

    <div class="homeworkSingle__content">
      <h5
        class="homeworkSingle__contentTitle homeworkSingle__contentTitle_green"
      >
        {{ homework.label }}
      </h5>

      <iframe
        class="homeworkSingle__video"
        src="https://rutube.ru/play/embed/9fa66fc2e001793df1af22f0f26947ba/"
        frameBorder="0"
        allow="clipboard-write; autoplay"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      />

      <h5 class="homeworkSingle__contentTitle">Задача:</h5>

      <p>
        Поставленные перед нами задачи требуют тщательного анализа и постановки
        ясных целей. Задание, которое мы получили, является ключевым моментом
        для успешного выполнения поставленных перед нами задач. Каждый шаг
        должен быть продуман и точно выполнен, чтобы достичь желаемого
        результата. Решение поставленных задач требует не только умения
        анализировать информацию, но и умения выстраивать логическую цепочку
        действий. Успешное выполнение задания зависит от нашей грамотности и
        профессионализма.
      </p>

      <div class="homeworkSingle__answerBox">
        <h5 class="homeworkSingle__contentTitle">Ответ</h5>

        <textarea
          class="homeworkSingle__textarea"
          placeholder="Напишите ваш ответ"
        />

        <CommonDownloadFile label="Прикрепить файл" file="#" />
      </div>

      <div class="homeworkSingle__buttonsBox">
        <CommonButtonPrimary class="homeworkSingle__button">
          Отправить ответ
        </CommonButtonPrimary>
      </div>

      <div class="homeworkSingle__statusBox">
        <h5 class="homeworkSingle__contentTitle">Статус домашнего задания:</h5>

        <div class="homeworkSingle__status">
          <div
            class="homeworkSingle__statusItem"
            v-for="(status, i) in statuses"
            :key="`status-${i}`"
          >
            <p
              :class="[
                'homeworkSingle__statusName',
                { homeworkSingle__statusName_active: status.isActive },
              ]"
            >
              {{ status.label }}
            </p>

            <div class="homeworkSingle__statusProgress">
              <div
                :class="[
                  'homeworkSingle__statusProgressLine',
                  {
                    homeworkSingle__statusProgressLine_active: status.isActive,
                  },
                ]"
              ></div>
              <div
                :class="[
                  'homeworkSingle__statusProgressCircle',
                  {
                    homeworkSingle__statusProgressCircle_active:
                      status.isActive,
                  },
                ]"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from "vue";
import type { HomeworkSingleProps } from "./types";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { CommonDownloadFile } from "@/components/CommonDownloadFile";

export default defineComponent({
  name: "HomeworkSingle",

  components: {
    CommonButtonPrimary,
    CommonDownloadFile,
  },

  props: {
    homework: {
      type: Object as PropType<HomeworkSingleProps["homework"]>,
      required: true,
    },
  },

  setup(props) {
    const isFirstStatusActive = computed(
      () =>
        props.homework.status === "sent" ||
        isSecondStatusActive.value ||
        isThirdStatusActive.value
    );

    const isSecondStatusActive = computed(
      () => props.homework.status === "examination" || isThirdStatusActive.value
    );

    const isThirdStatusActive = computed(
      () => props.homework.status === "complete"
    );

    const statuses = [
      {
        label: "Отправлено на проверку",
        isActive: isFirstStatusActive.value,
      },
      {
        label: "Выполняется проверка",
        isActive: isSecondStatusActive.value,
      },
      {
        label: "Домашнее задание проверено",
        isActive: isThirdStatusActive.value,
      },
    ];

    return {
      statuses,
    };
  },
});
</script>

<style lang="stylus" scoped>
.homeworkSingle {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 35px;
    gap: 40px;
    background-color: $colorLightGrey;
  }

  &__contentTitle {
    &_green {
      text-align: center;
      color: $colorPrimary;
    }
  }

  &__video {
    width: 100%;
    height: 35.2vw;
    border-radius: 40px;
  }

  &__answerBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__textarea {
    padding: 30px;
    width: 100%;
    height: 10vw;
    resize: none;
    border-radius: 10px;

    &:focus {
      outline: none;
      border: 1px solid $colorPrimary;
    }
  }

  &__download {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    text-wrap: nowrap;
    color: $colorPrimary;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__buttonsBox {
    display: flex;
    justify-content: center;
  }

  &__button {
    width: fit-content;
  }

  &__status {
    display: flex;
  }

  &__statusItem {
    flex-grow: 1;
  }

  &__statusName {
    text-align: center;
    font-weight: bold;

    &_active {
      color: $colorPrimary;
    }
  }

  &__statusProgress {
    position: relative;
  }

  &__statusProgressLine {
    width: 100%;
    height: 22px;
    border-radius: 10px;
    border: 2px solid $colorPrimary;
    background-color: $colorFiller;

    &_active {
      background-color: $colorPrimary;
    }
  }

  &__statusProgressCircle {
    position: absolute;
    top: -8.5px;
    right: -17px;
    width: 34px;
    height: 34px;
    border-radius: 100px;
    border: 2px solid $colorPrimary;
    background-color: $colorFiller;
    z-index: 1;

    &_active {
      background-color: $colorPrimary;
      border: 1px solid $colorFiller;
    }
  }

  +mediaTablet() {
    padding: 2.5vw;
    gap: 4vw;

    &__content {
      gap: 4vw;
    }
  }
}
</style>
