<template>
  <div class="commonBannerChatBot">
    <h6 class="commonBannerChatBot__title">
      Чат-бот центра<br />образования<br />"Московская медицина"
    </h6>

    <p class="commonBannerChatBot__text">
      Подключайте телеграм-бота,<br />
      через него вы также сможете<br />общаться со специалистами.
    </p>

    <div class="commonBannerChatBot__buttonBox">
      <svg class="commonBannerChatBot__tg">
        <use xlink:href="#telegram-white-icon--sprite" />
      </svg>

      <button class="commonBannerChatBot__button">Подключить</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonBannerChatBot",
});
</script>

<style lang="stylus" scoped>
.commonBannerChatBot {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1vw;
  padding: 20px;
  background-color: $colorTgContainer;
  border-radius: 30px;
  color: $colorFiller;

  &__title {
    font-weight: bold;
  }

  &__buttonBox {
    display: flex;
    align-items: center;
    gap: 1vw;
  }

  &__button {
    padding: 6px 20px;
    color: $colorTgContainer;
    background-color: $colorFiller;
    border: none;
    border-radius: 31px;
    getFontSmall();
    font-weight: bold;
    cursor: pointer;
    &:hover {
      opacity: .6;
    }
  }

  &__tg {
    width: 37px;
    height: 31px;
  }
}
</style>
