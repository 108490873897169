<template>
  <div class="commonBannerYourMentor">
    <h6 class="commonBannerYourMentor__title">Ваш ИИ<br />наставник</h6>

    <img
      class="commonBannerYourMentor__mentor"
      src="@/assets/img/test-mentor.jpg"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonBannerYourMentor",
});
</script>

<style lang="stylus" scoped>
.commonBannerYourMentor {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1vw;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid $colorGreenMedium;

  &__title {
    text-align: center;
    font-weight: bold;
  }

  &__mentor {
    border-radius: 100px;
  }
}
</style>
