<template>
  <div class="profileDpoCareer">
    <div class="profileDpoCareer__container">
      <div
        class="profileDpoCareer__item"
        v-for="(work, i) in worksList"
        :key="`work-${i}`"
      >
        <div class="profileDpoCareer__itemsBox">
          <CommonSelect
            class="profileDpoCareer__select"
            :select-options="educationLevel"
            :hidden-text="'Выберите место'"
            :value="work.workPlaceValue"
          >
            Место работы
          </CommonSelect>

          <CommonSelect
            class="profileDpoCareer__select"
            :select-options="educationSpeciality"
            :hidden-text="'Выберите должность'"
            :value="work.jobTitleValue"
          >
            Должность
          </CommonSelect>
        </div>

        <div class="profileDpoCareer__datesBox">
          <CommonInput
            type="date"
            class="profileDpoCareer__date"
            :value="work.startWorking"
          >
            Дата начала работы
          </CommonInput>

          <CommonInput
            type="date"
            class="profileDpoCareer__date"
            :value="work.endWorking"
          >
            Дата окончания работы
          </CommonInput>
        </div>
      </div>
    </div>

    <svg class="profileDpoCareer__moreBtn" @click="addMoreWorks">
      <use xlink:href="#add-more-icon--sprite" />
    </svg>

    <div class="profileDpoCareer__accreditationBox">
      <div
        class="profileDpoCareer__accreditationItem"
        v-for="(accreditation, i) in accreditationList"
        :key="`accreditation-${i}`"
      >
        <CommonSelect
          class="profileDpoCareer__select"
          :select-options="educationSpeciality"
          :hidden-text="'Выберите специальность'"
          :value="accreditation.accreditationValue"
        >
          Свидетельство об аккредитации
        </CommonSelect>

        <div class="profileDpoCareer__datesBox">
          <CommonInput
            type="date"
            class="profileDpoCareer__date"
            :value="accreditation.startAccreditation"
          >
            Дата начала работы
          </CommonInput>

          <CommonInput
            type="date"
            class="profileDpoCareer__date"
            :value="accreditation.endAccreditation"
          >
            Дата окончания работы
          </CommonInput>
        </div>
      </div>
    </div>

    <svg class="profileDpoCareer__moreBtn" @click="addMoreAccreditation">
      <use xlink:href="#add-more-icon--sprite" />
    </svg>

    <br />

    <CommonButtonPrimary>Сохранить</CommonButtonPrimary>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { CommonSelect } from "@/components/CommonSelect";
import { CommonInput } from "@/components/CommonInput";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { educationSpeciality, educationLevel } from "../../constants";

import "@/assets/img/icons/add-more-icon.svg?sprite";

export default defineComponent({
  name: "ProfileDpoCareer",

  components: {
    CommonSelect,
    CommonInput,
    CommonButtonPrimary,
  },

  setup() {
    const worksList = ref([
      {
        workPlaceValue: "test5",
        jobTitleValue: "test2",
        startWorking: "2022.05.05",
        endWorking: "15.10.2023",
      },
    ]);

    const accreditationList = ref([
      {
        accreditationValue: "test5",
        startAccreditation: "05.05.2022",
        endAccreditation: "15.10.2023",
      },
    ]);

    const addMoreAccreditation = () => {
      accreditationList.value.push({
        accreditationValue: "test5",
        startAccreditation: "05.05.2022",
        endAccreditation: "15.10.2023",
      });
    };

    const addMoreWorks = () => {
      worksList.value.push({
        workPlaceValue: "",
        jobTitleValue: "",
        startWorking: "",
        endWorking: "",
      });
    };

    return {
      worksList,
      accreditationList,
      educationSpeciality,
      educationLevel,
      addMoreWorks,
      addMoreAccreditation,
    };
  },
});
</script>

<style lang="stylus" scoped>
.profileDpoCareer {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 3vw;
  }

  &__item,
  &__accreditationBox {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }

  &__itemsBox {
    display: flex;
    gap: 1.5vw;
  }

  &__select {
    flex-grow: 1;
    flex-basis: 0;
  }

  &__date {
    max-width: 10vw;
  }

  &__moreBtn {
    margin: 1.5vw 0;
    width: 2.5vw;
    height: 2.5vw;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

  &__accreditationItem {
    display: flex;
    gap: 1.5vw;
  }

  &__datesBox {
    display: flex;
    gap: 1.5vw;
    flex-grow: 1;
    flex-basis: 0;
  }
}
</style>
