<template>
  <div class="commonNavigation">
    <router-link
      :to="{ name: item.pathName }"
      :class="[
        'commonNavigation__item',
        { commonNavigation__item_active: isThisPage(i).value },
      ]"
      v-for="(item, i) in navList"
      :key="`nav-item-${i}`"
      @mousedown="mousedown"
      @mouseup="mouseup"
    >
      {{ item.label }}
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from "vue";
import { useRoute } from "vue-router";
import type { CommonNavigationProps } from "./types";

export default defineComponent({
  name: "CommonNavigation",

  props: {
    navList: {
      type: Array as PropType<CommonNavigationProps["navList"]>,
      default: () => [],
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();

    const mousedown = (event: any) => {
      event.currentTarget.classList.add("commonNavigation__item_selected");
    };

    const mouseup = (event: any) => {
      event.currentTarget.classList.remove("commonNavigation__item_selected");
    };

    const isThisPage = (index: number) =>
      computed(() => {
        if (route.matched[0]?.name === "DPO") {
          return route.matched[1]?.name === props.navList[index].pathName;
        }
        return route.matched[0]?.name === props.navList[index].pathName;
      });

    return {
      mousedown,
      mouseup,
      isThisPage,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonNavigation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .7vw;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 0;
    flex-grow: 1;
    height: 40px;
    border-radius: 10px;
    color: $colorPrimary;
    background-color: $colorFiller;
    border: 2px solid $colorPrimary;
    cursor: pointer;
    transition: all 0.1s;
    text-decoration: none;
    text-wrap: wrap;
    text-align: center;
    getFontText();

    &_selected {
      background-color: $colorThird!important;
    }

    &:hover {
      opacity: 1;
      color: $colorFiller;
      background-color: $colorSecond;
    }

    &_active {
      background-color: $colorPrimary!important;
      color: $colorFiller;
      cursor: default;
    }
  }

  +mediaTablet() {
    &__item {
      height: 3vw;
    }
  }
}

.dpo .commonNavigation {
  &__item {
    color: $colorPrimaryDpo;
    border: 2px solid $colorPrimaryDpo;

    &_selected {
      background-color: $colorThirdDpo!important;
    }

    &:hover {
      background-color: $colorSecondDpo;
      color: $colorFiller;
    }

    &_active {
      background-color: $colorPrimaryDpo!important;
      color: $colorFiller;
    }
  }
}
</style>
