<template>
  <VueFinalModal
    :class="{ dpo: hasDpo }"
    v-model="test"
    name="ModalGoToEducation"
    modal-id="ModalGoToEducation"
    :zIndexFn="() => 1000"
  >
    <div class="modalGoToEducation">
      <CommonBannerIiMessage :title="bannerTitle" text="" />

      <CommonButtonSecondary
        class="modalGoToEducation__btn"
        @click="goToEducation"
      >
        Перейти к обучению
      </CommonButtonSecondary>
    </div>
  </VueFinalModal>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { VueFinalModal } from "vue-final-modal";
import { bannerTitle } from "./constants";
import { CommonBannerIiMessage } from "@/components/CommonBannerIiMessage";
import { CommonButtonSecondary } from "@/components/CommonButtonSecondary";
import { useRouter, useRoute } from "vue-router";
import { RouteName } from "@/constants";
import { useVfm } from "vue-final-modal";

export default defineComponent({
  name: "ModalGoToEducation",

  components: {
    VueFinalModal,
    CommonBannerIiMessage,
    CommonButtonSecondary,
  },

  setup() {
    const vfm = useVfm();
    const router = useRouter();
    const route = useRoute();

    const test = ref<boolean>(false);

    const hasDpo = computed(() => route.matched[0]?.name === RouteName.DPO);

    const goToEducation = () => {
      router.push({ name: RouteName.LECTION_DPO, query: { id: 123 } });
      vfm.close("ModalGoToEducation");
    };

    return {
      bannerTitle,
      test,
      hasDpo,
      goToEducation,
    };
  },
});
</script>

<style lang="stylus">
.vfm {
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  &__content {
    position: relative;
    top: 0;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    opacity: .8;
    z-index: -1;
    background: $colorDarkGrey;
  }
}
</style>

<style lang="stylus" scoped>
.modalGoToEducation {
  position: relative;
  top: 60px;
  margin-bottom: 30%;
  width: 100%;
  max-width: 95vw;
  padding: 15px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-shadow: 0 4px 12px 0 rgba(25, 135, 84, 0.25);
  overflow: hidden;
  background-color: $colorFiller;

  &__icon {
    width: 94px;
    height: 71px;
  }

  &__contentTitle {
    text-align: center;

    &_green {
      color: $colorPrimary;
    }
  }

  &__titleBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    getFontH4();
    color: $colorPrimary;
    text-wrap: wrap;
  }

  &__btn {
    width: fit-content;
    margin: 0 auto;
  }

  +mediaTablet() {
    max-width: 60vw;
    padding: 64px 0;
    gap: 3vw;
  }
}

.dpo .modalTestRules {
  &__contentTitle {
    text-align: center;

    &_green {
      color: $colorPrimaryDpo;
    }
  }

  &__titleBox {
    color: $colorPrimaryDpo;
  }
}
</style>
