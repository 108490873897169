<template>
  <LayoutBase>
    <div class="educationMaterialsPage">
      <CommonDropdown
        :label="dropdown.label"
        isSelectGrey
        v-for="(dropdown, index) in dropdownsList"
        :key="dropdown.label"
      >
        <div
          class="educationMaterialsPage__linkBox"
          v-for="(subLabel, i) in dropdown.subLabelsList"
          :key="subLabel.title"
        >
          <div class="educationMaterialsPage__link" v-if="index === 1">
            <div class="educationMaterialsPage__resource">
              <svg>
                <use xlink:href="#resources-logo-1--sprite" />
              </svg>

              <p class="educationMaterialsPage__resourceLabel">
                Электронная<br />библиотечная система
              </p>
            </div>

            <div class="educationMaterialsPage__resource">
              <svg>
                <use xlink:href="#resources-logo-2--sprite" />
              </svg>

              <p class="educationMaterialsPage__resourceLabel">
                Научная<br />электронная библиотека
              </p>
            </div>

            <div class="educationMaterialsPage__resource">
              <svg>
                <use xlink:href="#resources-logo-3--sprite" />
              </svg>

              <p class="educationMaterialsPage__resourceLabel">
                База<br />знаний врача
              </p>
            </div>

            <div class="educationMaterialsPage__resource">
              <svg>
                <use xlink:href="#resources-logo-4--sprite" />
              </svg>

              <p class="educationMaterialsPage__resourceLabel">
                Научные<br />информационные ресурсы
              </p>
            </div>
          </div>

          <CommonDropdownSecond
            :label="subLabel.title"
            class="educationMaterialsPage__link"
            v-else
          >
            <div v-if="i === 0">
              <p class="bold-text">
                Математическое и компьютерное моделирование
              </p>

              <p class="bold-text">Лекции</p>

              <p>Тема 1</p>

              <p class="bold-text">Презентации</p>

              <p class="bold-text">Дополнительные материалы</p>
            </div>

            <p v-for="(item, i) in subLabel.content" :key="`content-${i}`">
              {{ i + 1 }}.
              {{ item }}
            </p>
          </CommonDropdownSecond>

          <hr
            class="educationMaterialsPage__linkHr"
            v-if="
              i !== dropdown.subLabelsList.length - 1 &&
              dropdown.subLabelsList.length > 1
            "
          />
        </div>
      </CommonDropdown>
    </div>
  </LayoutBase>

  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { CommonDropdown } from "@/components/CommonDropdown";
import { CommonDropdownSecond } from "@/components/CommonDropdownSecond";
import { dropdownsList } from "./constants";

import "@/assets/img/icons/resources-logo-1.svg?sprite";
import "@/assets/img/icons/resources-logo-2.svg?sprite";
import "@/assets/img/icons/resources-logo-3.svg?sprite";
import "@/assets/img/icons/resources-logo-4.svg?sprite";

export default defineComponent({
  name: "EducationMaterialsPage",

  components: {
    LayoutBase,
    CommonDropdown,
    CommonDropdownSecond,
  },

  setup() {
    return {
      dropdownsList,
    };
  },
});
</script>

<style lang="stylus" scoped>
.educationMaterialsPage {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__linkBox {
    padding: 10px 30px 0 30px;
    &:hover {
      background-color: $colorLightGrey;
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding-bottom: 10px;
  }

  &__linkHr {
    margin: 0;
    width: 100%;
    height: 3px;
    background-color: $colorDarkGrey;
    border: none;
  }

  &__resource:hover  &__resourceLabel {
    font-weight: bold;
  }

  +mediaTablet() {
    &__linkBox {
      padding: 1vw 1vw 0 1vw;
    }
    &__link {
      padding-bottom: 1vw;
    }
  }
}
</style>
