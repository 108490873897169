<template>
  <header class="magistracy-header">
    <nav class="navbar">
      <router-link class="navbar-brand" :to="{ name: RouteName.HOME }">
        <svg class="magistracy__logo">
          <use xlink:href="#logo-white-icon--sprite" />
        </svg>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#magistracyMenu"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="magistracyMenu">
        <ul class="navbar-nav">
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY }"
            >
              Магистратура
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_ABOUT,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_ABOUT }"
            >
              О программе
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_PROGRAM,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_PROGRAM }"
            >
              Структура программы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY__ENTRANCE,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY__ENTRANCE }"
            >
              Приемная кампания
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_DOCUMENTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_DOCUMENTS }"
            >
              Документы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_CONTACTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_CONTACTS }"
            >
              Контакты
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <main>
    <div class="magistracy-page">
      <div class="container">
        <div class="page-content">
          <h1 class="page-title">Структура образовательной программы</h1>
          <div class="mb-5">
            <p class="h5 mb-4">Дисциплины программы включают</p>
            <div class="mb-4">
              <p class="h4 font-weight-bolder text-secondary mb-2">Лекции</p>
              <p>Вы изучаете теорию в формате видеолекций в удобное время.</p>
            </div>
            <div class="mb-4">
              <p class="h4 font-weight-bolder text-secondary mb-2">
                Практические занятия
              </p>
              <p>
                Вы погружаетесь в реальную рабочую среду. В формате онлайн
                разбираете с квалифицированными преподавателями практические
                задачи, кейсы, работу с программным обеспечением и нейросетями и
                т.д.
              </p>
            </div>
            <div class="mb-4">
              <p class="h4 font-weight-bolder text-secondary mb-2">
                Самостоятельную работу
              </p>
              <p>
                Вы сможете добавить в Ваше портфолио практические задания и
                кейсы, выполненные под руководством наших преподавателей на
                реальных проектах цифровой трансформации здравоохранения Москвы.
              </p>
            </div>
          </div>

          <a
            href="#magistracyRegister"
            class="btn btn-magistracy"
            data-toggle="modal"
            >Подать заявку</a
          >
        </div>
      </div>
    </div>

    <section class="magistracy-program--simple">
      <h2 class="magistracy-title mb-lg-5">Дисциплины программы</h2>
      <div class="program-accordion">
        <div class="accordion" id="accordionProgram">
          <div class="card semester">
            <div class="semester__head">
              <div
                :class="[
                  'semester-toggler',
                  { collapsed: hasCollapsed(1).value },
                ]"
                data-toggle="collapse"
                data-target="#semester1"
                :aria-expanded="hasCollapsed(1).value"
                @click="setCollapsedId(1)"
              >
                <div class="semester-name">1 семестр</div>
                <div class="semester-date">сентябрь-декабрь 2024 г.</div>
              </div>
            </div>
            <div
              :class="[
                'collapse',
                'semester__body',
                {
                  show: hasCollapsed(1).value,
                },
              ]"
              id="semester1"
              data-parent="#accordionProgram"
            >
              <div class="semester-program">
                <ul class="circle-list">
                  <li>Математическое и компьютерное моделирование</li>
                  <li>
                    Технологии и средства разработки программного обеспечения
                  </li>
                  <li>Анализ и синтез информационных систем</li>
                  <li>Модели и методы искусственного интеллекта</li>
                  <li>
                    Основы менеджмента и маркетинга в медицинской организации
                  </li>
                  <li>Компьютерное моделирование биологических систем</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="card semester">
            <div class="semester__head">
              <div
                :class="[
                  'semester-toggler',
                  { collapsed: hasCollapsed(2).value },
                ]"
                data-toggle="collapse"
                data-target="#semester2"
                :aria-expanded="hasCollapsed(2).value"
                @click="setCollapsedId(2)"
              >
                <div class="semester-name">2 семестр</div>
                <div class="semester-date">февраль-июнь 2025 г.</div>
              </div>
            </div>
            <div
              :class="[
                'collapse',
                'semester__body',
                {
                  show: hasCollapsed(2).value,
                },
              ]"
              id="semester2"
              data-parent="#accordionProgram"
            >
              <div class="semester-program">
                <ul class="circle-list">
                  <li>Математическое и компьютерное моделирование</li>
                  <li>
                    Статистические методы анализа медико-биологических данных с
                    элементами статистического моделирования
                  </li>
                  <li>Моделирование и оптимизация бизнес-процессов</li>
                  <li>Разработка сетевых приложений и облачные вычисления</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="card semester">
            <div class="semester__head">
              <div
                :class="[
                  'semester-toggler',
                  { collapsed: hasCollapsed(3).value },
                ]"
                data-toggle="collapse"
                data-target="#semester3"
                :aria-expanded="hasCollapsed(3).value"
                @click="setCollapsedId(3)"
              >
                <div class="semester-name">3 семестр</div>
                <div class="semester-date">сентябрь-декабрь 2025 г.</div>
              </div>
            </div>
            <div
              :class="[
                'collapse',
                'semester__body',
                {
                  show: hasCollapsed(3).value,
                },
              ]"
              id="semester3"
              data-parent="#accordionProgram"
            >
              <div class="semester-program">
                <ul class="circle-list">
                  <li>Системы поддержки принятия решений</li>
                  <li>
                    Иностранный язык в сфере профессиональных коммуникаций
                  </li>
                  <li>Машинное обучение: нейронные сети</li>
                  <li>Информатизация здравоохранения</li>
                  <li>
                    Коммерциализация результатов научных исследований и
                    разработок
                  </li>
                  <li>
                    Программные продукты как изделия медицинского назначения
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="card semester">
            <div class="semester__head">
              <div
                :class="[
                  'semester-toggler',
                  { collapsed: hasCollapsed(4).value },
                ]"
                data-toggle="collapse"
                data-target="#semester4"
                :aria-expanded="hasCollapsed(4).value"
                @click="setCollapsedId(4)"
              >
                <div class="semester-name">4 семестр</div>
                <div class="semester-date">февраль-июнь 2026 г.</div>
              </div>
            </div>
            <div
              :class="[
                'collapse',
                'semester__body',
                {
                  show: hasCollapsed(4).value,
                },
              ]"
              id="semester4"
              data-parent="#accordionProgram"
            >
              <div class="semester-program">
                <ul class="circle-list">
                  <li>Методология научных исследований</li>
                  <li>Управление проектами</li>
                  <li>Управление интеллектуальной собственностью</li>
                  <li>Защита информации в медицинской организации</li>
                  <li>Информационная безопасность медицинской организации</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <footer class="footer-magistracy">
    <div class="container">
      <div class="contacts-title">Приемная комиссия</div>
      <div class="contacts">
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#clock--sprite" />
            </svg>
          </div>
          <div class="contact-name">Часы работы</div>
          <div class="contact-text">
            <p>Пн-Пт 09:00 – 17:30</p>
            <p>Сб-Вс Выходные</p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#email--sprite" />
            </svg>
          </div>
          <div class="contact-name">Почта</div>
          <div class="contact-text">
            <p>
              <a href="mailto:TolstyakovaOV1@zdrav.mos.ru"
                >TolstyakovaOV1@zdrav.mos.ru</a
              >
            </p>
            <p>
              <a href="mailto:PerunovaEA@zdrav.mos.ru"
                >PerunovaEA@zdrav.mos.ru</a
              >
            </p>
            <p>
              <a href="mailto:KuznetsovMY1@zdrav.mos.ru"
                >KuznetsovMY1@zdrav.mos.ru</a
              >
            </p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#phone--sprite" />
            </svg>
          </div>
          <div class="contact-name">Телефон</div>
          <div class="contact-text">
            <p>8 (499) 249-74-72<br />(доб. 574, 572, 571)</p>
            <p>8 (905) 734-17-14</p>
            <p>8 (977) 570-30-66</p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#map--sprite" />
            </svg>
          </div>
          <div class="contact-name">Адрес</div>
          <div class="contact-text">
            <p>
              121165, г. Москва,<br />
              ул. Киевская, д. 20
            </p>
            <p><a href="#" target="_blank">Карта</a></p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer__copyright">&copy; ГБУ «НИИОЗММ ДЗМ», 2024</div>
  </footer>
  <div class="modal fade" id="magistracyRegister" tabindex="-1">
    <div class="modal-dialog magistracy-register">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
          <h3 class="modal-title">Подать заявку</h3>

          <div class="register-intro js-register-types">
            <div class="buttons-group">
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#personRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#person"></use>
                  </svg>
                  <div class="card-body">Лично</div>
                </button>
              </div>
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#webformRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#webform"></use>
                  </svg>
                  <div class="card-body">
                    Через Личный кабинет на&nbsp;сайте
                  </div>
                </button>
              </div>
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#postalRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#postal"></use>
                  </svg>
                  <div class="card-body">Через операторов почтовой связи</div>
                </button>
              </div>
            </div>
          </div>

          <div class="register-tabs js-register-tabs">
            <div class="nav">
              <a class="nav-link" data-toggle="tab" href="#personRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#person"></use>
                </svg>
                Лично
              </a>
              <a class="nav-link" data-toggle="tab" href="#webformRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#webform"></use>
                </svg>
                На сайте
              </a>
              <a class="nav-link" data-toggle="tab" href="#postalRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#postal"></use>
                </svg>
                По почте
              </a>
            </div>
            <div class="tab-content">
              <div class="tab-pane" id="personRegister" role="tabpanel">
                <p>
                  <b>Вы можете подать документы лично по адресу:</b>
                  г.&nbsp;Москва, ул.&nbsp;Киевская, д.&nbsp;20, кабинет&nbsp;8.
                </p>
                <p><b>Часы работы:</b> Пн-Пт 09:00 – 17:30</p>
                <p>
                  <b
                    >Список документов для поступления на&nbsp;программу
                    магистратуры:</b
                  >
                </p>
                <ol>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/000/000ec0855d5336e3befb60fcf61f82a1.docx"
                      tarhet="_blank"
                      >Заявление на обучение</a
                    >
                  </li>
                  <li>
                    Копия паспорта (главная и регистрация по&nbsp;месту
                    жительства)
                  </li>
                  <li>
                    Копия диплома о высшем образовании с&nbsp;приложениями
                  </li>
                  <li>Копия СНИЛС</li>
                  <li>ИНН (копия или номер)</li>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/1b0/1b02e815b990b92a660bf53a78f855f2.docx"
                      target="_blank"
                      >Согласие на обработку персональных данных</a
                    >
                  </li>
                </ol>
                <div class="register-footer">
                  <button type="button" class="btn" data-dismiss="modal">
                    Понятно
                  </button>
                </div>
              </div>

              <div class="tab-pane" id="webformRegister" role="tabpanel">
                <p>
                  Для подачи документов в магистратуру Вы будете перенаправлены
                  на сайт Государственного бюджетного учреждения
                  Научно-исследовательский институт организации здравоохранения
                  и медицинского менеджмента Департамента здравоохранения г.
                  Москвы (ГБУ «НИИОЗММ ДЗМ»).
                </p>
                <ol class="divided">
                  <li>
                    Зарегистрируйтесь на сайте
                    <a href="https://niioz.ru/" target="_blank">www.niioz.ru</a>
                  </li>
                  <li>Подтвердите электронную почту по ссылке из письма</li>
                  <li>
                    Войдите в Личный кабинет в раздел
                    <a
                      href="https://niioz.ru/personal/magistracy/"
                      target="_blank"
                      >«Подать документы в магистратуру»</a
                    >
                  </li>
                  <li>Заполните форму, нажмите кнопку «Сохранить»</li>
                </ol>
                <p>
                  <a href="#" target="_blank">Скачать подробную инструкцию</a>
                </p>
                <div class="register-footer">
                  <a
                    href="https://niioz.ru/obrazovanie/magistratura/"
                    class="btn"
                    target="_blank"
                    >Продолжить</a
                  >
                </div>
              </div>

              <div class="tab-pane" id="postalRegister" role="tabpanel">
                <p>
                  Вы можете направить документы через оператора почтовой связи,
                  например, Почтой России.
                </p>
                <p>
                  <b>Адрес:</b> ул. Большая Татарская, д.&nbsp;30, помещение
                  1/2, Москва, 115184.
                </p>
                <p>
                  <b
                    >Список документов для поступления на&nbsp;программу
                    магистратуры:</b
                  >
                </p>
                <ol>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/000/000ec0855d5336e3befb60fcf61f82a1.docx"
                      target="_blank"
                      >Заявление на обучение</a
                    >
                  </li>
                  <li>
                    Копия паспорта (главная и регистрация по&nbsp;месту
                    жительства)
                  </li>
                  <li>
                    Копия диплома о высшем образовании с&nbsp;приложениями
                  </li>
                  <li>Копия СНИЛС</li>
                  <li>ИНН (копия или номер)</li>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/1b0/1b02e815b990b92a660bf53a78f855f2.docx"
                      target="_blank"
                      >Согласие на обработку персональных данных</a
                    >
                  </li>
                </ol>
                <div class="register-footer">
                  <button type="button" class="btn" data-dismiss="modal">
                    Понятно
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  computed,
} from "vue";
import { RouteName } from "@/constants";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "HomeMagistracyProgramsPage",

  components: {},

  setup() {
    const route = useRoute();

    const pickedValue = ref<number>(1);

    const hasCollapsed = (id: number) =>
      computed(() => pickedValue.value === id);

    const setMainCss = () => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.id = "main-css";

      document.head.appendChild(link);

      link.href = "/assets/css/main.css";
    };

    const removeMainCss = () => {
      document.getElementById("main-css")?.remove();
    };

    const setCollapsedId = (id: number) => (pickedValue.value = id);

    onBeforeMount(() => setMainCss());

    onBeforeUnmount(() => removeMainCss());

    return {
      RouteName,
      route,
      pickedValue,
      hasCollapsed,
      setCollapsedId,
    };
  },
});
</script>

<style lang="stylus" scoped>
.magistracy__logo {
  width: 206px;
  height: 40px;
  min-width: 206px;
}
.navbar-collapse {
  background-color: transparent;
}
.magistracy-page::before {
  background-image: url("@/assets/img/magistracy-hand.png");
}
.program-accordion::before {
  background-image: url("@/assets/img/hand.png")!important;
}
</style>
