import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonButtonSecondary = _resolveComponent("CommonButtonSecondary")!
  const _component_LayoutAuth = _resolveComponent("LayoutAuth")!

  return (_openBlock(), _createBlock(_component_LayoutAuth, {
    modalName: 'signInModal',
    title: "Вход в профиль"
  }, {
    title: _withCtx(() => [
      _createTextVNode("Вход в профиль")
    ]),
    content: _withCtx(() => [
      _createVNode(_component_CommonInput, null, {
        default: _withCtx(() => [
          _createTextVNode("Электронная почта")
        ]),
        _: 1
      }),
      _createVNode(_component_CommonInput, { type: "password" }, {
        default: _withCtx(() => [
          _createTextVNode("Пароль")
        ]),
        _: 1
      }),
      _createVNode(_component_CommonButtonPrimary, { onClick: _ctx.signIn }, {
        default: _withCtx(() => [
          _createTextVNode("Войти")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_CommonButtonSecondary, { onClick: _ctx.openRegistrationModal }, {
        default: _withCtx(() => [
          _createTextVNode(" Создать профиль ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}