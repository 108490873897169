<template>
  <div class="commonEducationStatus">
    <div class="commonEducationStatus__labelBox">
      <div class="commonEducationStatus__label">
        {{ label }}
      </div>

      <div class="commonEducationStatus__subLabel">
        {{ subLabel }}
      </div>
    </div>

    <div class="commonEducationStatus__btnBox">
      <CommonButtonPrimary v-if="status === 'started'">
        Дата старта<br />
        {{ date }}
      </CommonButtonPrimary>
      <CommonButtonPrimary v-else-if="status === 'completed'" disabled>
        Завершен
      </CommonButtonPrimary>
      <CommonButtonPrimary v-else-if="status === 'active'">
        Активен
      </CommonButtonPrimary>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CommonEducationStatusProps } from "./types";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";

export default defineComponent({
  name: "CommonEducationStatus",

  props: {
    label: {
      type: String as PropType<CommonEducationStatusProps["label"]>,
    },
    subLabel: {
      type: String as PropType<CommonEducationStatusProps["subLabel"]>,
    },
    status: {
      type: String as PropType<CommonEducationStatusProps["status"]>,
    },
    date: {
      type: String as PropType<CommonEducationStatusProps["date"]>,
    },
  },

  components: {
    CommonButtonPrimary,
  },
});
</script>

<style lang="stylus" scoped>
.commonEducationStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__labelBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__label {
    font-weight: bold;
  }

  &__subLabel {
    getFontSmall();
    color: $colorDarkGrey;
  }
}
</style>
