import axios, { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";

import { pathOr, pipe } from "rambda";

import authCookies from "./cookies/authCookies";

const buildFormData = (formData: any, data: any, parentKey?: any) => {
  const isDataObject = data && typeof data === "object";
  if (isDataObject && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
};

const xsrfToken = Cookies.get("XSRF-TOKEN");
const laravel_session = Cookies.get("laravel_session");

export const jsonToFormData = (data: any) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};

export class ApiInstance {
  // public baseURL: string;

  public changeRequestData: (data: any) => any;

  public paramsValidator: (props: AxiosRequestConfig) => boolean;

  public transformResponse: Array<(data: any) => any>;

  public catchErr: (e: any) => void;

  public sendByGet(url: string, props?: any) {
    return axios({
      method: "GET",
      // baseURL: this.baseURL,
      url,
      withCredentials: true,
      withXSRFToken: true,
      xsrfCookieName: "XSRF-TOKEN",
      xsrfHeaderName: "X-XSRF-TOKEN",
      ...(props || {}),
    });
  }

  constructor({
    // baseURL,
    changeRequestData = (data) => data,
    transformResponse,
    catchErr,
    paramsValidator = () => true,
  }: {
    // baseURL: string;
    changeRequestData?: (data: any) => any;
    transformResponse: Array<(data: any) => any>;
    catchErr: (e: any) => void;
    paramsValidator?: (props: AxiosRequestConfig) => boolean;
  }) {
    // this.baseURL = baseURL;
    this.changeRequestData = changeRequestData;
    this.transformResponse = transformResponse;
    this.catchErr = catchErr;
    this.paramsValidator = paramsValidator;
  }

  public get standardConfig() {
    return {
      withCredentials: true,
      withXSRFToken: true,
      xsrfCookieName: "XSRF-TOKEN",
      xsrfHeaderName: "X-XSRF-TOKEN",
      // baseURL: this.baseURL,
      transformResponse: [
        (resp: any) => JSON.parse(resp),
        ...this.transformResponse,
      ],
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  public sendPost = async (props: AxiosRequestConfig) => {
    if (!this.paramsValidator(props)) {
      const message = `Validate props error. Method: ${props.url}`;

      console.error(message, "\n", props);
      throw new Error(message);
    }

    axios.defaults.withXSRFToken = true;
    const instance = axios.create({
      ...this.standardConfig,
      method: "POST",
      transformRequest: [this.addParamsToData, (req) => JSON.stringify(req)],
    });

    try {
      return await instance({
        method: "POST",
        ...props,
      });
    } catch (e: any) {
      this.catchErr(e);
      throw new Error(e);
    }
  };

  public clearPost = async (props: AxiosRequestConfig) => {
    try {
      return await axios({
        method: "POST",
        ...props,
      });
    } catch (e: any) {
      this.catchErr(e);
      throw new Error(e);
    }
  };

  public sendFormDataPost(url: string) {
    return (data: any) => {
      const formData = pipe(this.addParamsToData, jsonToFormData)(data);

      return axios({
        method: "POST",
        withCredentials: true,
        data: formData,
        url,
        // baseURL: this.baseURL,
        headers: { "Content-Type": "multipart/form-data" },
      });
    };
  }

  private readonly addParamsToData = (data: any) =>
    this.changeRequestData(data);
}

export const api = new ApiInstance({
  // baseURL: `${process.env.VUE_APP_BASE_API_URL}api/`,
  changeRequestData: (data: any) => {
    const newData = {
      // signature: 1,
      // domain: process.env.VUE_APP_DOMAIN,
      ...data,
    };

    const auth = authCookies.get();

    if (auth) {
      newData.auth = auth;
    }
    return newData;
  },

  transformResponse: [
    (data: any) => {
      if (data.code === 401) {
        Cookies.remove("auth");
        return data;
      }
      return data;
    },
  ],
  catchErr: () => false,
});
