import { Statuses } from "@/constants/statuses";
import codes from "@/utils/api/codes";
import { DefaultOfficeResponse } from "@/utils/api/apiTypes";
import { pathOr } from "rambda";
import Cookies from "js-cookie";
import { api } from "@/utils/apiInstance";

export type State =
  | typeof Statuses.INIT
  | typeof Statuses.PENDING
  | typeof Statuses.FULFILLED
  | typeof Statuses.REJECTED;

export interface FetchStateInterface<responseType> {
  fetchState: State;
  response: responseType | null;
}

export type FetchOfficeState<dataType> = FetchStateInterface<
  DefaultOfficeResponse<dataType>
>;

export const getFetchState = <dataType>(): FetchStateInterface<dataType> => ({
  fetchState: Statuses.INIT as State,
  response: null,
});

export const getFetchMutations = () => ({
  SET_STATE(
    state: any,
    { fetchState, data }: { fetchState: State; data?: any }
  ) {
    state.fetchState = fetchState;

    if (data) {
      state.response = data;
    }
  },
  CLEAR_STORE(state: any) {
    state.fetchState = Statuses.INIT;
    state.response = null;
  },
});

export interface FetchProps {
  method: any;
  props?: {
    url: string;

    [key: string]: any;
  };
  setState({ fetchState, data }: { fetchState: State; data?: any }): void;
  callback?(state: State, resp?: any): void;
}

const onError = (ctx: FetchProps, e: any, resp?: any) => {
  ctx.setState({ fetchState: Statuses.REJECTED });
  console.error(new Error(e));

  if (typeof ctx.callback === "function") {
    ctx.callback(Statuses.REJECTED, resp);
  }
};

const fetch = async (ctx: FetchProps) => {
  if (typeof ctx.method !== "function" || typeof ctx.setState !== "function") {
    throw new Error(
      "Error in method storeUtils.fetch, ctx.method or ctx.setState is not a function"
    );
  }

  try {
    ctx.setState({ fetchState: Statuses.PENDING });

    const response = await ctx.method(ctx.props);

    return response;
  } catch (e: any) {
    return onError(ctx, e);
  }
};

export const basicFetch = async (ctx: FetchProps) => {
  const xsrfToken = Cookies.get("XSRF-TOKEN");

  if (!xsrfToken) {
    await fetch({
      method: () =>
        api.sendByGet(
          window.location.hostname === "localhost"
            ? "/api/csrf-cookie"
            : "/backend/api/csrf-cookie"
        ),
      setState: ({ fetchState }) => {
        console.log(fetchState);
      },
    });
  }

  let response;

  try {
    response = await fetch(ctx);
  } catch (e: any) {
    console.error(e);
    return;
  }

  const code = pathOr(1, ["data", "code"], response);
  const codeIsPassed = codes[0] === codes[code];

  if (codeIsPassed) {
    ctx.setState({ fetchState: Statuses.FULFILLED, data: response });

    if (typeof ctx.callback === "function") {
      ctx.callback(Statuses.FULFILLED, response);
    }
  } else {
    onError(ctx, `codeError: ${code}`, response);
  }
};

/**
 * @deprecated
 */
