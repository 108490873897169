import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0267e8cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "informationPage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonNavigation = _resolveComponent("CommonNavigation")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.hasInformationMain)
    ? (_openBlock(), _createBlock(_component_LayoutBase, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_CommonNavigation, { "nav-list": _ctx.navList }, null, 8, ["nav-list"])
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}