<template>
  <div class="commonTestResults">
    <h6>Ваш результат</h6>

    <div class="commonTestResults__result">
      <span
        :class="[
          {
            commonTestResults__currentPoints_success: isSuccess,
            commonTestResults__currentPoints_fail: !isSuccess,
          },
        ]"
      >
        87
      </span>
      / 100
    </div>

    <CommonBannerIiMessage :title="bannerTitle" :text="bannerText" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from "vue";
import { CommonBannerIiMessage } from "@/components/CommonBannerIiMessage";
import {
  bannerTitleSuccess,
  bannerTextSuccess,
  bannerTitleFail,
} from "./constants";
import type { CommonTestResultsProps } from "./types";

export default defineComponent({
  name: "CommonTestResults",

  components: {
    CommonBannerIiMessage,
  },

  props: {
    isSuccess: {
      type: Boolean as PropType<CommonTestResultsProps["isSuccess"]>,
    },
  },

  setup(props) {
    const bannerTitle = computed(() =>
      props.isSuccess ? bannerTitleSuccess : bannerTitleFail
    );

    const bannerText = computed(() =>
      props.isSuccess ? bannerTextSuccess : " "
    );

    return {
      bannerTitle,
      bannerText,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonTestResults {
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;

  &__result {
    font-weight: bold;
    font-size: 74px;
  }

  &__currentPoints {
    &_success {
      color: $colorGreenMedium;
    }

    &_fail {
      color: $colorDanger;
    }
  }

  +mediaTablet() {
    &__result {
      font-size: 7vw;
    }
  }
}
</style>
