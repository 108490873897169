import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-176ba925"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonChatItem" }
const _hoisted_2 = {
  key: 0,
  class: "commonChatItem__unreadCount"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonDropdownSecond = _resolveComponent("CommonDropdownSecond")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonDropdownSecond, {
      class: "commonChatItem__dropdown",
      label: _ctx.label
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["label"]),
    (_ctx.unreadCount !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.unreadCount), 1))
      : _createCommentVNode("", true)
  ]))
}