<template>
  <LayoutBase>
    <div class="homeworkPage">
      <div class="homeworkPage__titleBox">
        <h2 class="homeworkPage__title">Специальность</h2>

        <h6 class="homeworkPage__secondTitle">
          Информационные системы и технологии<br />в здравоохранении
        </h6>
      </div>

      <HomeworkOmissions v-if="false" />

      <HomeworkCrossword v-else-if="0" />

      <HomeworkCorrelate v-else />
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { eventsList } from "./constants";
import { HomeworkOmissions } from "./components/HomeworkOmissions";
import { HomeworkCrossword } from "./components/HomeworkCrossword";
import { HomeworkCorrelate } from "./components/HomeworkCorrelate";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";

export default defineComponent({
  name: "HomeworksDpoPage",

  components: {
    LayoutBase,
    HomeworkOmissions,
    HomeworkCrossword,
    HomeworkCorrelate,
  },

  setup() {
    const route = useRoute();

    const isShowTextForVideo = ref<boolean>(false);

    const hasEducationMain = computed(
      () => route?.name === RouteName.EDUCATION_DPO
    );

    const toggleTextForVideo = () => {
      isShowTextForVideo.value = !isShowTextForVideo.value;
    };

    return {
      hasEducationMain,
      eventsList,
      isShowTextForVideo,
      toggleTextForVideo,
    };
  },
});
</script>

<style lang="stylus" scoped>
.homeworkPage {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__secondTitle,
  &__btn {
    margin-top: 15px;
  }

  &__video {
    width: 100%;
    height: 39.2vw;
    border-radius: 53px;
  }

  &__textForVideo {
    padding: 20px;
    border-radius: 10px;
    background-color: $colorBgGrey;
  }

  +mediaTablet() {
    gap: 4vw;

    &__secondTitle,
    &__btn {
      margin-top: 1.5vw;
    }

    &__textForVideo {
      padding: 2vw;
    }
  }
}
</style>
