<template>
  <LayoutHomepage>
    <div>
      <section class="intro">
        <div class="intro-layout">
          <div class="container">
            <div class="intro__header">
              <div class="intro-picture">
                <svg class="education-icon">
                  <use xlink:href="#education--sprite" />
                </svg>
              </div>
              <div class="intro-title">
                <h1 style="font-size: 3.5rem">
                  Московская Медицина. Образование
                </h1>
                <div class="intro-text" style="font-size: 3.5rem">
                  Центр образования в здравоохранении
                </div>
              </div>
            </div>

            <div class="intro__body">
              <div class="intro-counter">
                <div class="counter-text">
                  До конца набора в&nbsp;магистратуру осталось
                </div>
                <div class="counter-body">
                  <div class="counter-result">18 дней</div>
                  <a href="" class="btn">Подать заявку</a>
                </div>
              </div>
            </div>
          </div>
          <div class="intro__slogan">Развивайте свое будущее вместе с нами</div>
        </div>
      </section>

      <div class="container">
        <div class="education">
          <div class="education__item">
            <router-link
              :to="{ name: RouteName.HOME_MAGISTRACY }"
              class="btn btn-primary"
            >
              Магистратура
            </router-link>
          </div>
          <div class="education__item">
            <router-link
              :to="{ name: RouteName.HOME_ORDINAT }"
              class="btn btn-primary"
            >
              Ординатура
            </router-link>
          </div>
          <div class="education__item">
            <router-link
              :to="{ name: RouteName.HOME_PROGRAMS_LIST, query: { id: 2 } }"
              class="btn btn-red"
            >
              Аспирантура
            </router-link>
          </div>
          <div class="education__item">
            <router-link
              :to="{ name: RouteName.HOME_PROGRAMS_LIST, query: { id: 4 } }"
              class="btn btn-secondary"
            >
              Дополнительное образование
            </router-link>
          </div>
        </div>
      </div>

      <section class="description">
        <div class="description__row">
          <div class="description__col">
            <div class="row">
              <div class="description__item">
                <div class="digit">8000+</div>
                <p>слушателей с&nbsp;2016&nbsp;года</p>
              </div>
              <div class="description__item">
                <div class="digit">60+</div>
                <p>образовательных программ</p>
              </div>
              <div class="description__item">
                <div class="digit">100 000+</div>
                <p>учебных материалов в&nbsp;библиотеке</p>
              </div>
              <div class="description__item">
                <div class="digit">&infin;</div>
                <p>непрерывное образование</p>
              </div>
            </div>
          </div>

          <div class="description__col pl-xl-5">
            <h2>Московская медицина. Образование —</h2>
            <p>
              уникальный образовательный центр очного, дистанционного
              и&nbsp;онлайн-образования.
            </p>
            <p>
              Развивайте компетенции и&nbsp;формируйте успешную карьеру
              с&nbsp;нами!
            </p>
          </div>
        </div>
      </section>
      <section class="subscription">
        <div class="container">
          <div class="row">
            <div class="subscription__col">
              <h2>Подберем идеальную программу для вас</h2>
              <div class="subscription-title">Оставить заявку</div>
            </div>

            <div class="subscription__col pl-xl-5">
              <form method="post">
                <div class="form-input">
                  <input type="text" class="form-control" placeholder="Имя *" />
                </div>
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Фамилия *"
                  />
                </div>
                <div class="form-input">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="E-mail *"
                  />
                </div>
                <div class="form-input">
                  <select
                    class="selectpicker"
                    title="Выберите направление обучения"
                  >
                    <option>Магистратура</option>
                    <option>Ординатура</option>
                    <option>Аспирантура</option>
                    <option>ДПО</option>
                  </select>
                </div>
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="privacy"
                  />
                  <label class="custom-control-label" for="privacy">
                    Я согласен с политикой конфиденциальности
                  </label>
                </div>
                <div class="form-submit">
                  <button type="submit" class="btn">Отправить</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section class="comments">
        <div class="row">
          <div class="section-title">
            <h2>Отзывы наших студентов</h2>
          </div>
          <carousel class="carousel-reviews" :items-to-show="3">
            <slide class="carousel-cell">
              <div class="reviews-item">
                <div class="reviews-card">
                  <div class="comment-head">Анна Иванова</div>
                  <div class="comment-body">
                    Курс насыщенный. Практика в каждом блоке полезная, посильна
                    даже тем, кто никогда не работал ни в одной из программ,
                    использовавшихся на курсе. Однако хотелось бы чуть больше
                    часов для закрепления изученного материала.
                  </div>
                </div>
              </div>
            </slide>
            <slide class="carousel-cell">
              <div class="reviews-item">
                <div class="reviews-card">
                  <div class="comment-head">Олег Петров</div>
                  <div class="comment-body">
                    Структура курса выстроена очень грамотно, от простого к
                    более сложному. Форма подачи материала очень понятная, на
                    каждой лекции было много новой полезной информации.
                    Благодаря курсу у меня сформировалась четкая стратегия.
                  </div>
                </div>
              </div>
            </slide>
            <slide class="carousel-cell">
              <div class="reviews-item">
                <div class="reviews-card">
                  <div class="comment-head">Ольга Романова</div>
                  <div class="comment-body">
                    Отмечу работу спикеров курса. Понравилось качество проверки
                    домашних заданий. Всегда оперативные ответы на возникающие
                    вопросы.
                  </div>
                </div>
              </div>
            </slide>
            <slide class="carousel-cell">
              <div class="reviews-item">
                <div class="reviews-card">
                  <div class="comment-head">Виталий Мартынов</div>
                  <div class="comment-body">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                    amet lacus accumsan et viverra justo commodo.
                  </div>
                </div>
              </div>
            </slide>
            <slide class="carousel-cell">
              <div class="reviews-item">
                <div class="reviews-card">
                  <div class="comment-head">Максим Снегирев</div>
                  <div class="comment-body">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                    amet lacus accumsan et viverra justo commodo.
                  </div>
                </div>
              </div>
            </slide>

            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
      </section>
      <section class="advantages">
        <h2 class="title-section">Наши преимущества</h2>
        <div class="container">
          <div class="advantages__row">
            <div class="advantages__item">
              <div class="advantage-card">
                <span>
                  Современные направления и актуальные программы обучения
                </span>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantage-card">
                <span>Высококвалифицированный преподавательский состав</span>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantage-card">
                <span>
                  Живое общение с лучшими экспертами и практикующими
                  специалистами
                </span>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantage-card">
                <span>Помогаем уверенно и эффективно добиваться успеха</span>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantage-card">
                <span>Поддержка куратора на всем протяжении обучения</span>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantage-card">
                <span>Погружение в профессиональное сообщество</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="subscription">
        <div class="container">
          <div class="row">
            <div class="subscription__col">
              <img
                src="img/icons/subscription.svg"
                width="70"
                class="mb-3"
                alt=""
              />
              <h2>
                Рассказываем о самых интересных и важных событиях в наших
                рассылках
              </h2>
            </div>

            <div class="subscription__col pl-xl-5">
              <form method="post">
                <div class="form-input">
                  <input type="text" class="form-control" placeholder="Имя *" />
                </div>
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Фамилия *"
                  />
                </div>
                <div class="form-input">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="E-mail *"
                  />
                </div>
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="privacy"
                  />
                  <label class="custom-control-label" for="privacy">
                    Я согласен с политикой конфиденциальности
                  </label>
                </div>
                <div class="form-submit">
                  <button type="submit" class="btn">Подписаться</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section class="documents">
        <h2 class="title-section">Наши лицензии</h2>
        <carousel :items-to-show="4" class="carousel-documents">
          <slide class="carousel-cell">
            <a href="" class="documents-item" target="_blank">
              <img
                src="@/assets/img/certificate.jpg"
                alt="Свидетельстро о государственной аккредитации"
              />
            </a>
          </slide>
          <slide class="carousel-cell">
            <a href="" class="documents-item" target="_blank">
              <img src="@/assets/img/document1.jpg" alt="Выписка" />
            </a>
          </slide>
          <slide class="carousel-cell">
            <a href="" class="documents-item" target="_blank">
              <img
                src="@/assets/img/license.jpg"
                alt="Лицензия на образовательную деятельность"
              />
            </a>
          </slide>
          <slide class="carousel-cell">
            <a href="" class="documents-item" target="_blank">
              <img
                src="@/assets/img/document2.jpg"
                alt="Выписка из реестра лицензий"
              />
            </a>
          </slide>

          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </section>
    </div>
  </LayoutHomepage>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onBeforeMount } from "vue";
import { LayoutHomepage } from "@/layouts/LayoutHomepage";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { RouteName } from "@/constants";

import "@/assets/img/icons/education.svg?sprite";

export default defineComponent({
  name: "HomePageMain",

  components: {
    LayoutHomepage,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  setup() {
    const setMainCss = () => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.id = "main-css";

      document.head.appendChild(link);

      link.href = "/assets/css/main.css";
    };

    const removeMainCss = () => {
      document.getElementById("main-css")?.remove();
    };

    onBeforeMount(() => setMainCss());

    onBeforeUnmount(() => removeMainCss());

    return {
      RouteName,
    };
  },
});
</script>

<style lang="stylus" scoped>
.intro {
  background-image: url("@/assets/img/intro-bg.jpg");
}
.education-icon {
  max-width: 190px;
}
.comment-body,
.comment-head {
  text-align: left;
}
.reviews-item,
.reviews-card {
  height: 100%;
}
</style>
