import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-339afa9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonNavigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (item, i) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        to: { name: item.pathName },
        class: _normalizeClass([
        'commonNavigation__item',
        { commonNavigation__item_active: _ctx.isThisPage(i).value },
      ]),
        key: `nav-item-${i}`,
        onMousedown: _ctx.mousedown,
        onMouseup: _ctx.mouseup
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.label), 1)
        ]),
        _: 2
      }, 1032, ["to", "class", "onMousedown", "onMouseup"]))
    }), 128))
  ]))
}