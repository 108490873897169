<template>
  <div class="testIntro">
    <div class="testIntro__titleBox">
      <h2 class="testIntro__title">Дисциплина</h2>

      <h6 class="testIntro__secondTitle">
        Математическое и компьютерное<br />моделирование
      </h6>
    </div>

    <div class="testIntro__content">
      <h1 class="testIntro__contentTitle testIntro__contentTitle_green">
        Итоговое тестирование по дисциплине
      </h1>

      <CommonBannerIiMessage :title="bannerTitle" :text="bannerText" />

      <hr class="testIntro__hr" />

      <div class="testIntro__ratingsContainer">
        <div
          class="testIntro__ratingQuestionContainer"
          v-for="(item, i) in ratingList"
          :key="`question-${i}`"
        >
          <div class="testIntro__ratingTitle">{{ item.title }}</div>
          <div
            class="testIntro__radioBox"
            v-for="(variable, index) in item.ratings"
            :key="`variable-${index}`"
          >
            <input
              type="radio"
              :id="`variable-${index}`"
              :name="`variable-${i}`"
              class="testIntro__radio"
            />
            <label :for="`variable-${index}`">{{ variable }}</label>
          </div>
        </div>
      </div>

      <CommonButtonPrimary class="testIntro__btn" @click="openTestRules">
        Отправить и перейти к тестированию
      </CommonButtonPrimary>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { bannerTitle, bannerText } from "./constants";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/RouteName";
import { CommonBannerIiMessage } from "@/components/CommonBannerIiMessage";
import { ratingList } from "./constants";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { useVfm } from "vue-final-modal";

export default defineComponent({
  name: "TestIntro",

  components: {
    CommonBannerIiMessage,
    CommonButtonPrimary,
  },

  setup() {
    const vfm = useVfm();
    const router = useRouter();

    const isShowTextForVideo = ref<boolean>(false);

    const toggleTextForVideo = () => {
      isShowTextForVideo.value = !isShowTextForVideo.value;
    };

    const goToTest = () => router.push({ name: RouteName.TEST });

    const openTestRules = () => vfm.open("ModalTestRules");

    return {
      bannerTitle,
      bannerText,
      isShowTextForVideo,
      ratingList,
      openTestRules,
      toggleTextForVideo,
      goToTest,
    };
  },
});
</script>

<style lang="stylus" scoped>
.testIntro {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 35px;
    gap: 40px;
    background-color: $colorLightGrey;
  }

  &__contentTitle {
    text-align: center;

    &_green {
      color: $colorPrimary;
    }
  }

  &__secondTitle {
    margin-top: 15px;
  }

  &__ratingsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__ratingTitle {
    margin-bottom: 2px;
    font-weight: bold;
  }

  &__ratingQuestionContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__radioBox {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__radio {
    margin: 0;
  }

  &__btn {
    margin: 0 auto;
    width: fit-content;
  }

  &__hr {
    width: 100%;
    height: 3px;
    background-color: $colorPrimary;
  }

  +mediaTablet() {
    padding: 2.5vw;
    gap: 4vw;

    &__content {
      gap: 4vw;
    }

    &__ratingsContainer {
      gap: 2vw;
    }

    &__ratingTitle {
      margin-bottom: calc(.7vw - 8px);
    }
  }
}
</style>
