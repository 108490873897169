<template>
  <LayoutBase>
    <div class="chatsPage">
      <div class="chatsPage__list">
        <CommonChatItem
          v-for="chatItem in chatsList"
          :key="chatItem.label"
          :label="chatItem.label"
          :unread-count="3"
        >
          <div class="chatsPage__secondList">
            <router-link
              :to="{ name: RouteName.CHATS, query: { id: item.id } }"
              v-for="(item, i) in chatItem.chats"
              :key="item.id"
              class="chatsPage__link"
            >
              {{ i + 1 }}. {{ item.title }}
            </router-link>
          </div>
        </CommonChatItem>
      </div>

      <div class="chatsPage__content">
        <div class="chatsPage__emptyChat" v-if="!hasOpenChat">
          <svg class="chatsPage__emptyChatIcon">
            <use
              xlink:href="#empty-chat-icon--sprite"
              class="chatsPage__emptyChatIcon"
            />
          </svg>

          <p>Выберите чат для продолжения общения с преподавателем</p>
        </div>

        <CommonChat
          teacher="Пожарская Н. В."
          :chat="chat"
          @close-chat="onCloseChat"
          v-else
        />
      </div>
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { chatsList, chat } from "./constants";
import { useRouter, useRoute } from "vue-router";
import { RouteName } from "@/constants/RouteName";
import { CommonChatItem } from "./components/CommonChatItem";
import { CommonChat } from "@/components/CommonChat";

import "@/assets/img/icons/empty-chat-icon.svg?sprite";

export default defineComponent({
  name: "ChatsPage",

  components: {
    LayoutBase,
    CommonChatItem,
    CommonChat,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const isShowTextForVideo = ref<boolean>(false);

    const hasOpenChat = computed(() => !!route.query.id);

    const toggleTextForVideo = () => {
      isShowTextForVideo.value = !isShowTextForVideo.value;
    };

    const onCloseChat = () =>
      router.push({ name: RouteName.CHATS, query: { id: "" } });

    const goToTest = () => router.push({ name: RouteName.TEST });

    return {
      hasOpenChat,
      RouteName,
      chat,
      chatsList,
      isShowTextForVideo,
      toggleTextForVideo,
      goToTest,
      onCloseChat,
    };
  },
});
</script>

<style lang="stylus" scoped>
.chatsPage {
  display: flex;
  gap: 13px;

  &__list {
    padding: 30px 0;
    width: 40.7%;
    box-shadow: 0 4px 12px 0 rgba(25, 135, 84, 0.25);
    border-radius: 10px;
  }

  &__secondList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 90%;
  }

  &__link {
    text-decoration: none;
    &:hover {
      font-weight: bold;
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__emptyChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  &__emptyChatIcon {
    width: 100%;
    height: 300px;
  }

  +mediaTablet() {
    &__list {
      padding: 3vw 0;
    }
  }
}
</style>
