export const title = "Курс";
export const subTitle =
  "Особо опасные инфекции и действия медперсонала при выявлении больногос подозрением на ООИ";
export const question =
  "Перед вами тест с пропусками слов. Вам необходимо вписать в пропуски недостающие слова и нажать на кнопку «отправить ответ». Система сразу подскажет правильно ли вы выполнили задание. На выполнение вам дается неограниченное кол-во попыток. Выполнить задание можно в любое время, но до наступления итогового теста.";

export const taskOmission = [
  [
    "Магнитный поток обозначается буквой",
    {
      type: "input",
      length: 1,
    },
    "и выражается в таких единицах, как",
    {
      type: "input",
      length: 2,
    },
    "и обозначается",
    {
      type: "input",
      length: 2,
    },
  ],
  [
    "Магнитный поток по такой аналогии характеризует, какое количество",
    {
      type: "input",
      length: null,
    },
    "будет проходить через ограниченный контур.",
  ],
  [
    "Именно под действием магнитного потока создается",
    {
      type: "input",
      length: 3,
    },
    {
      type: "input",
      length: 2,
    },
    "в замкнутом проводнике.",
  ],
  [
    "Магнитный поток пропорционален",
    {
      type: "input",
      length: null,
    },
  ],
  [
    "Магнитный поток прямо пропорционален",
    {
      type: "input",
      length: null,
    },
    "поверхности контура, через который проходят линии магнитной индукции.",
  ],
  [
    "И третье - зависимость магнитного потока от",
    {
      type: "input",
      length: null,
    },
    "наклона контура.",
  ],
];
