export const dropdownsList = [
  {
    label: "Материалы по дисциплинам программы",
    subLabelsList: [
      {
        title: "1 семестр",
        doc: "#",
        content: [
          "Технологии и средства разработки программного обеспечения",
          "Анализ и синтез информационных систем",
          "Модели и методы искусственного интеллекта",
          "Основы менеджмента и маркетинга в медицинской организации",
          "Компьютерное моделирование биологических систем",
        ],
      },
      {
        title: "2 семестр",
        doc: "#",
        content: [
          "Математическое и компьютерное моделирование",
          "Статистические методы анализа медико-биологических данных с элементами статистического моделирования",
          "Моделирование и оптимизация бизнес-процессов",
          "Разработка сетевых приложений и облачные вычисления",
        ],
      },
      {
        title: "3 семестр",
        doc: "#",
        content: [
          "Большие данные и их обработка",
          "Системы поддержки принятия решений",
          "Иностранный язык в сфере профессиональных коммуникаций",
          "Машинное обучение: нейронные сети",
          "Цифровизация здравоохранения",
          "Коммерциализация результатов научных исследований и разработок",
          "Программные продукты как изделия медицинского назначения",
        ],
      },
      {
        title: "4 семестр",
        doc: "#",
        content: [
          "Методология научных исследований",
          "Управление проектами",
          "Управление интеллектуальной собственностью",
          "Защита информации в медицинской организации",
          "Информационная безопасность медицинской организации",
        ],
      },
    ],
  },
  {
    label: "Библиотечные ресурсы",
    subLabelsList: [
      {
        title: "",
        doc: "#",
        content: [],
      },
    ],
  },
];
