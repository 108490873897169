<template>
  <div class="commonTest">
    <div class="commonTest__titleBox">
      <h2 class="commonTest__title">Дисциплина</h2>

      <h6 class="commonTest__secondTitle">
        Математическое и компьютерное<br />моделирование
      </h6>
    </div>

    <div class="commonTest__content">
      <h1 class="commonTest__contentTitle commonTest__contentTitle_green">
        Итоговое тестирование по дисциплине
      </h1>

      <div class="commonTest__inProcess" v-if="!isShowResults">
        <div class="commonTest__inProcessContent">
          <div class="commonTest__inProcessValuesBox">
            <h6>Итоговое тестирование</h6>

            <div class="commonTest__inProcessValues">
              <div
                :class="[
                  'commonTest__inProcessValue',
                  {
                    commonTest__inProcessValue_success:
                      (value.currentAnswer &&
                        value.answers[value.currentAnswer - 1].isSuccess) ||
                      false,
                    commonTest__inProcessValue_fail:
                      (value.currentAnswer &&
                        !value.answers[value.currentAnswer - 1].isSuccess) ||
                      false,
                  },
                ]"
                v-for="value in valuesProcess"
                :key="value.title"
              />
            </div>
          </div>

          <h2 class="commonTest__inProcessTimer">
            {{ minutesLoss }}:{{ secondsLoss }}
          </h2>
        </div>

        <hr class="commonTest__hr" />

        <div class="commonTest__questionContainer">
          <h6 calss="commonTest__questionTitle">
            {{ Number(activeQuestionId) + 1 }}
            вопрос из {{ valuesProcess.length }}
          </h6>

          <div class="commonTest__question">
            <div class="commonTest__questionTitle">
              {{ activeQuestion?.title || "" }}
            </div>

            <div
              class="commonTest__radioBox"
              v-for="(answer, index) in activeQuestion?.answers"
              :key="`variable-${index}`"
            >
              <input
                type="radio"
                :id="`question-${index}`"
                :name="`question-${index}`"
                class="commonTest__radio"
              />
              <label :for="`question-${index}`">{{ answer.label }}</label>
            </div>
          </div>
        </div>

        <CommonButtonPrimary
          class="commonTest__btn"
          v-if="activeQuestionId === valuesProcess.length - 1"
          @click="goToResults"
        >
          Завершить тестирование
        </CommonButtonPrimary>

        <CommonButtonPrimary class="commonTest__btn" v-else>
          Далее
        </CommonButtonPrimary>
      </div>

      <CommonTestResults :is-success="isSuccess" v-else />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { useVfm } from "vue-final-modal";
import { valuesProcess } from "./constants";
import { CommonTestResults } from "../CommonTestResults";

export default defineComponent({
  name: "CommonTest",

  components: {
    CommonButtonPrimary,
    CommonTestResults,
  },

  setup() {
    const vfm = useVfm();

    const isShowResults = ref(false);
    const isSuccess = ref(false);

    const timerSeconds = ref<number>(300);

    const isShowTextForVideo = ref<boolean>(false);

    const minutesLoss = computed(() => Math.floor(timerSeconds.value / 60));
    const secondsLoss = computed(() => timerSeconds.value % 60);

    const activeQuestion = computed(() =>
      valuesProcess.value.find((item: any) => item.currentAnswer === null)
    );

    const activeQuestionId = computed(
      () =>
        activeQuestion.value &&
        valuesProcess.value.indexOf(activeQuestion.value)
    );

    const goToResults = () => (isShowResults.value = true);

    const openTestRules = () => vfm.open("ModalTestRules");

    setInterval(() => {
      if (timerSeconds.value !== 0) {
        timerSeconds.value--;
      }
    }, 1000);

    return {
      isShowResults,
      isSuccess,
      minutesLoss,
      secondsLoss,
      valuesProcess,
      isShowTextForVideo,
      activeQuestion,
      activeQuestionId,
      openTestRules,
      goToResults,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonTest {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__content, &__inProcess {
    display: flex;
    flex-direction: column;
    padding: 35px;
    gap: 40px;
    background-color: $colorLightGrey;
  }

  &__contentTitle {
    text-align: center;

    &_green {
      color: $colorPrimary;
    }
  }

  &__secondTitle {
    margin-top: 15px;
  }

  &__inProcessContent {
    display: flex;
    justify-content: space-between;
  }

  &__inProcessValues {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 14px;
  }

  &__inProcessValue {
    width: 18px;
    height: 18px;
  }

  &__inProcessTimer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 60px;
    color: $colorPrimary;
    border: 2px solid $colorPrimary;
    border-radius: 8px;
  }

  &__hr {
    width: 100%;
    height: 3px;
    background-color: $colorPrimary;
  }

  &__btn {
    margin: 0 auto;
    width: fit-content;
  }

  &__questionTitle {
    margin-top: 20px;
    font-weight: bold;
  }

  &__question {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__radioBox {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__radio {
    margin: 0;
  }

  +mediaTablet() {
    &__inProcessValue {
      width: 1.2vw;
      height: 1.2vw;
      border-radius: 100px;
      border: 1px solid $colorPrimary;

      &_success {
        background-color: $colorSuccess;
      }

      &_fail {
        background-color: $colorDanger;
      }
    }
  }
}
</style>
