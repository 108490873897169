<template>
  <div class="commonEducationDpoCourse">
    <div class="commonEducationDpoCourse__titleBox">
      <h2 class="commonEducationDpoCourse__title">{{ title }}</h2>

      <h6 class="commonEducationDpoCourse__secondTitle">
        {{ subTitle }}
      </h6>
    </div>

    <div>
      <CommonEducationCalendar />
      <div class="commonEducationDpoCourse__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CommonEducationDpoCourseProps } from "./types";
import { CommonEducationCalendar } from "@/components/CommonEducationCalendar";

export default defineComponent({
  name: "CommonEducationDpoCourse",

  props: {
    title: {
      type: String as PropType<CommonEducationDpoCourseProps["title"]>,
    },
    subTitle: {
      type: String as PropType<CommonEducationDpoCourseProps["subTitle"]>,
    },
  },

  components: {
    CommonEducationCalendar,
  },
});
</script>

<style lang="stylus" scoped>
.commonEducationDpoCourse {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 35px;
    gap: 40px;
    background-color: $colorLightGrey;
  }

  &__titleBox {
    width: 50%;
  }

  &__secondTitle,
  &__btn {
    margin-top: 15px;

    &_center {
      margin: auto;
    }
  }

  +mediaTablet() {
    gap: 4vw;

    &__secondTitle,
    &__btn {
      margin-top: 1.5vw;
    }

    &__textForVideo {
      padding: 2vw;
    }
  }
}
</style>
