<template>
  <div class="profileDpoEducation">
    <div class="profileDpoEducation__container">
      <div
        class="profileDpoEducation__item"
        v-for="(education, i) in educationsList"
        :key="`education-${i}`"
      >
        <CommonSelect
          :select-options="educationLevel"
          :hidden-text="'Уровень образования'"
          :value="education.educationLevelValue"
        >
          Уровень образования
        </CommonSelect>

        <CommonSelect
          :select-options="educationSpeciality"
          :hidden-text="'Cпециальность'"
          :value="education.educationSpecialityValue"
        >
          Специальность
        </CommonSelect>
      </div>
    </div>

    <svg class="profileDpoEducation__moreBtn" @click="addMoreEducations">
      <use xlink:href="#add-more-icon--sprite" />
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { CommonSelect } from "@/components/CommonSelect";
import { educationSpeciality, educationLevel } from "../../constants";

import "@/assets/img/icons/add-more-icon.svg?sprite";

export default defineComponent({
  name: "ProfileDpoEducation",

  components: {
    CommonSelect,
  },

  setup() {
    const educationsList = ref([
      {
        educationLevelValue: "test3",
        educationSpecialityValue: "test5",
      },
    ]);

    const addMoreEducations = () => {
      educationsList.value.push({
        educationLevelValue: "",
        educationSpecialityValue: "",
      });
    };

    return {
      educationsList,
      educationSpeciality,
      educationLevel,
      addMoreEducations,
    };
  },
});
</script>

<style lang="stylus" scoped>
.profileDpoEducation {
  max-width: 33.6vw;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 3vw;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }

  &__moreBtn {
    margin-top: 1.5vw;
    width: 2.5vw;
    height: 2.5vw;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
}
</style>
