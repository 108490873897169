<template>
  <div class="commonDropdown">
    <div class="commonDropdown__container" @click="setDropdownShown">
      <div class="commonDropdown__titleBox">
        <h6>{{ label }}</h6>
      </div>

      <svg class="commonDropdown__arrow" v-if="isShownDropdown">
        <use xlink:href="#arrow-dropdown-top-icon--sprite" />
      </svg>

      <svg class="commonDropdown__arrow" v-else>
        <use xlink:href="#arrow-dropdown-bottom-icon--sprite" />
      </svg>
    </div>

    <div
      :class="[
        'commonDropdown__selectContainer',
        {
          commonDropdown__selectContainer_grey: isSelectGrey,
        },
      ]"
      v-if="isShownDropdown"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType } from "vue";
import type { CommonDropdownProps } from "./types";

import "@/assets/img/icons/arrow-dropdown-top-icon.svg?sprite";
import "@/assets/img/icons/arrow-dropdown-bottom-icon.svg?sprite";

export default defineComponent({
  name: "CommonDropdown",

  props: {
    label: {
      type: String as PropType<CommonDropdownProps["label"]>,
      default: "",
    },
    isSelectGrey: {
      type: Boolean as PropType<CommonDropdownProps["isSelectGrey"]>,
      default: false,
    },
  },

  setup() {
    const isShownDropdown = ref<boolean>(false);

    const setDropdownShown = () => {
      isShownDropdown.value = !isShownDropdown.value;
    };

    return {
      isShownDropdown,
      setDropdownShown,
    };
  },
});
</script>

<style lang="stylus">
.v-popper__arrow-container {
  display: none;
}
</style>

<style lang="stylus" scoped>
.commonDropdown {
  position: relative;
  cursor: pointer;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 15px 30px;
    border-radius: 10px;
    color: $colorFiller;
    background-color: $colorPrimary;
    z-index: 1;
  }

  &__arrow {
    width: 33px;
    height: 21px;
  }

  &__selectContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    position: relative;
    top: -10px;
    border-radius: 10px;
    background-color: $colorFiller;

    &_grey {
      background-color: $colorLightGrey;
    }
  }

  +mediaTablet() {
    &__container {
      padding: 2vw 1vw;
    }
  }
}

.dpo .commonDropdown {
  &__container {
    background-color: $colorPrimaryDpo;
  }
}
</style>
