import isDev from "@/utils/isDev";
import { createDirectStore } from "direct-vuex";
import { createLogger } from "vuex";
import userStore from "./modules/userStore";
import programsStore from "./modules/programsStore";

const {
  store,
  rootActionContext: rootActionCtx,
  moduleActionContext: modActionCtx,
  rootGetterContext: rootGetterCtx,
  moduleGetterContext: modGetterCtx,
} = createDirectStore({
  modules: {
    userStore,
    programsStore,
  },
  plugins: isDev ? [createLogger()] : [],
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions and getters.
export { rootActionCtx, modActionCtx, rootGetterCtx, modGetterCtx };

// The following lines enable types in the injected store "$store".
export type AppStore = typeof store;
declare module "vuex" {
  // eslint-disable-next-line
  interface Store<S> {
    direct: AppStore;
  }
}
