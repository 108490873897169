import { ref } from "vue";

export const valuesProcess = ref([
  {
    title: "Вопрос на тему курса разные варианты вопросов?",
    answers: [
      {
        label: "ответ 1",
        isSuccess: false,
      },
      {
        label: "ответ 2",
        isSuccess: true,
      },
      {
        label: "ответ 3",
        isSuccess: false,
      },
    ],
    currentAnswer: 2,
  },
  {
    title: "Вопрос на тему курса разные варианты вопросов?",
    answers: [
      {
        label: "ответ 1",
        isSuccess: false,
      },
      {
        label: "ответ 2",
        isSuccess: true,
      },
      {
        label: "ответ 3",
        isSuccess: false,
      },
    ],
    currentAnswer: 1,
  },
  {
    title: "Вопрос на тему курса разные варианты вопросов?",
    answers: [
      {
        label: "ответ 1",
        isSuccess: false,
      },
      {
        label: "ответ 2",
        isSuccess: true,
      },
      {
        label: "ответ 3",
        isSuccess: false,
      },
    ],
    currentAnswer: 2,
  },
  {
    title: "Вопрос на тему курса разные варианты вопросов?",
    answers: [
      {
        label: "ответ 1",
        isSuccess: false,
      },
      {
        label: "ответ 2",
        isSuccess: true,
      },
      {
        label: "ответ 3",
        isSuccess: false,
      },
    ],
    currentAnswer: 2,
  },
  {
    title: "Вопрос на тему курса разные варианты вопросов?",
    answers: [
      {
        label: "ответ 1",
        isSuccess: false,
      },
      {
        label: "ответ 2",
        isSuccess: true,
      },
      {
        label: "ответ 3",
        isSuccess: false,
      },
    ],
    currentAnswer: 2,
  },
  {
    title: "Вопрос на тему курса разные варианты вопросов?",
    answers: [
      {
        label: "ответ 1",
        isSuccess: false,
      },
      {
        label: "ответ 2",
        isSuccess: true,
      },
      {
        label: "ответ 3",
        isSuccess: false,
      },
    ],
    currentAnswer: 2,
  },
  {
    title: "Вопрос на тему курса разные варианты вопросов?",
    answers: [
      {
        label: "ответ 1",
        isSuccess: false,
      },
      {
        label: "ответ 2",
        isSuccess: true,
      },
      {
        label: "ответ 3",
        isSuccess: false,
      },
    ],
    currentAnswer: 1,
  },
  {
    title: "Вопрос на тему курса разные варианты вопросов?",
    answers: [
      {
        label: "ответ 1",
        isSuccess: false,
      },
      {
        label: "ответ 2",
        isSuccess: true,
      },
      {
        label: "ответ 3",
        isSuccess: false,
      },
    ],
    currentAnswer: 3,
  },
  {
    title: "Вопрос на тему курса разные варианты вопросов?",
    answers: [
      {
        label: "ответ 1",
        isSuccess: false,
      },
      {
        label: "ответ 2",
        isSuccess: true,
      },
      {
        label: "ответ 3",
        isSuccess: false,
      },
    ],
    currentAnswer: null,
  },
]);
