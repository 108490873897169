<template>
  <LayoutBase>
    <TestIntro v-if="!isOpenTest" />

    <CommonTest v-else />
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { TestIntro } from "./components/TestIntro";
import { CommonTest } from "./components/CommonTest";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "TestPage",

  components: {
    LayoutBase,
    TestIntro,
    CommonTest,
  },

  setup() {
    const route = useRoute();

    const isOpenTest = computed(() => 123 === Number(route.query.id));

    return {
      isOpenTest,
    };
  },
});
</script>

<style lang="stylus" scoped>
.testPage {
}
</style>
