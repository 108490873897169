<template>
  <section class="intro">
    <div class="intro__layout">
      <div class="container">
        <div class="intro-logo">
          <svg class="footer__logo">
            <use xlink:href="#logo-white-icon--sprite" />
          </svg>
        </div>
        <h1 class="intro-title">{{ title }}</h1>
        <div class="intro-action">
          <a href="#application" class="btn">Подать заявку</a>
        </div>
      </div>
      <div class="intro__slogan">
        <div class="container">Развивайте свое будущее вместе с нами</div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";

export default defineComponent({
  name: "CommonIntro",

  components: {},

  setup() {
    const route = useRoute();

    const title = ref("ОРДИНАТУРА");

    if (route?.name === RouteName.HOME_ORDINAT_FAQ) {
      title.value = "ОРДИНАТУРА";
    } else if (route?.name === RouteName.HOME_ASPIRANT_FAQ) {
      title.value = "АСПИРАНТУРА";
    } else if (route?.name === RouteName.HOME_COURSES_FAQ) {
      title.value = "ДОПОЛНИТЕЛЬНОЕ ОБРАЗОВАНИЕ";
    }

    return {
      title,
    };
  },
});
</script>

<style lang="stylus" scoped>
.intro {
  background-image: url("@/assets/img/intro-bg.jpg");
}
</style>
