export const educationList = [
  {
    label: "Практический онлайн-курс для руководителей и топ-менеджеров",
    status: "active",
    date: null,
  },
  {
    label: "Организация работы врачебной комиссии медицинской организации",
    status: "completed",
    date: null,
  },
  {
    label: "Практический онлайн-курс для руководителей и топ-менеджеров",
    status: "started",
    date: "18.04.2024",
  },
];
