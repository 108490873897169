<template>
  <main class="profileDpo">
    <div class="profileDpo__left">
      <section class="profileDpo__about">
        <ProfileDpoAbout />
      </section>

      <section class="profileDpo__education">
        <h6
          class="profileDpo__title"
          @click="
            sectionsState.isShowEducation = !sectionsState.isShowEducation
          "
        >
          Образование

          <svg class="profileDpo__arrow" v-if="sectionsState.isShowEducation">
            <use xlink:href="#arrow-top-icon--sprite" />
          </svg>

          <svg class="profileDpo__arrow" v-else>
            <use xlink:href="#arrow-bottom-icon--sprite" />
          </svg>
        </h6>

        <ProfileDpoEducation v-if="sectionsState.isShowEducation" />
      </section>

      <section class="profileDpo__qualification">
        <h6 class="profileDpo__title">Повышение квалификации</h6>

        <ProfileDpoQualification />
      </section>

      <section class="profileDpo__career">
        <h6
          class="profileDpo__title"
          @click="sectionsState.isShowCareer = !sectionsState.isShowCareer"
        >
          Карьера

          <svg class="profileDpo__arrow" v-if="sectionsState.isShowCareer">
            <use xlink:href="#arrow-top-icon--sprite" />
          </svg>

          <svg class="profileDpo__arrow" v-else>
            <use xlink:href="#arrow-bottom-icon--sprite" />
          </svg>
        </h6>

        <ProfileDpoCareer v-if="sectionsState.isShowCareer" />
      </section>

      <section class="profileDpo__changePass">
        <h6 class="profileDpo__title">Смена пароля</h6>

        <ProfileDpoChangePass />
      </section>
    </div>

    <div class="profileDpo__right">
      <CommonBannerYourPoints :pointsCount="24" />

      <CommonBannerChatBot />

      <CommonBannerYourMentor />

      <CommonBannerCheckDocks v-if="0" />
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import CommonBannerChatBot from "@/components/CommonBannerChatBot/index.vue";
import CommonBannerYourMentor from "@/components/CommonBannerYourMentor/index.vue";
import { CommonBannerYourPoints } from "@/components/CommonBannerYourPoints";
import { CommonBannerCheckDocks } from "@/components/CommonBannerCheckDocks";
import { ProfileDpoAbout } from "./components/ProfileDpoAbout";
import { ProfileDpoEducation } from "./components/ProfileDpoEducation";
import { ProfileDpoQualification } from "./components/ProfileDpoQualification";
import { ProfileDpoCareer } from "./components/ProfileDpoCareer";
import { ProfileDpoChangePass } from "./components/ProfileDpoChangePass";

export default defineComponent({
  name: "ProfileDpo",

  components: {
    CommonBannerYourPoints,
    CommonBannerChatBot,
    CommonBannerYourMentor,
    CommonBannerCheckDocks,
    ProfileDpoAbout,
    ProfileDpoEducation,
    ProfileDpoQualification,
    ProfileDpoCareer,
    ProfileDpoChangePass,
  },

  setup() {
    const sectionsState = reactive({
      isShowEducation: true,
      isShowCareer: true,
    });

    return {
      sectionsState,
    };
  },
});
</script>

<style lang="stylus">
.profileDpo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5vw;

  &__left,
  &__right {
    flex-grow: 1;
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    max-width: 315px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 25px;

    +mediaTablet() {
      gap: 2.7vw;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: .7vw;
    margin-bottom: 15px;

    +mediaTablet() {
      margin-bottom: 1.5vw;
    }
  }

  &__arrow {
    width: 24px;
    height: 24px;
  }
}
</style>
