import {
  State,
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicFetch,
} from "@/store/storeUtils";
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

import { path, pathOr } from "rambda";

import { defineModule } from "direct-vuex";
import { modActionCtx, modGetterCtx } from "@/store/index";
import { api } from "@/utils/apiInstance";

import { Statuses } from "@/constants/statuses";
import { router } from "@/router";
import qs from "qs";

import authCookies from "@/utils/cookies/authCookies";

const userStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState(),
  },

  getters: {},
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = Statuses.INIT;
    },
    // ...getFetchMutations(),
    // SET_AUTH(state, isAuth: boolean) {
    //   state.isAuth = isAuth;
    // },
    // UPDATE_DATA(state, payload: any) {
    //   if (!state.response) {
    //     return;
    //   }
    //   const newResponse = {
    //     ...state.response,
    //     data: {
    //       ...state.response.data,
    //       data: {
    //         ...state.response.data.data,
    //         ...payload,
    //       },
    //     },
    //   };
    //   state.response = newResponse;
    // },
    // UPDATE_FORGOT(state, payload: boolean) {
    //   state.isForgot = payload;
    // },
    // SET_TWO_AUTH(state, two: boolean) {
    //   state.with2FaAuth = two;
    // },
  },
  actions: {
    async register(
      ctx,
      payload: {
        name: string;
        surname: string;
        patronymic: string;
        email: string;
        password: string;
        confirm_password: string;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendPost,
        props: {
          url: "/api/register",
          data: {
            ...payload,
          },
        },
        setState: commit.SET_STATE,
        callback: async (fetchState) => {
          if (fetchState === Statuses.REJECTED) {
            console.error("err");
          }

          if (fetchState === Statuses.FULFILLED) {
            commit.SET_INIT();
            console.log(state.response);

            router.replace("/");
          }
        },
      });
    },

    async login(
      ctx,
      payload: {
        email: string;
        password: string;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendPost,
        props: {
          url: "/api/login",
          data: {
            ...payload,
          },
        },
        setState: commit.SET_STATE,
        callback: async (fetchState) => {
          if (fetchState === Statuses.REJECTED) {
            console.error("err");
          }

          if (fetchState === Statuses.FULFILLED) {
            commit.SET_INIT();
            console.log(state.response);

            router.replace("/");
          }
        },
      });
    },
  },
});

export default userStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, userStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, userStore);
