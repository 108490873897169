<template>
  <LayoutHomepage>
    <CommonIntro />

    <div class="page-section">
      <div class="nav-overflow">
        <nav class="tabs-menu">
          <ul class="nav nav-tabs">
            <li
              class="nav-item intro__nav"
              v-for="item in homeNavList"
              :key="item.label"
              v-show="
                !(
                  (item.label === 'Документы' ||
                    item.label === 'Приемная кампания') &&
                  hasCourses
                )
              "
            >
              <router-link
                :class="[
                  'nav-link',
                  {
                    active:
                      pathName(
                        item.pathOrdinatName,
                        item.pathAspirantName,
                        item.pathCoursesName
                      ).value === route?.name,
                  },
                ]"
                :to="{
                  name: pathName(
                    item.pathOrdinatName,
                    item.pathAspirantName,
                    item.pathCoursesName
                  ).value,
                  query: route.query,
                }"
                @click="toggleBodyStyle"
              >
                {{ item.label }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <h2 class="section-title">Документы</h2>
      <CommonQuestion
        :question="item.title"
        isSlotAnswer
        v-for="item in programOrd?.documents"
        :key="item.title"
      >
        <div
          class="answer"
          v-for="answer in item.list"
          :key="answer"
          v-html="answer"
        />
      </CommonQuestion>
    </div>

    <CommonApplication />
  </LayoutHomepage>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import { LayoutHomepage } from "@/layouts/LayoutHomepage";
import { basicFetch } from "@/store/storeUtils";
import { api } from "@/utils/apiInstance";
import { CommonApplication } from "./components/CommonApplication";
import { CommonIntro } from "./components/CommonIntro";
import { useRouter, useRoute } from "vue-router";
import { homeNavList } from "./constants";
import { RouteName } from "@/constants";
import { CommonQuestion } from "@/components/CommonQuestion";
import store from "@/store";

import "@/assets/img/icons/cert-icon-0.svg?sprite";
import "@/assets/img/icons/cert-icon-1.svg?sprite";

export default defineComponent({
  name: "HomeDocsPage",

  components: {
    LayoutHomepage,
    CommonApplication,
    CommonIntro,
    CommonQuestion,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const programOrd = computed(() => store.getters.programsStore.data);
    const showDiscipline = ref(1);

    const hasOrdinat = computed(() => useRoute()?.path.includes("ordinat"));
    const hasAspirant = computed(() => route?.path.includes("aspirant"));
    const hasCourses = computed(() => route?.path.includes("courses"));

    const bodyStyleName = computed(() => {
      if (route?.name === RouteName.HOME_ORDINAT_DOCS) {
        return "ordinat";
      } else if (route?.name === RouteName.HOME_ASPIRANT_DOCS) {
        return "aspirant";
      }
      return "courses";
    });

    const queryId = computed(() => route?.query?.id);

    const programId = computed(() => {
      if (queryId.value) return Number(queryId.value);

      if (route.path.includes("ordinat")) {
        return 1;
      } else if (route.path.includes("aspirant")) {
        return 2;
      }

      return 4;
    });

    const hasShowDisciplines = (id: number) =>
      computed(() => Number(route.query.id) === id);

    const goToDisciplines = (id: number) => {
      router.push({ query: { id } });
      showDiscipline.value = id;
    };

    const pathName = (ordinatPath: any, aspirantPath: any, coursesPath: any) =>
      computed(() => {
        if (hasOrdinat.value) {
          return ordinatPath;
        } else if (hasAspirant.value) {
          return aspirantPath;
        }
        return coursesPath;
      });

    const toggleBodyStyle = () => {
      document.body.classList.add(bodyStyleName.value);
    };

    onMounted(async () => {
      toggleBodyStyle();

      store.dispatch.programsStore.getPrograms({ program_id: programId.value });
    });

    return {
      hasCourses,
      programOrd,
      showDiscipline,
      homeNavList,
      route,
      toggleBodyStyle,
      pathName,
      goToDisciplines,
      hasShowDisciplines,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  &__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
}
</style>
