<template>
  <div class="educationList">
    <div
      class="educationList__item"
      v-for="(item, i) in educationList"
      :key="item.label"
    >
      <hr v-if="i === 0" class="educationList__hr" />

      <router-link :to="{ name: RouteName.LESSON_DPO }">
        <CommonEducationStatus
          :label="item.label"
          :status="item.status"
          :date="item.date"
        />
      </router-link>

      <hr class="educationList__hr" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { CommonEducationStatus } from "@/components/CommonEducationStatus";
import { educationList } from "./constants";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";

export default defineComponent({
  name: "EducationList",

  components: {
    CommonEducationStatus,
  },

  setup() {
    const route = useRoute();

    const isShowTextForVideo = ref<boolean>(false);

    const hasEducationMain = computed(
      () => route?.name === RouteName.EDUCATION
    );

    const toggleTextForVideo = () => {
      isShowTextForVideo.value = !isShowTextForVideo.value;
    };

    return {
      RouteName,
      hasEducationMain,
      educationList,
      isShowTextForVideo,
      toggleTextForVideo,
    };
  },
});
</script>

<style lang="stylus" scoped>
.educationList {
  display: flex;
  flex-direction: column;

  &__secondTitle,
  &__btn {
    margin-top: 15px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__video {
    width: 100%;
    height: 39.2vw;
    border-radius: 53px;
  }

  &__textForVideo {
    padding: 20px;
    border-radius: 10px;
    background-color: $colorBgGrey;
  }

  &__hr {
    width: 100%;
    height: 2px;
    background-color: $colorFontBase;
    border: none;
  }

  +mediaTablet() {
    gap: 4vw;
    &__secondTitle,
    &__btn {
      margin-top: 1.5vw;
    }

    &__item {
      gap: 4vw;
    }

    &__textForVideo {
      padding: 2vw;
    }
  }
}
</style>
