<template>
  <router-link
    :to="{ name: RouteName.HOMEWORKS, query: { id } }"
    class="homeworkLink"
    @click="scrollToTop"
  >
    <div class="homeworkLink__titleBox">
      <div class="homeworkLink__tabsBox">
        <div class="homeworkLink__tab">{{ deadline }}</div>

        <div
          :class="[
            'homeworkLink__tab',
            'homeworkLink__statusTab',
            `homeworkLink__statusTab_${status}`,
          ]"
        >
          <p class="homeworkLink__dot">&bull;</p>
          {{ statusLabel }}
        </div>
      </div>

      <h6>{{ label }}</h6>
    </div>

    <svg class="homeworkLink__arrow">
      <use xlink:href="#arrow-dropdown-right-icon--sprite" />
    </svg>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { HomeworkLinkProps } from "./types";
import { RouteName } from "@/constants";

import "@/assets/img/icons/arrow-dropdown-right-icon.svg?sprite";

export default defineComponent({
  name: "HomeworkLink",

  props: {
    label: {
      type: String as PropType<HomeworkLinkProps["label"]>,
      default: "",
    },
    statusLabel: {
      type: String as PropType<HomeworkLinkProps["statusLabel"]>,
      default: "",
    },
    deadline: {
      type: String as PropType<HomeworkLinkProps["deadline"]>,
      default: "",
    },
    status: {
      type: String as PropType<HomeworkLinkProps["status"]>,
      default: "",
    },
    index: {
      type: Number as PropType<HomeworkLinkProps["index"]>,
      default: 0,
    },
    id: {
      type: Number as PropType<HomeworkLinkProps["id"]>,
      required: true,
    },
  },

  setup() {
    const scrollToTop = window.scrollTo(0, 0);

    return {
      RouteName,
      scrollToTop,
    };
  },
});
</script>

<style lang="stylus" scoped>
.homeworkLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-radius: 10px;
  background-color: $colorPrimary;
  color: $colorFiller;
  text-decoration: none;

  &__titleBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__tabsBox {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__arrow {
    width: 33px;
    height: 21px;
  }

  &__selectContainer {
    padding: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__tab {
    padding: 4px;
    border-radius: 5px;
    background-color: $colorFiller;
    color: $colorFontBase;
  }

  &__dot {
    margin: 0;
  }

  &__statusTab {
    display: flex;
    align-items: center;
    gap: 4px;

    &_sent {
      background-color: #DFFFEE;
      color: $colorGreenMedium;
    }

    &_examination {
      background-color: #9E9E9E;
      color: $colorFiller;
    }

    &_complete {
      background-color: $colorSuccess;
      color: $colorFiller;
    }

    &_notComplete {
      background-color: $colorDanger;
      color: $colorFiller;
    }

    &_remake {
      background-color: $colorFontBase;
      color: $colorFiller;
    }
  }

  +mediaTablet() {
    padding: 1.5vw 2vw;
  }
}
</style>
