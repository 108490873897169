<template>
  <LayoutAuth :modalName="'signInModal'" title="Вход в профиль">
    <template #title>Вход в профиль</template>

    <template #content>
      <CommonInput>Электронная почта</CommonInput>

      <CommonInput type="password">Пароль</CommonInput>

      <CommonButtonPrimary @click="signIn">Войти</CommonButtonPrimary>
      <CommonButtonSecondary @click="openRegistrationModal">
        Создать профиль
      </CommonButtonSecondary>
    </template>
  </LayoutAuth>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LayoutAuth } from "@/layouts/LayoutAuth/index";
import { CommonInput } from "@/components/CommonInput/index";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary/index";
import { CommonButtonSecondary } from "@/components/CommonButtonSecondary/index";
import { useVfm } from "vue-final-modal";
import store from "@/store";

export default defineComponent({
  name: "ModalSignIn",
  components: {
    LayoutAuth,
    CommonInput,
    CommonButtonPrimary,
    CommonButtonSecondary,
  },

  setup() {
    const vfm = useVfm();

    const signIn = async () => {
      await store.dispatch.userStore.login({
        email: "admin@admin.ru",
        password: "admin",
      });
    };

    const openRegistrationModal = () => {
      vfm.close("signInModal");
      vfm.open("registrationModal");
    };

    return {
      signIn,
      openRegistrationModal,
    };
  },
});
</script>

<style lang="stylus" scoped>
.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 13px;
}
</style>
