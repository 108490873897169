<template>
  <div class="educationWorks">
    <CommonButtonPrimary class="educationWorks__btn">
      Добавить
    </CommonButtonPrimary>

    <div class="educationWorks__inputBox">
      <CommonInput
        placeholder="Напишите ваш ответ"
        class="educationWorks__input"
      >
        Дисциплина
      </CommonInput>

      <CommonDownloadFile label="Прикрепить файл" file="#" />
    </div>

    <div class="educationWorks__textareaBoxWrap">
      <div class="educationWorks__textareaBox">
        <label class="educationWorks__label">Отзыв</label>
        <textarea
          placeholder="Напишите ваш ответ"
          class="educationWorks__textarea"
        />
      </div>

      <CommonDownloadFile label="Прикрепить файл" file="#" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { CommonInput } from "@/components/CommonInput";
import { CommonDownloadFile } from "@/components/CommonDownloadFile";

export default defineComponent({
  name: "EducationWorks",

  components: {
    CommonButtonPrimary,
    CommonInput,
    CommonDownloadFile,
  },

  setup() {
    return {};
  },
});
</script>

<style lang="stylus">
.educationWorks {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;

  &__btn {
    width: fit-content;
  }

  &__inputBox,
  &__textareaBoxWrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__textarea {
    padding: 30px;
    width: 100%;
    height: 10vw;
    resize: none;
    border-radius: 10px;

    &:focus {
      outline: none;
      border: 1px solid $colorPrimary;
    }
  }

  &__textareaBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__label {
    getFont("text_16");
  }

  +mediaTablet() {
    padding: 3vw 0;
    gap: 3vw;

    &__inputBox {
      gap: 1.1vw;
    }

    &__inputBox {
      max-width: 50%;
    }
  }
}
</style>
