import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0e112407"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profileDpoChangePass" }
const _hoisted_2 = { class: "profileDpoChangePass__inputsBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CommonInput, null, {
        default: _withCtx(() => [
          _createTextVNode("Логин")
        ]),
        _: 1
      }),
      _createVNode(_component_CommonInput, { type: "password" }, {
        default: _withCtx(() => [
          _createTextVNode("Текущий пароль")
        ]),
        _: 1
      }),
      _createVNode(_component_CommonInput, { type: "password" }, {
        default: _withCtx(() => [
          _createTextVNode("Новый пароль")
        ]),
        _: 1
      }),
      _createVNode(_component_CommonInput, { type: "password" }, {
        default: _withCtx(() => [
          _createTextVNode("Подтвердите новый пароль")
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_CommonButtonPrimary, null, {
      default: _withCtx(() => [
        _createTextVNode("Сохранить новый пароль")
      ]),
      _: 1
    })
  ]))
}