import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-72275f8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "docsPage" }
const _hoisted_2 = { class: "docsPage__linksList" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonNavigation = _resolveComponent("CommonNavigation")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!

  return (_openBlock(), _createBlock(_component_LayoutBase, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CommonNavigation, { "nav-list": _ctx.navList }, null, 8, ["nav-list"]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (doc) => {
            return (_openBlock(), _createElementBlock("a", {
              href: doc.link,
              class: "docsPage__link",
              download: "",
              key: doc.label
            }, _toDisplayString(doc.label), 9, _hoisted_3))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}