<template>
  <div class="commonNotifications">
    <div class="commonNotifications__header">
      <h4 class="commonNotifications__title">Уведомления</h4>

      <svg class="commonNotifications__headerIcon">
        <use xlink:href="#settings-icon--sprite" />
      </svg>
    </div>

    <hr class="commonNotifications__hr" />

    <p class="commonNotifications__unread opacity-text">
      Непрочитанные ({{ unreadNotificationsCount }})
    </p>

    <div class="commonNotifications__content">
      <div
        class="commonNotifications__item"
        v-for="notification in notificationsList"
        :key="notification.title"
      >
        <div class="commonNotifications__iconBox">
          <svg class="commonNotifications__icon">
            <use xlink:href="#notification-icon--sprite" />
          </svg>

          <div
            class="commonNotifications__iconUnread"
            v-if="!notification.isRead"
          />
        </div>

        <div class="commonNotifications__contentText">
          <p>{{ notification.title }}</p>

          <p>{{ notification.text }}</p>

          <p class="opacity-text">{{ notification.date }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { notificationsList } from "./constants";

import "@/assets/img/icons/settings-icon.svg?sprite";
import "@/assets/img/icons/notification-icon.svg?sprite";

export default defineComponent({
  name: "CommonNotifications",

  setup() {
    const unreadNotificationsCount =
      notificationsList.filter((item) => !item.isRead).length || 0;

    return {
      notificationsList,
      unreadNotificationsCount,
    };
  },
});
</script>

<style lang="stylus" scoped>
p {
  margin: 0;
}
.commonNotifications {
  padding: 15px 10px;
  max-width: 522px;
  max-height: calc(100vh - 150px);
  overflow-y: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: normal;
  }

  &__headerIcon {
    width: 22px;
    height: 4px;
    cursor: pointer;

    &:hover {
      opacity: .6;
    }
  }

  &__hr {
    margin: 15px 0;
    width: 100%;
    height: 1px;
    background-color: $colorMediumGrey;
    border: none;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 0;
    &:hover {
      background-color: $colorLightGrey;
      cursor: pointer;
    }
  }

  &__iconBox {
    position: relative;
  }

  &__icon {
    width: 40px;
    height: 40px;
    position: relative;
  }

  &__iconUnread {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 10px;
    height: 10px;
    background-color: $colorDanger;
    border-radius: 100px;
  }

  &__contentText {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  +mediaTablet() {
    padding: 1.5vw 1vw;

    &__hr {
      margin: 1vw 0;
    }

    &__item {
      gap: 1vw;
      padding: 1vw 0;
    }
  }
}
</style>
