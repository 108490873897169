<template>
  <LayoutBase>
    <div class="recommendedCoursesDpo">
      <div
        class="recommendedCoursesDpo__item"
        v-for="item in 6"
        :key="`course-${item}`"
      >
        <iframe
          class="recommendedCoursesDpo__video"
          src="https://rutube.ru/play/embed/9fa66fc2e001793df1af22f0f26947ba/"
          frameBorder="0"
          allow="clipboard-write; autoplay"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        />

        <h5 class="recommendedCoursesDpo__title">Курс</h5>

        <p>
          Актуальные вопросы организации лечебного и профилактического питания
        </p>

        <CommonButtonPrimary>123</CommonButtonPrimary>
      </div>
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { subLabelsList, dropdownsList } from "./constants";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/RouteName";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";

export default defineComponent({
  name: "RecommendedCoursesDpo",

  components: {
    LayoutBase,
    CommonButtonPrimary,
  },

  setup() {
    const router = useRouter();

    const isShowTextForVideo = ref<boolean>(false);

    const toggleTextForVideo = () => {
      isShowTextForVideo.value = !isShowTextForVideo.value;
    };

    const goToTest = () => router.push({ name: RouteName.TEST });

    return {
      dropdownsList,
      subLabelsList,
      isShowTextForVideo,
      toggleTextForVideo,
      goToTest,
    };
  },
});
</script>

<style lang="stylus" scoped>
.recommendedCoursesDpo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px 26px;

  &__video {
    border-radius: 10px;
  }

  &__title {
    color: $colorPrimaryDpo;
  }
}
</style>
