<template>
  <div class="commonPagination">
    <div class="commonPagination__arrow-wrap">
      <svg
        class="commonPagination__arrow"
        v-if="currentPage !== 1"
        @click="$emit('changePage', currentPage - 1)"
      >
        <use xlink:href="#pagination-arrow-left-icon--sprite" />
      </svg>
    </div>

    <div class="commonPagination__pagesBox">
      <button
        :class="[
          'commonPagination__button',
          { commonPagination__button_active: item === currentPage },
        ]"
        v-for="item in pagesCount"
        :key="`page-${item}`"
        @click="$emit('changePage', item)"
      >
        {{ item }}
      </button>
    </div>

    <div class="commonPagination__arrow-wrap">
      <svg
        v-if="currentPage !== pagesCount"
        class="commonPagination__arrow"
        @click="$emit('changePage', currentPage + 1)"
      >
        <use xlink:href="#pagination-arrow-right-icon--sprite" />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CommonPaginationProps } from "./types";

import "@/assets/img/icons/pagination-arrow-left-icon.svg?sprite";
import "@/assets/img/icons/pagination-arrow-right-icon.svg?sprite";

export default defineComponent({
  name: "CommonPagination",

  props: {
    currentPage: {
      type: Number as PropType<CommonPaginationProps["currentPage"]>,
      default: 1,
    },
    pagesCount: {
      type: Number as PropType<CommonPaginationProps["pagesCount"]>,
      default: 1,
    },
  },
});
</script>

<style lang="stylus" scoped>
.commonPagination {
  display: flex;
  justify-content: center;
  gap: 16px;

  &__arrow-wrap {
    width: 32px;
    height: 32px;
    user-select: none;
  }
  &__arrow {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__pagesBox {
    display: flex;
    gap: 5px;
  }

  &__button {
    width: 32px;
    height: 32px;
    background-color: $colorFiller;
    color: $colorPrimary;
    border: none;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      border: 1px solid $colorPrimary;
    }

    &_active {
      cursor: default;
      background-color: $colorPrimary;
      color: $colorFiller;
    }
  }
}

.dpo .commonPagination {
  &__button {
    color: $colorPrimaryDpo;

    &:hover {
      border: 1px solid $colorPrimaryDpo;
    }

    &_active {
      background-color: $colorPrimaryDpo;
      color: $colorFiller;
    }
  }
}
</style>
