import { ProfileDpo } from "./components/ProfileDpo";
import { ProfileDocsDpo } from "./components/ProfileDocsDpo";
import { ProfileSupportDpo } from "./components/ProfileSupportDpo";

export const tabsList = [
  { value: "info", label: "Личная информация", component: ProfileDpo },
  { value: "docs", label: "Документы об обучении", component: ProfileDocsDpo },
  {
    value: "support",
    label: "Техническая поддержка",
    component: ProfileSupportDpo,
  },
];
