<template>
  <LayoutBase v-if="hasEducationMain">
    <div class="educationPage">
      <EducationList />
    </div>
  </LayoutBase>

  <router-view v-else />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { eventsList } from "./constants";
import { EducationList } from "./components/EducationList";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";

export default defineComponent({
  name: "EducationDpoPage",

  components: {
    LayoutBase,
    EducationList,
  },

  setup() {
    const route = useRoute();

    const isShowTextForVideo = ref<boolean>(false);

    const hasEducationMain = computed(
      () => route?.name === RouteName.EDUCATION_DPO
    );

    const toggleTextForVideo = () => {
      isShowTextForVideo.value = !isShowTextForVideo.value;
    };

    return {
      hasEducationMain,
      eventsList,
      isShowTextForVideo,
      toggleTextForVideo,
    };
  },
});
</script>

<style lang="stylus" scoped>
.educationPage {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__secondTitle,
  &__btn {
    margin-top: 15px;
  }

  &__video {
    width: 100%;
    height: 39.2vw;
    border-radius: 53px;
  }

  &__textForVideo {
    padding: 20px;
    border-radius: 10px;
    background-color: $colorBgGrey;
  }

  +mediaTablet() {
    gap: 4vw;

    &__secondTitle,
    &__btn {
      margin-top: 1.5vw;
    }

    &__textForVideo {
      padding: 2vw;
    }
  }
}
</style>
