export const bannerTitle = "Уважаемый слушатель!";
export const bannerText =
  "Перед тем как приступить к тестированию, ответьте на несколько вопросов о пройденном обучении. Нам важно знать ваше мнение!";

export const ratingList = [
  {
    title: "Как вы оцениваете качество поданного материала?",
    ratings: [
      "Отлично, все понятно и интересно",
      "Хорошо, но хотелось бы больше матерала",
      "Удовлетворительно",
      "Плохо, темы не понимаю",
      "Затрудняюсь ответить",
    ],
  },
  {
    title: "Удовлетворены ли вы спикером программы?",
    ratings: [
      "Отлично, все понятно и интересно",
      "Хорошо, но хотелось бы больше матерала",
      "Удовлетворительно",
    ],
  },
  {
    title: "Как вы оцениваете качество поданного материала?",
    ratings: [
      "Отлично, все понятно и интересно",
      "Хорошо, но хотелось бы больше матерала",
      "Удовлетворительно",
      "Плохо, темы не понимаю",
      "Затрудняюсь ответить",
    ],
  },
  {
    title: "Как вы оцениваете качество поданного материала?",
    ratings: [
      "Отлично, все понятно и интересно",
      "Хорошо, но хотелось бы больше матерала",
      "Удовлетворительно",
      "Плохо, темы не понимаю",
      "Затрудняюсь ответить",
    ],
  },
  {
    title: "Как вы оцениваете качество поданного материала?",
    ratings: [
      "Отлично, все понятно и интересно",
      "Хорошо, но хотелось бы больше матерала",
      "Удовлетворительно",
    ],
  },
];
