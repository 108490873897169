export const dropdownsList = [
  {
    label: "Учебные работы",
    subLabelsList: [
      {
        title: "Выполненные работы по дисциплинам",
        doc: "#",
      },
      {
        title: "Отчёты по практике",
        doc: "#",
      },
      {
        title: "Выпускная работа",
        doc: "#",
      },
    ],
  },
  {
    label: "Научные работы",
    subLabelsList: [
      {
        title: "Публикации в научных изданиях",
        doc: "#",
      },
      {
        title: "Другие работы",
        doc: "#",
      },
    ],
  },
  {
    label: "Результаты внеучебной деятельности",
    subLabelsList: [
      {
        title: "",
        doc: "#",
      },
    ],
  },
  {
    label: "Сертификаты участника мероприятий",
    subLabelsList: [
      {
        title: "",
        doc: "#",
      },
      {
        title: "Сертификаты",
        doc: "#",
      },
    ],
  },
];
