import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9c44e0b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonDocsDownload" }
const _hoisted_2 = { class: "commonDocsDownload__labelBox" }
const _hoisted_3 = { class: "commonDocsDownload__label" }
const _hoisted_4 = { class: "commonDocsDownload__subLabel" }
const _hoisted_5 = { class: "commonDocsDownload__btnBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.subLabel), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (1)
        ? (_openBlock(), _createBlock(_component_CommonButtonPrimary, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode("Скачать")
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_CommonButtonPrimary, {
            key: 1,
            disabled: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Ожидается загрузка ")
            ]),
            _: 1
          }))
    ])
  ]))
}