<template>
  <LayoutBase>
    <div class="homeworksPage" v-if="!isOpenHomework">
      <h2 class="homeworksPage__title">Домашние задания</h2>

      <div class="homeworksPage__list">
        <HomeworkLink
          v-for="(homework, i) in homeworksAfterSlice"
          :key="`homework-${i}`"
          :label="homework.label"
          :statusLabel="homework.statusLabel"
          :status="homework.status"
          :deadline="homework.deadline"
          :index="i"
          :id="homework.id"
        />
      </div>

      <CommonPagination
        :pagesCount="pagesCount"
        :current-page="page"
        @changePage="changePage"
      />
    </div>

    <HomeworkSingle
      :homework="homeworkForSingle"
      v-else-if="homeworkForSingle !== undefined"
    />
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { HomeworkLink } from "./components/HomeworkLink";
import { HomeworkSingle } from "./components/HomeworkSingle";
import { CommonPagination } from "@/components/CommonPagination";
import { homeworks } from "./constants";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "HomeworksPage",

  components: {
    LayoutBase,
    HomeworkLink,
    HomeworkSingle,
    CommonPagination,
  },

  setup() {
    const route = useRoute();

    const page = ref<number>(1);

    const isOpenHomework = computed(() => route.query.id);

    const pagesCount = computed(() => Math.ceil(homeworks.length / 10));

    const homeworkForSingle = computed(() =>
      homeworks.find((item) => item.id === Number(route.query.id))
    );

    const homeworksAfterSlice = computed(() =>
      homeworks.slice((page.value - 1) * 10, (page.value - 1) * 10 + 10)
    );

    const changePage = (item: number) => {
      page.value = item;
    };

    return {
      isOpenHomework,
      homeworksAfterSlice,
      homeworks,
      pagesCount,
      page,
      homeworkForSingle,
      changePage,
    };
  },
});
</script>

<style lang="stylus" scoped>
.homeworksPage {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__secondTitle,
  &__btn {
    margin-top: 15px;
  }

  &__video {
    width: 100%;
    height: 39.2vw;
    border-radius: 53px;
  }

  &__textForVideo {
    padding: 20px;
    border-radius: 10px;
    background-color: $colorBgGrey;
  }

  +mediaTablet() {

    &__secondTitle,
    &__btn {
      margin-top: 1.5vw;
    }

    &__textForVideo {
      padding: 2vw;
    }
  }
}
</style>
