export const questionsList = [
  {
    question: "Виктор был лучшим учеником в нашей школе.",
    id: 1,
  },
  {
    question: "Я считаю, что книги Джека Лондона действительно хороши.",
    id: 2,
  },
  {
    question: "Лили плохо себя чувствовала после той аварии.",
    id: 3,
  },
  {
    question: "Я попросила ребенка не совать пальцы в нос!",
    id: 4,
  },
  {
    question: "Виктор был лучшим учеником в нашей школе.",
    id: 5,
  },
  {
    question: "Лили плохо себя чувствовала после той аварии.",
    id: 6,
  },
];

export const answersList = [
  {
    answer: "Все его оценки были блестящими.",
    id: 1,
  },
  {
    answer: "Я считаю эту игру скучной.",
    id: 2,
  },
  {
    answer: "Они просто потрясающие.",
    id: 3,
  },
  {
    answer: "Я считаю эту игру скучной.",
    id: 4,
  },
  {
    answer: "Как вам удалось добиться такого успеха?",
    id: 5,
  },
  {
    answer: "Я считаю эту игру скучной.",
    id: 6,
  },
];

export const lettersList = ["А", "Б", "В", "Г", "Д", "Е"];
