export const eventsList = [
  {
    time: "17:00",
    name: "Тема 5",
  },
  {
    time: "18:35",
    name: "ЗАЧЕТ",
  },
];
