import { RouteName } from "@/constants";

export const navList = [
  {
    id: 1,
    label: "Мой профиль",
    pathName: RouteName.PROFILE,
  },
  {
    id: 2,
    label: "Мое обучение",
    pathName: RouteName.EDUCATION,
  },
  {
    id: 3,
    label: "Мое портфолио",
    pathName: RouteName.PORTFOLIO,
  },
  {
    id: 4,
    label: "Учебные материалы",
    pathName: RouteName.EDUCATION_MATERIALS,
  },
  {
    id: 5,
    label: "Информация",
    pathName: RouteName.ADVERT,
  },
];

export const navDpoList = [
  {
    id: 1,
    label: "Мой профиль",
    pathName: RouteName.PROFILE_DPO,
  },
  {
    id: 2,
    label: "Мое обучение",
    pathName: RouteName.EDUCATION_DPO,
  },
  {
    id: 3,
    label: "Рекомендованные курсы",
    pathName: RouteName.COURSES_DPO,
  },
];
