import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonTabs = _resolveComponent("CommonTabs")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!

  return (_openBlock(), _createBlock(_component_LayoutBase, null, {
    default: _withCtx(() => [
      _createVNode(_component_CommonTabs, {
        "tabs-list": _ctx.tabsList,
        "current-tab": _ctx.currentTab?.value || 'info',
        onChangeTab: _ctx.changeTab
      }, null, 8, ["tabs-list", "current-tab", "onChangeTab"]),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentTab?.component), { class: "profilePage__content" }))
    ]),
    _: 1
  }))
}