<template>
  <div class="homeworkOmissions">
    <CommonEducationDpoCourse :title="title" :subTitle="subTitle">
      <h2 class="homeworkOmissions__title main-color-text">Домашнее задание</h2>

      <h2 class="homeworkOmissions__title">
        Задание: заполните пропуски в тексте
      </h2>

      <p class="homeworkOmissions__text">{{ question }}</p>

      <div class="homeworkOmissions__content">
        <p
          class="homeworkOmissions__task"
          v-for="(task, i) in taskOmission"
          :key="`task-${i}`"
        >
          {{ i + 1 }}.
          <span
            class="homeworkOmissions__taskItem"
            v-for="(item, index) in task"
            :key="`task-item-${index}`"
          >
            <input
              v-if="!isItemString(item)"
              :length="item.length"
              class="homeworkOmissions__input"
            />

            <span v-else>{{ item }}</span>
          </span>
        </p>
      </div>

      <CommonButtonPrimary class="homeworkOmissions__btn">
        Отправить ответ
      </CommonButtonPrimary>

      <CommonHomeworkStatus />
    </CommonEducationDpoCourse>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { title, subTitle, question, taskOmission } from "./constants";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";
import { CommonEducationDpoCourse } from "@/components/CommonEducationDpoCourse";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { CommonHomeworkStatus } from "@/components/CommonHomeworkStatus";

export default defineComponent({
  name: "HomeworkOmissions",

  components: {
    CommonEducationDpoCourse,
    CommonButtonPrimary,
    CommonHomeworkStatus,
  },

  setup() {
    const route = useRoute();

    const isShowTextForVideo = ref<boolean>(false);

    const hasEducationMain = computed(
      () => route?.name === RouteName.EDUCATION_DPO
    );

    const isItemString = (item: any) => typeof item === "string";

    const toggleTextForVideo = () => {
      isShowTextForVideo.value = !isShowTextForVideo.value;
    };

    return {
      hasEducationMain,
      title,
      subTitle,
      question,
      taskOmission,
      isShowTextForVideo,
      toggleTextForVideo,
      isItemString,
    };
  },
});
</script>

<style lang="stylus" scoped>
.homeworkOmissions {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__secondTitle {
    margin-top: 15px;
  }

  &__title,
  &__text {
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__task {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }

  &__input {
    padding: 9px 18px;
    background-color: transparent;
    border-radius: 30px;
    border: 2px solid $colorDpoGrey;
    color: $colorDpoGrey;
  }

  &__btn {
    width: fit-content;
    margin: 0 auto;
  }

  +mediaTablet() {
    gap: 4vw;

    &__secondTitle,
    &__btn {
      margin-top: 1.5vw;
    }

    &__textForVideo {
      padding: 2vw;
    }
  }
}
</style>
