<template>
  <header class="magistracy-header">
    <nav class="navbar">
      <router-link class="navbar-brand" :to="{ name: RouteName.HOME }">
        <svg class="magistracy__logo">
          <use xlink:href="#logo-white-icon--sprite" />
        </svg>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#magistracyMenu"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="magistracyMenu">
        <ul class="navbar-nav">
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY }"
            >
              Магистратура
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_ABOUT,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_ABOUT }"
            >
              О программе
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_PROGRAM,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_PROGRAM }"
            >
              Структура программы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY__ENTRANCE,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY__ENTRANCE }"
            >
              Приемная кампания
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_DOCUMENTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_DOCUMENTS }"
            >
              Документы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_CONTACTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_CONTACTS }"
            >
              Контакты
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <main>
    <section class="magistracy-intro">
      <div class="magistracy-intro__body">
        <h1>Магистратура</h1>
        <div class="intro__lead">
          Информационные системы и технологии в здравоохранении
        </div>
        <div class="intro__action">
          <a href="#magistracyRegister" class="btn" data-toggle="modal"
            >Подать заявку</a
          >
        </div>

        <div class="intro__invite">
          <div class="intro-title">
            Развивайтесь в цифровой модели здравоохранения Москвы
          </div>
          <div class="row">
            <div class="invite-col">
              <img src="@/assets/img/invite-point.svg" alt="" />
              <span>Постройте карьеру в&nbsp;IT в&nbsp;здравоохранении</span>
            </div>
            <div class="invite-col">
              <img src="@/assets/img/invite-point.svg" alt="" />
              <span>Выбирайте новый уровень в&nbsp;профессии</span>
            </div>
            <div class="invite-col">
              <img src="@/assets/img/invite-point.svg" alt="" />
              <span>Станьте лидерами в&nbsp;цифровизации здравоохранения</span>
            </div>
          </div>
        </div>

        <div class="intro__advantages">
          <div class="intro-title">Преимущества</div>
          <div class="advantages-row">
            <div class="advantages-col">
              <div class="advantage-card">
                Индивидуальная траектория обучения
              </div>
            </div>
            <div class="advantages-col">
              <div class="advantage-card">
                Практика и 3&nbsp;кейса в&nbsp;личное портфолио
              </div>
            </div>
            <div class="advantages-col">
              <div class="advantage-card">
                Работа с технологиями ИИ и&nbsp;нейронных сетей
              </div>
            </div>
            <div class="advantages-col">
              <div class="advantage-card">
                Собственный куратор курса и ИИ-наставник каждому
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-begin">
      <h2 class="magistracy-title">
        Начало приема документов – 8&nbsp;апреля 2024&nbsp;года
      </h2>
      <div class="magistracy-divider"></div>
      <div class="begin__row">
        <div class="begin__item">удобное расписание</div>
        <div class="begin__item">онлайн-занятия</div>
        <div class="begin__item">постоянный доступ к&nbsp;материалам</div>
        <div class="begin__item">
          практика с&nbsp;ИИ и&nbsp;большими данными
        </div>
        <div class="begin__item">крутое портфолио</div>
      </div>
      <a
        href="#magistracyRegister"
        class="btn btn-magistracy"
        data-toggle="modal"
        >Подать заявку</a
      >
    </section>
    <section class="magistracy-skills">
      <div class="skills__body">
        <h2 class="magistracy-title">Вы научитесь</h2>
        <div class="skills__row">
          <div class="skills__item">
            <div class="skill">
              Моделировать информационные системы в&nbsp;здравоохранении
            </div>
          </div>
          <div class="skills__item">
            <div class="skill">
              Применять искусственный интеллект в&nbsp;здравоохранении
            </div>
          </div>
          <div class="skills__item">
            <div class="skill">
              Разрабатывать сетевые приложения для сферы здравоохранения
            </div>
          </div>
          <div class="skills__item">
            <div class="skill">Управлять IT-проектами</div>
          </div>
          <div class="skills__item">
            <div class="skill">Обрабатывать большие данные</div>
          </div>
          <div class="skills__item">
            <div class="skill">Обучать нейронные сети</div>
          </div>
          <div class="skills__item">
            <div class="skill">
              Управлять информационной безопасностью в&nbsp;здравоохранении
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-prof">
      <h2 class="magistracy-title">
        Профессиональные возможности после окончания программы
      </h2>
      <div class="row">
        <div class="prof__item">
          <div class="prof-card">
            <svg class="magistracy__icon">
              <use xlink:href="#admin--sprite" />
            </svg>
            Администратор информационных систем
          </div>
        </div>
        <div class="prof__item">
          <div class="prof-card">
            <svg class="magistracy__icon">
              <use xlink:href="#analyst--sprite" />
            </svg>
            Аналитик медицинских данных
          </div>
        </div>
        <div class="prof__item">
          <div class="prof-card">
            <svg class="magistracy__icon">
              <use xlink:href="#inform--sprite" />
            </svg>
            Руководитель управления по&nbsp;информатизации
            в&nbsp;здравоохранении
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-program">
      <h2 class="magistracy-title">Структура образовательной программы</h2>
      <div class="program__body">
        <div class="accordion" id="accordionProgram">
          <div class="card semester">
            <div class="semester__head">
              <div
                :class="[
                  'semester-toggler',
                  { collapsed: hasCollapsed(1).value },
                ]"
                data-toggle="collapse"
                data-target="#semester1"
                :aria-expanded="hasCollapsed(1).value"
                @click="setCollapsedId(1)"
              >
                <div class="semester-name">1 семестр</div>
                <div class="semester-date">сентябрь-декабрь 2024 г.</div>
              </div>
            </div>
            <div
              :class="[
                'collapse',
                'semester__body',
                {
                  show: hasCollapsed(1).value,
                },
              ]"
              id="semester1"
              data-parent="#accordionProgram"
            >
              <div class="semester-program">
                <ul class="circle-list">
                  <li>Математическое и компьютерное моделирование</li>
                  <li>
                    Технологии и средства разработки программного обеспечения
                  </li>
                  <li>Анализ и синтез информационных систем</li>
                  <li>Модели и методы искусственного интеллекта</li>
                  <li>
                    Основы менеджмента и маркетинга в медицинской организации
                  </li>
                  <li>Компьютерное моделирование биологических систем</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="card semester">
            <div class="semester__head">
              <div
                :class="[
                  'semester-toggler',
                  { collapsed: hasCollapsed(2).value },
                ]"
                data-toggle="collapse"
                data-target="#semester2"
                :aria-expanded="hasCollapsed(2).value"
                @click="setCollapsedId(2)"
              >
                <div class="semester-name">2 семестр</div>
                <div class="semester-date">февраль-июнь 2025 г.</div>
              </div>
            </div>
            <div
              :class="[
                'collapse',
                'semester__body',
                {
                  show: hasCollapsed(2).value,
                },
              ]"
              id="semester2"
              data-parent="#accordionProgram"
            >
              <div class="semester-program">
                <ul class="circle-list">
                  <li>Математическое и компьютерное моделирование</li>
                  <li>
                    Статистические методы анализа медико-биологических данных с
                    элементами статистического моделирования
                  </li>
                  <li>Моделирование и оптимизация бизнес-процессов</li>
                  <li>Разработка сетевых приложений и облачные вычисления</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="card semester">
            <div class="semester__head">
              <div
                :class="[
                  'semester-toggler',
                  { collapsed: hasCollapsed(3).value },
                ]"
                data-toggle="collapse"
                data-target="#semester3"
                :aria-expanded="hasCollapsed(3).value"
                @click="setCollapsedId(3)"
              >
                <div class="semester-name">3 семестр</div>
                <div class="semester-date">сентябрь-декабрь 2025 г.</div>
              </div>
            </div>
            <div
              :class="[
                'collapse',
                'semester__body',
                {
                  show: hasCollapsed(3).value,
                },
              ]"
              id="semester3"
              data-parent="#accordionProgram"
            >
              <div class="semester-program">
                <ul class="circle-list">
                  <li>Системы поддержки принятия решений</li>
                  <li>
                    Иностранный язык в сфере профессиональных коммуникаций
                  </li>
                  <li>Машинное обучение: нейронные сети</li>
                  <li>Информатизация здравоохранения</li>
                  <li>
                    Коммерциализация результатов научных исследований и
                    разработок
                  </li>
                  <li>
                    Программные продукты как изделия медицинского назначения
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="card semester">
            <div class="semester__head">
              <div
                :class="[
                  'semester-toggler',
                  { collapsed: hasCollapsed(4).value },
                ]"
                data-toggle="collapse"
                data-target="#semester4"
                :aria-expanded="hasCollapsed(4).value"
                @click="setCollapsedId(4)"
              >
                <div class="semester-name">4 семестр</div>
                <div class="semester-date">февраль-июнь 2026 г.</div>
              </div>
            </div>
            <div
              :class="[
                'collapse',
                'semester__body',
                {
                  show: hasCollapsed(4).value,
                },
              ]"
              id="semester4"
              data-parent="#accordionProgram"
            >
              <div class="semester-program">
                <ul class="circle-list">
                  <li>Методология научных исследований</li>
                  <li>Управление проектами</li>
                  <li>Управление интеллектуальной собственностью</li>
                  <li>Защита информации в медицинской организации</li>
                  <li>Информационная безопасность медицинской организации</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-entrance">
      <div class="entrance__body">
        <h2 class="magistracy-title">Поступление в датах</h2>
        <div class="row">
          <div class="entrance__item">
            <div class="entrance-card">
              <div class="entrance-name">Подайте документы</div>
              <div class="entrance-date">
                с 08 апреля<br />
                по 23 августа 2024 г.
              </div>
            </div>
          </div>
          <div class="entrance__item">
            <div class="entrance-card">
              <div class="entrance-name">Сдайте вступительные испытания</div>
              <div class="entrance-date">
                с 26 августа<br />
                по 29 августа 2024 г.
              </div>
            </div>
          </div>
          <div class="entrance__item">
            <div class="entrance-card">
              <div class="entrance-name">Заключите договор</div>
              <div class="entrance-date">
                с 27 августа<br />
                по 29 августа 2024 г.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-steps">
      <div class="admission__action">
        <a
          href="#magistracyRegister"
          class="btn btn-magistracy"
          data-toggle="modal"
          >Подать заявку</a
        >
      </div>

      <div class="admission__row">
        <div class="admission__item">
          <div class="admission-name">старт обучения</div>
          <div class="admission-text">1 сентября 2024&nbsp;г.</div>
        </div>
        <div class="admission__item">
          <div class="admission-name">Формат обучения</div>
          <div class="admission-text">Онлайн</div>
        </div>
        <div class="admission__item">
          <div class="admission-name">срок обучения</div>
          <div class="admission-text">2 года</div>
        </div>
        <div class="admission__item">
          <div class="admission-name">количество мест</div>
          <div class="admission-text">10</div>
        </div>
        <div class="admission__item">
          <div class="admission-name">документ государственного образца*</div>
          <div class="admission-text">
            <small
              >*после прохождения процедуры государственной аккредитации</small
            >
          </div>
        </div>
      </div>
      <div class="magistracy-divider"></div>

      <div class="steps">
        <h2 class="steps-title">Поступите в 2 шага</h2>
        <div class="row">
          <div class="steps__item">
            <div class="step-num">01</div>
            <div class="step-text">
              <div class="step-name">Подайте документы</div>
              <ul class="circle-list">
                <li>Лично</li>
                <li>Через Личный кабинет на сайте</li>
                <li>Через операторов почтовой связи</li>
              </ul>
            </div>
          </div>

          <div class="steps__item">
            <div class="step-num">02</div>
            <div class="step-text">
              <div class="step-name">Сдайте вступительный тест</div>
              <ul class="circle-list">
                <li>Междисциплинарный тест</li>
                <li>
                  <a href="#" target="_blank"
                    >Программа вступительных испытаний</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>

        <a
          href="#magistracyRegister"
          class="btn btn-magistracy"
          data-toggle="modal"
          >Подать заявку</a
        >
      </div>
    </section>
    <div class="magistracy-board">
      <div class="container">
        <div class="board__body">
          <div class="board__row">
            <div class="board__item">
              <a href="#" class="board-card" target="_blank">
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs1"></use>
                </svg>
                <div class="card-text">Расписание вступительных испытаний</div>
              </a>
            </div>
            <div class="board__item">
              <a href="#" class="board-card" target="_blank">
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs2"></use>
                </svg>
                <div class="card-text">
                  Информация о количестве поданных заявлений
                </div>
              </a>
            </div>
            <div class="board__item">
              <a href="#" class="board-card" target="_blank">
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs3"></use>
                </svg>
                <div class="card-text">Списки лиц, подавших документы</div>
              </a>
            </div>
            <div class="board__item">
              <a href="#" class="board-card" target="_blank">
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs4"></use>
                </svg>
                <div class="card-text">Результаты вступительного испытания</div>
              </a>
            </div>
            <div class="board__item">
              <a href="#" class="board-card" target="_blank">
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs5"></use>
                </svg>
                <div class="card-text">Ранжированный список поступающих</div>
              </a>
            </div>
            <div class="board__item">
              <a href="#" class="board-card" target="_blank">
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs6"></use>
                </svg>
                <div class="card-text">Сведения о зачислении на обучение</div>
              </a>
            </div>
          </div>

          <a
            href="#magistracyRegister"
            class="btn btn-magistracy"
            data-toggle="modal"
            >Подать заявку</a
          >
        </div>
      </div>
    </div>
    <section class="magistracy-documents">
      <div class="container">
        <h2 class="magistracy-title">Документы</h2>
        <div class="accordion">
          <div class="card docs__item">
            <div class="docs__head">
              <div
                class="docs-toggler"
                data-toggle="collapse"
                data-target="#docsEntrance"
                aria-expanded="true"
              >
                Документы по приемной кампании
              </div>
            </div>
            <div class="collapse show docs__body" id="docsEntrance">
              <div class="card-body">
                <ul class="docs-list">
                  <li>
                    <a href="#" target="_blank">Правила приема</a>
                  </li>
                  <li>
                    <a href="#" target="_blank"
                      >Положение о приемной, экзаменационной и апелляционной
                      комиссиях</a
                    >
                  </li>
                  <li>
                    <a href="#" target="_blank"
                      >Программа вступительного испытания</a
                    >
                  </li>
                  <li>
                    <a href="#" target="_blank">Индивидуальные достижения</a>
                  </li>
                  <li>
                    <a href="#" target="_blank"
                      >Образец договора об оказании платных образовательных
                      услуг</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="card docs__item">
            <div class="docs__head">
              <div
                class="docs-toggler"
                data-toggle="collapse"
                data-target="#docsProgram"
                aria-expanded="true"
              >
                Документы по программе
              </div>
            </div>
            <div class="collapse show docs__body" id="docsProgram">
              <div class="card-body">
                <ul class="docs-list">
                  <li>
                    <a href="#" target="_blank">Учебный план</a>
                  </li>
                  <li>
                    <a href="#" target="_blank">Календарный учебный график</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-gift">
      <div class="gift__row">
        <div class="gift__col">
          <h2>Подарок каждому</h2>
          <p>
            Первый в столичном здравоохранении ИИ словарь профессиональных
            терминов
          </p>
        </div>
        <div class="gift__col">
          <img
            src="@/assets/img/gift.png"
            alt="ИИ словарь профессиональных терминов"
          />
        </div>
      </div>
    </section>
  </main>
  <footer class="footer-magistracy">
    <div class="container">
      <div class="contacts-title">Приемная комиссия</div>
      <div class="contacts">
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#clock--sprite" />
            </svg>
          </div>
          <div class="contact-name">Часы работы</div>
          <div class="contact-text">
            <p>Пн-Пт 09:00 – 17:30</p>
            <p>Сб-Вс Выходные</p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#email--sprite" />
            </svg>
          </div>
          <div class="contact-name">Почта</div>
          <div class="contact-text">
            <p>
              <a href="mailto:TolstyakovaOV1@zdrav.mos.ru"
                >TolstyakovaOV1@zdrav.mos.ru</a
              >
            </p>
            <p>
              <a href="mailto:PerunovaEA@zdrav.mos.ru"
                >PerunovaEA@zdrav.mos.ru</a
              >
            </p>
            <p>
              <a href="mailto:KuznetsovMY1@zdrav.mos.ru"
                >KuznetsovMY1@zdrav.mos.ru</a
              >
            </p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#phone--sprite" />
            </svg>
          </div>
          <div class="contact-name">Телефон</div>
          <div class="contact-text">
            <p>8 (499) 249-74-72<br />(доб. 574, 572, 571)</p>
            <p>8 (905) 734-17-14</p>
            <p>8 (977) 570-30-66</p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#map--sprite" />
            </svg>
          </div>
          <div class="contact-name">Адрес</div>
          <div class="contact-text">
            <p>
              121165, г. Москва,<br />
              ул. Киевская, д. 20
            </p>
            <p><a href="#" target="_blank">Карта</a></p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer__copyright">&copy; ГБУ «НИИОЗММ ДЗМ», 2024</div>
  </footer>
  <div class="modal fade" id="magistracyRegister" tabindex="-1">
    <div class="modal-dialog magistracy-register">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
          <h3 class="modal-title">Подать заявку</h3>

          <div class="register-intro js-register-types">
            <div class="buttons-group">
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#personRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#person"></use>
                  </svg>
                  <div class="card-body">Лично</div>
                </button>
              </div>
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#webformRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#webform"></use>
                  </svg>
                  <div class="card-body">
                    Через Личный кабинет на&nbsp;сайте
                  </div>
                </button>
              </div>
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#postalRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#postal"></use>
                  </svg>
                  <div class="card-body">Через операторов почтовой связи</div>
                </button>
              </div>
            </div>
          </div>

          <div class="register-tabs js-register-tabs">
            <div class="nav">
              <a class="nav-link" data-toggle="tab" href="#personRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#person"></use>
                </svg>
                Лично
              </a>
              <a class="nav-link" data-toggle="tab" href="#webformRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#webform"></use>
                </svg>
                На сайте
              </a>
              <a class="nav-link" data-toggle="tab" href="#postalRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#postal"></use>
                </svg>
                По почте
              </a>
            </div>
            <div class="tab-content">
              <div class="tab-pane" id="personRegister" role="tabpanel">
                <p>
                  <b>Вы можете подать документы лично по адресу:</b>
                  г.&nbsp;Москва, ул.&nbsp;Киевская, д.&nbsp;20, кабинет&nbsp;8.
                </p>
                <p><b>Часы работы:</b> Пн-Пт 09:00 – 17:30</p>
                <p>
                  <b
                    >Список документов для поступления на&nbsp;программу
                    магистратуры:</b
                  >
                </p>
                <ol>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/000/000ec0855d5336e3befb60fcf61f82a1.docx"
                      tarhet="_blank"
                      >Заявление на обучение</a
                    >
                  </li>
                  <li>
                    Копия паспорта (главная и регистрация по&nbsp;месту
                    жительства)
                  </li>
                  <li>
                    Копия диплома о высшем образовании с&nbsp;приложениями
                  </li>
                  <li>Копия СНИЛС</li>
                  <li>ИНН (копия или номер)</li>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/1b0/1b02e815b990b92a660bf53a78f855f2.docx"
                      target="_blank"
                      >Согласие на обработку персональных данных</a
                    >
                  </li>
                </ol>
                <div class="register-footer">
                  <button type="button" class="btn" data-dismiss="modal">
                    Понятно
                  </button>
                </div>
              </div>

              <div class="tab-pane" id="webformRegister" role="tabpanel">
                <p>
                  Для подачи документов в магистратуру Вы будете перенаправлены
                  на сайт Государственного бюджетного учреждения
                  Научно-исследовательский институт организации здравоохранения
                  и медицинского менеджмента Департамента здравоохранения г.
                  Москвы (ГБУ «НИИОЗММ ДЗМ»).
                </p>
                <ol class="divided">
                  <li>
                    Зарегистрируйтесь на сайте
                    <a href="https://niioz.ru/" target="_blank">www.niioz.ru</a>
                  </li>
                  <li>Подтвердите электронную почту по ссылке из письма</li>
                  <li>
                    Войдите в Личный кабинет в раздел
                    <a
                      href="https://niioz.ru/personal/magistracy/"
                      target="_blank"
                      >«Подать документы в магистратуру»</a
                    >
                  </li>
                  <li>Заполните форму, нажмите кнопку «Сохранить»</li>
                </ol>
                <p>
                  <a href="#" target="_blank">Скачать подробную инструкцию</a>
                </p>
                <div class="register-footer">
                  <a
                    href="https://niioz.ru/obrazovanie/magistratura/"
                    class="btn"
                    target="_blank"
                    >Продолжить</a
                  >
                </div>
              </div>

              <div class="tab-pane" id="postalRegister" role="tabpanel">
                <p>
                  Вы можете направить документы через оператора почтовой связи,
                  например, Почтой России.
                </p>
                <p>
                  <b>Адрес:</b> ул. Большая Татарская, д.&nbsp;30, помещение
                  1/2, Москва, 115184.
                </p>
                <p>
                  <b
                    >Список документов для поступления на&nbsp;программу
                    магистратуры:</b
                  >
                </p>
                <ol>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/000/000ec0855d5336e3befb60fcf61f82a1.docx"
                      target="_blank"
                      >Заявление на обучение</a
                    >
                  </li>
                  <li>
                    Копия паспорта (главная и регистрация по&nbsp;месту
                    жительства)
                  </li>
                  <li>
                    Копия диплома о высшем образовании с&nbsp;приложениями
                  </li>
                  <li>Копия СНИЛС</li>
                  <li>ИНН (копия или номер)</li>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/1b0/1b02e815b990b92a660bf53a78f855f2.docx"
                      target="_blank"
                      >Согласие на обработку персональных данных</a
                    >
                  </li>
                </ol>
                <div class="register-footer">
                  <button type="button" class="btn" data-dismiss="modal">
                    Понятно
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  computed,
} from "vue";
import { RouteName } from "@/constants";
import { useRoute } from "vue-router";

import "@/assets/img/icons/admin.svg?sprite";
import "@/assets/img/icons/analyst.svg?sprite";
import "@/assets/img/icons/inform.svg?sprite";
import "@/assets/img/icons/clock.svg?sprite";
import "@/assets/img/icons/email.svg?sprite";
import "@/assets/img/icons/phone.svg?sprite";
import "@/assets/img/icons/map.svg?sprite";

export default defineComponent({
  name: "HomeMagistracyPage",

  components: {},

  setup() {
    const route = useRoute();

    const pickedValue = ref<number>(1);

    const hasCollapsed = (id: number) =>
      computed(() => pickedValue.value === id);

    const setMainCss = () => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.id = "main-css";

      document.head.appendChild(link);

      link.href = "/assets/css/main.css";
    };

    const removeMainCss = () => {
      document.getElementById("main-css")?.remove();
    };

    const setCollapsedId = (id: number) => (pickedValue.value = id);

    onBeforeMount(() => setMainCss());

    onBeforeUnmount(() => removeMainCss());

    return {
      RouteName,
      route,
      pickedValue,
      hasCollapsed,
      setCollapsedId,
    };
  },
});
</script>

<style lang="stylus" scoped>
.magistracy-intro::before {
  background-image: url("@/assets/img/magistracy-intro.png");
}
.magistracy-skills:before {
  background-image: url("@/assets/img/magistracy-bg.png");
}
.magistracy__logo {
  width: 206px;
  height: 40px;
  min-width: 206px;
}
.navbar-collapse {
  background-color: transparent;
}
.docs-list li::before {
  content: "";
  width: 11px;
  height: 11px;
  border-radius: 100px;
  background-color: #E24E25;
  position: relative;
  top: 2px;
  left: -2px;
}
.magistracy-documents::before {
  background-image: url("@/assets/img/robot.png");
}
.program__body::before {
  background-image: url("@/assets/img/hand.png")!important;
}
</style>

<style lang="stylus">
.magistracy-page .container {
  padding-top: 82px;
}
</style>
