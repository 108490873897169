<template>
  <VueFinalModal
    :class="{ dpo: hasDpo }"
    v-model="test"
    name="ModalSuccess"
    modal-id="ModalSuccess"
    :zIndexFn="() => 1000"
  >
    <div class="modalSuccess">
      <svg class="modalSuccess__star">
        <use xlink:href="#smile-success-icon--sprite" />
      </svg>

      <b>Правильно</b>
    </div>
  </VueFinalModal>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { VueFinalModal } from "vue-final-modal";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";

import "@/assets/img/icons/smile-success-icon.svg?sprite";

export default defineComponent({
  name: "ModalSuccess",

  components: {
    VueFinalModal,
  },

  setup() {
    const route = useRoute();

    const test = ref<boolean>(false);

    const hasDpo = computed(() => route.matched[0]?.name === RouteName.DPO);

    return {
      test,
      hasDpo,
    };
  },
});
</script>

<style lang="stylus">
.vfm {
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  &__content {
    position: relative;
    top: 0;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    opacity: .8;
    z-index: -1;
    background: $colorDarkGrey;
  }
}
</style>

<style lang="stylus" scoped>
.modalSuccess {
  position: relative;
  top: 60px;
  margin-bottom: 30%;
  width: 100%;
  max-width: 95vw;
  padding: 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-shadow: 0 4px 12px 0 rgba(25, 135, 84, 0.25);
  overflow: hidden;
  background-color: $colorFiller;
  text-align: center;

  &__icon {
    width: 94px;
    height: 71px;
  }

  &__contentTitle {
    text-align: center;

    &_green {
      color: $colorPrimary;
    }
  }

  &__titleBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    getFontH4();
    color: $colorPrimary;
    text-wrap: wrap;
  }

  &__contentBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 13px;
  }

  &__content {
    text-align: center;
  }

  &__footerBox:empty {
    display: none;
  }

  +mediaTablet() {
    max-width: 60vw;
    padding: 64px;
    gap: 3vw;
  }
}

.dpo .modalTestRules {
  &__contentTitle {
    text-align: center;

    &_green {
      color: $colorPrimaryDpo;
    }
  }

  &__titleBox {
    color: $colorPrimaryDpo;
  }
}
</style>
