<template>
  <div class="commonBannerYourPoints">
    <h6 class="commonBannerYourPoints__title">Ваши баллы</h6>

    <div class="commonBannerYourPoints__pointsContainer">
      <h1 class="commonBannerYourPoints__points">
        {{ pointsCount }}
      </h1>

      <svg class="commonBannerYourPoints__star">
        <use xlink:href="#star-icon--sprite" />
      </svg>
    </div>

    <p class="commonBannerYourPoints__text">
      Участвуйте в марафоне,<br />
      зарабатывайте баллы<br />и получите шанс<br />выиграть призы.
    </p>

    <hr class="commonBannerYourPoints__hr" />

    <p class="commonBannerYourPoints__text">
      Подсказка: чтобы получить<br />еще больше баллов, заполните<br />
      в профиле поля "Отчество"<br />и "Телефон".
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { CommonBannerYourPointsProps } from "./types";

import "@/assets/img/icons/star-icon.svg?sprite";

export default defineComponent({
  name: "CommonBannerYourPoints",

  props: {
    pointsCount: {
      type: String as PropType<CommonBannerYourPointsProps["pointsCount"]>,
      required: true,
    },
  },
});
</script>

<style lang="stylus" scoped>
.commonBannerYourPoints {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1vw;
  padding: 20px;
  background-color: $colorGreenMedium;
  border-radius: 30px;
  color: $colorFiller;

  &__title {
    font-weight: bold;
  }

  &__pointsContainer {
    display: flex;
    align-items: center;
    gap: 1vw;
  }

  &__star {
    width: 50px;
    height: 50px;
  }

  &__hr {
    width: 50%;
    height: 1px;
    margin: 0;
  }
}
</style>
