<template>
  <button
    class="primaryButton"
    @click="click"
    @mousedown="mousedown"
    @mouseup="mouseup"
    v-bind="$attrs"
  >
    <span class="primaryButton__content">
      <slot />
    </span>
  </button>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonButtonPrimary",
  emits: ["click"],
  props: {},
  setup(_, { emit }) {
    const click = (e) => {
      emit("click", e);
    };

    const mousedown = (event) => {
      event.currentTarget.classList.add("primaryButton__selected");
    };

    const mouseup = (event) => {
      event.currentTarget.classList.remove("primaryButton__selected");
    };

    return {
      click,
      mousedown,
      mouseup,
    };
  },
});
</script>

<style lang="stylus" scoped>
.primaryButton {
  padding: 12px 16px;
  user-select: none;

  +mediaTablet() {
    padding: .9vw 4.2vw;
  }

  border-radius: 10px;
  color: $colorFiller;
  background-color: $colorPrimary;
  border: 0;
  cursor: pointer;
  transition: all 0.1s;
  getFontText();

  &__selected {
    background-color: $colorThird!important;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $colorSecond;
  }

  &:disabled {
    cursor: default;
    background-color: $colorLightGrey;
    color: $colorDarkGrey;
    opacity: 1;
  }

  &__content {
    opacity: 1;
  }
}

.dpo .primaryButton {
  background-color: $colorPrimaryDpo;

  &__selected {
    background-color: $colorThirdDpo!important;
  }

  &:hover {
    background-color: $colorSecondDpo;
  }
}
</style>
