export const subLabelsList = [
  {
    title: "Памятка «Классификация ...»",
    doc: "#",
  },
  {
    title: "Чек-лист",
    doc: "#",
  },
];

export const dropdownsList = [
  "Презентации к лекции 1",
  "Дополнительные материалы к лекции 1",
  "Литература к лекции 1",
];
