export const notificationsList = [
  {
    isRead: false,
    title: "Скидка на курсы для ваших друзей повышена еще на 5%",
    text: "Весь апрель по вашей ссылке из раздела “Приведи друга” скидки до 65%",
    date: "23 апреля",
  },
  {
    isRead: false,
    title: "Скидка на курсы для ваших друзей повышена еще на 5%",
    text: "Весь апрель по вашей ссылке из раздела “Приведи друга” скидки до 65%",
    date: "23 апреля",
  },
  {
    isRead: false,
    title: "Скидка на курсы для ваших друзей повышена еще на 5%",
    text: "Весь апрель по вашей ссылке из раздела “Приведи друга” скидки до 65%",
    date: "23 апреля",
  },
  {
    isRead: true,
    title: "Скидка на курсы для ваших друзей повышена еще на 5%",
    text: "Весь апрель по вашей ссылке из раздела “Приведи друга” скидки до 65%",
    date: "23 апреля",
  },
];
