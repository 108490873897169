<template>
  <LayoutBase v-if="true">
    <div class="educationPage">
      <div class="educationPage__titleBox">
        <h2 class="educationPage__title">Специальность</h2>

        <h6 class="educationPage__secondTitle">
          Информационные системы и технологии<br />в здравоохранении
        </h6>
      </div>

      <div class="educationPage__videoBox">
        <iframe
          class="educationPage__video"
          src="https://rutube.ru/play/embed/9fa66fc2e001793df1af22f0f26947ba/"
          frameBorder="0"
          allow="clipboard-write; autoplay"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        />

        <CommonButtonSecondary
          class="educationPage__btn"
          @click="toggleTextForVideo"
        >
          Сгенерировать текст к видео
        </CommonButtonSecondary>

        <p class="educationPage__textForVideo" v-if="isShowTextForVideo">
          Роман "Обломов" - классический роман мировой литературы, вершина
          творчества Ивана Гончарова. В свое время он стал важнейшим
          общественнымсобытием, вызвал волну споров и дискуссий и стал
          своеобразным призывом к борьбе с застоем и апатией. Характер Обломова
          зачастую воспринимали как "знамение времени", а его имя стало
          нарицательным, породив понятие "обломовщина". Роман "Обломов" -
          классический роман мировой литературы, вершина творчества Ивана
          Гончарова. В свое время он стал важнейшим общественным событием,
          вызвал волну споров и дискуссий и стал своеобразным призывом к борьбе
          с застоем и апатией. Характер Обломова зачастую воспринимали как
          "знамение времени", а его имя стало нарицательным, породив понятие
          "обломовщина".
        </p>
      </div>

      <CommonCalendar
        :eventsList="eventsList"
        :is-show-buttons="false"
        :lesson-id="33"
      />

      <CommonButtonSecondary
        class="educationPage__btn educationPage__btn_center"
        @click="goToEducation"
      >
        Перейти к обучению
      </CommonButtonSecondary>

      <h3 class="educationPage__trajectoryTitle">
        Постройте свою персональную траекторию
      </h3>

      <div class="educationPage__videoBox">
        <iframe
          class="educationPage__video"
          src="https://rutube.ru/play/embed/9fa66fc2e001793df1af22f0f26947ba/"
          frameBorder="0"
          allow="clipboard-write; autoplay"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        />

        <CommonButtonSecondary
          class="educationPage__btn"
          @click="toggleTextForVideo"
        >
          Сгенерировать текст к видео
        </CommonButtonSecondary>

        <p class="educationPage__textForVideo" v-if="isShowTextForVideo">
          Роман "Обломов" - классический роман мировой литературы, вершина
          творчества Ивана Гончарова. В свое время он стал важнейшим
          общественнымсобытием, вызвал волну споров и дискуссий и стал
          своеобразным призывом к борьбе с застоем и апатией. Характер Обломова
          зачастую воспринимали как "знамение времени", а его имя стало
          нарицательным, породив понятие "обломовщина". Роман "Обломов" -
          классический роман мировой литературы, вершина творчества Ивана
          Гончарова. В свое время он стал важнейшим общественным событием,
          вызвал волну споров и дискуссий и стал своеобразным призывом к борьбе
          с застоем и апатией. Характер Обломова зачастую воспринимали как
          "знамение времени", а его имя стало нарицательным, породив понятие
          "обломовщина".
        </p>
      </div>

      <div class="educationPage__buttonsBox">
        <CommonButtonSecondary
          class="educationPage__btn"
          @click="openTrajectoryModal"
        >
          Построить траекторию
        </CommonButtonSecondary>

        <CommonButtonSecondary
          class="educationPage__btn"
          @click="goToEducation"
        >
          Перейти к обучению
        </CommonButtonSecondary>
      </div>
    </div>
  </LayoutBase>

  <router-view v-else />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { CommonButtonSecondary } from "@/components/CommonButtonSecondary";
import { CommonCalendar } from "@/components/CommonCalendar";
import { eventsList } from "./constants";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";
import { useVfm } from "vue-final-modal";

export default defineComponent({
  name: "LessonDpoPage",

  components: {
    LayoutBase,
    CommonButtonSecondary,
    CommonCalendar,
  },

  setup() {
    const vfm = useVfm();
    const route = useRoute();
    const router = useRouter();

    const isShowTextForVideo = ref<boolean>(false);

    const hasEducationMain = computed(
      () => route?.name === RouteName.EDUCATION
    );

    const toggleTextForVideo = () => {
      isShowTextForVideo.value = !isShowTextForVideo.value;
    };

    const openTrajectoryModal = () => vfm.open("ModalTrajectory");

    const goToEducation = () => {
      // router.push({ name: RouteName.HOMEWORK_DPO, query: { id: 123 } });
      router.push({ name: RouteName.TEST_DPO, query: { id: 123 } });
    };

    return {
      hasEducationMain,
      eventsList,
      isShowTextForVideo,
      toggleTextForVideo,
      openTrajectoryModal,
      goToEducation,
    };
  },
});
</script>

<style lang="stylus" scoped>
.educationPage {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__secondTitle,
  &__btn {
    margin-top: 15px;

    &_center {
      margin: auto;
    }
  }

  &__trajectoryTitle {
    padding: 35px 0;
    text-align: center;
    font-weight: normal;
    background-color: $colorPrimaryDpo;
    color: $colorFiller;
  }

  &__buttonsBox {
    display: flex;
    justify-content: space-between;
    gap: 35px;

    & button {
      flex-grow: 1;
    }
  }

  &__video {
    width: 100%;
    height: 39.2vw;
    border-radius: 53px;
  }

  &__textForVideo {
    padding: 20px;
    border-radius: 10px;
    background-color: $colorBgGrey;
  }

  +mediaTablet() {
    gap: 4vw;

    &__secondTitle,
    &__btn {
      margin-top: 1.5vw;
    }

    &__textForVideo {
      padding: 2vw;
    }
  }
}
</style>
