<template>
  <div>
    <CommonEducationDpoCourse :title="title" :subTitle="subTitle">
      <h2 class="homeworkCorrelate__title main-color-text">Домашнее задание</h2>

      <h2 class="homeworkCorrelate__title">
        Задание: заполните пропуски в тексте
      </h2>

      <p class="homeworkCorrelate__text">{{ pageQuestion }}</p>

      <div class="homeworkCorrelate__content">
        <div class="homeworkCorrelate__questionsList">
          <div
            class="homeworkCorrelate__question"
            v-for="(question, i) in questionsList"
            :key="`question-${i}`"
          >
            {{ i + 1 }}. {{ question.question }}
          </div>
        </div>

        <div class="homeworkCorrelate__answersList">
          <div
            class="homeworkCorrelate__answer"
            v-for="(answer, i) in randomAnswerList"
            :key="`answer-${i}`"
          >
            {{ lettersList[i] }}. {{ answer.answer }}
          </div>
        </div>
      </div>

      <hr class="homeworkCorrelate__hr" />

      <div class="homeworkCorrelate__inputsContainer">
        <div
          class="homeworkCorrelate__inputBox"
          v-for="item in questionsList.length"
          :key="`input-${item}`"
        >
          {{ item }}
          <input class="homeworkCorrelate__input" maxLength="1" />
        </div>
      </div>

      <CommonButtonPrimary
        class="homeworkCorrelate__btn"
        @click="openResultModal"
      >
        Отправить ответ
      </CommonButtonPrimary>

      <CommonHomeworkStatus />
    </CommonEducationDpoCourse>
  </div>
</template>

<script type="ts">
import { defineComponent, ref } from "vue";
import { CommonEducationDpoCourse } from "@/components/CommonEducationDpoCourse";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { CommonHomeworkStatus } from "@/components/CommonHomeworkStatus";
import { questionsList, answersList, lettersList } from "./constants";
import { useVfm } from "vue-final-modal";

export default defineComponent({
  name: "HomeworkCorrelate",

  components: {
    CommonEducationDpoCourse,
    CommonButtonPrimary,
    CommonHomeworkStatus,
  },

  setup() {
    const vfm = useVfm();

    const isFail = ref(true);

    const compareRandom = (a, b) => {
      return Math.random() - 0.5;
    };

    const randomAnswerList = answersList.sort(compareRandom);

    const openResultModal = () => isFail.value ? vfm.open("ModalFail") : vfm.open("ModalSuccess");

    return {
      lettersList,
      questionsList,
      randomAnswerList,
      openResultModal,
    };
  },
});
</script>

<style lang="stylus" scoped>
.homeworkCorrelate {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__secondTitle {
    margin-top: 15px;
  }

  &__title,
  &__text {
    text-align: center;
  }

  &__content {
    display: flex;
    gap: 15px;
  }

  &__questionsList,
  &__answersList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-grow: 1;
    width: 50%;
  }

  &__question,
  &__answer {
    padding: 20px;
    background-color: $colorFiller;
    border-radius: 10px;
  }

  &__hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: $colorDarkGrey;
  }

  &__inputsContainer {
    display: flex;
    gap: 15px;
  }

  &__inputBox {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background-color: $colorFiller;
    border-radius: 10px;
  }

  &__input {
    width: 56px;
    padding: 8px;
    background-color: $colorLightGrey;
    border-radius: 10px;
    border: none;
  }

  &__btn {
    width: fit-content;
    margin: 0 auto;
  }

  +mediaTablet() {
    gap: 4vw;

    &__secondTitle,
    &__btn {
      margin-top: 1.5vw;
    }

    &__textForVideo {
      padding: 2vw;
    }
  }
}
</style>
