<template>
  <div class="commonDocsDownload">
    <div class="commonDocsDownload__labelBox">
      <div class="commonDocsDownload__label">
        {{ label }}
      </div>

      <div class="commonDocsDownload__subLabel">
        {{ subLabel }}
      </div>
    </div>

    <div class="commonDocsDownload__btnBox">
      <CommonButtonPrimary v-if="1">Скачать</CommonButtonPrimary>
      <CommonButtonPrimary v-else disabled>
        Ожидается загрузка
      </CommonButtonPrimary>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CommonDocsDownloadProps } from "./types";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";

export default defineComponent({
  name: "CommonDocsDownload",

  props: {
    label: {
      type: String as PropType<CommonDocsDownloadProps["label"]>,
    },
    subLabel: {
      type: String as PropType<CommonDocsDownloadProps["subLabel"]>,
    },
  },

  components: {
    CommonButtonPrimary,
  },
});
</script>

<style lang="stylus" scoped>
.commonDocsDownload {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__labelBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__label {
    font-weight: bold;
  }

  &__subLabel {
    getFontSmall();
    color: $colorDarkGrey;
  }
}
</style>
