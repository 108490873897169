import { RouteName } from "@/constants";

export const navList = [
  {
    id: 1,
    label: "Объявления",
    pathName: RouteName.ADVERT,
  },
  {
    id: 2,
    label: "Нормативные документы",
    pathName: RouteName.DOCS,
  },
];

export const docs = [
  {
    label: "Учебный план программы",
    link: "#",
  },
  {
    label: "Рабочие программы дисциплин (модулей)",
    link: "#",
  },
  {
    label: "Программы практик",
    link: "#",
  },
  {
    label: "Программа итоговой аттестации",
    link: "#",
  },
];
