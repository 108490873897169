import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5de6fa63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonEducationDpoCourse" }
const _hoisted_2 = { class: "commonEducationDpoCourse__titleBox" }
const _hoisted_3 = { class: "commonEducationDpoCourse__title" }
const _hoisted_4 = { class: "commonEducationDpoCourse__secondTitle" }
const _hoisted_5 = { class: "commonEducationDpoCourse__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonEducationCalendar = _resolveComponent("CommonEducationCalendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.subTitle), 1)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_CommonEducationCalendar),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}