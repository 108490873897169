<template>
  <div class="commonEducationCalendar__statusBox">
    <div class="commonEducationCalendar__status">
      <svg
        class="commonEducationCalendar__arrow icon-red commonEducationCalendar__arrow_left"
      >
        <use xlink:href="#arrow-dropdown-right-icon--sprite" />
      </svg>

      <div class="commonEducationCalendar__statusWrap">
        <div
          class="commonEducationCalendar__statusItem"
          v-for="(status, i) in calendarList"
          :key="`status-${i}`"
        >
          <div class="commonEducationCalendar__statusProgress">
            <div class="commonEducationCalendar__statusProgressLine" />

            <div class="commonEducationCalendar__statusTag">
              <p
                :class="[
                  'commonEducationCalendar__statusName',
                  {
                    commonEducationCalendar__statusName_active:
                      activeCalendar === i,
                  },
                ]"
              >
                {{ status }}
              </p>

              <div
                :class="[
                  'commonEducationCalendar__statusProgressCircle',
                  {
                    commonEducationCalendar__statusProgressCircle_active:
                      activeCalendar === i,
                  },
                ]"
              />
            </div>
          </div>
        </div>
      </div>

      <svg class="commonEducationCalendar__arrow icon-red">
        <use xlink:href="#arrow-dropdown-right-icon--sprite" />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref } from "vue";
import type { CommonEducationStatusProps } from "./types";
import { calendarList } from "./constants";

export default defineComponent({
  name: "CommonEducationCalendar",

  props: {
    label: {
      type: String as PropType<CommonEducationStatusProps["label"]>,
    },
    subLabel: {
      type: String as PropType<CommonEducationStatusProps["subLabel"]>,
    },
    status: {
      type: String as PropType<CommonEducationStatusProps["status"]>,
    },
    date: {
      type: String as PropType<CommonEducationStatusProps["date"]>,
    },
  },

  components: {},

  setup() {
    const activeCalendar = ref(1);

    return {
      activeCalendar,
      calendarList,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonEducationCalendar {
  &__status {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    padding-bottom: 50px;
  }

  &__statusWrap {
    display: flex;
    flex-grow: 1;
  }

  &__statusItem {
    position: relative;
    flex-grow: 1;
  }

  &__statusName {
    text-align: center;
    font-weight: bold;

    &_active {
      color: $colorPrimary;
    }
  }

  &__statusProgress {
    flex-grow: 1;
  }

  &__statusProgressLine {
    width: 100%;
    height: 10px;
    border: 2px solid $colorPrimary;
    background-color: $colorPrimary;
  }

  &__statusTag {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 34px;
    bottom: -12.5px;
    right: 0;
    z-index: 1;
  }

  &__statusProgressCircle {
    width: 34px;
    height: 34px;
    border-radius: 100px;
    border: 2px solid $colorPrimary;
    background-color: $colorFiller;
    z-index: 1;

    &_left {
      left: -17px;
    }

    &_active {
      background-color: $colorPrimary;
    }
  }

  &__arrow {
    width: 33px;
    height: 22px;
    position: relative;
    top: 5.5px;

    &_left {
      transform: rotate(180deg);
    }
  }
}
.dpo .commonEducationCalendar {
  &__statusName {
    &_active {
      color: $colorPrimaryDpo;
    }
  }

  &__statusProgressLine {
    border: 2px solid $colorPrimaryDpo;
    background-color: $colorPrimaryDpo;
  }

  &__statusProgressCircle {
    border: 2px solid $colorPrimaryDpo;
    background-color: $colorFiller;

    &_active {
      border: 2px solid $colorFiller;
      background-color: $colorPrimaryDpo;
    }
  }
}
</style>
