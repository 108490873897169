import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-fb611c84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonTabs" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsList, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        key: tab.label,
        class: _normalizeClass([
        'commonTabs__item',
        { commonTabs__item_active: tab.value === _ctx.currentTab },
      ]),
        onClick: ($event: any) => (_ctx.$emit('changeTab', tab.value))
      }, _toDisplayString(tab.label), 11, _hoisted_2))
    }), 128))
  ]))
}