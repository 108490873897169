<template>
  <LayoutBase>
    <div class="docsPage">
      <CommonNavigation :nav-list="navList" />

      <div class="docsPage__linksList">
        <a
          :href="doc.link"
          class="docsPage__link"
          download
          v-for="doc in docs"
          :key="doc.label"
        >
          {{ doc.label }}
        </a>
      </div>
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { navList, docs } from "./constants";
import { CommonNavigation } from "@/components/CommonNavigation";

export default defineComponent({
  name: "DocsPage",

  components: {
    LayoutBase,
    CommonNavigation,
  },

  setup() {
    return {
      navList,
      docs,
    };
  },
});
</script>

<style lang="stylus" scoped>
.docsPage {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__linksList {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__link {
    text-decoration: none;

    &:hover {
      font-weight: bold;
    }
  }

  +mediaTablet() {
    gap: 3vw;
  }
}
</style>
