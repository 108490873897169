<template>
  <main class="profileSupportDpo">
    <CommonBannerIiMessage :title="bannerTitle" :text="bannerText" />

    <div class="profileSupportDpo__supportEmailBox-wrap">
      <div class="profileSupportDpo__supportEmailBox">
        <svg :class="['profileSupportDpo__icon', { 'icon-red': hasDpo }]">
          <use xlink:href="#envelope-icon--sprite" />
        </svg>

        <a
          class="profileSupportDpo__supportEmail"
          href="mailto:support_mmo@zdrav.mos.ru"
        >
          support_mmo@zdrav.mos.ru
        </a>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { CommonBannerIiMessage } from "@/components/CommonBannerIiMessage";
import { bannerTitle, bannerText } from "./constants";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";

import "@/assets/img/icons/envelope-icon.svg?sprite";

export default defineComponent({
  name: "ProfileSupportDpo",

  components: {
    CommonBannerIiMessage,
  },

  setup() {
    const route = useRoute();

    const hasDpo = computed(() => route.matched[0]?.name === RouteName.DPO);

    return {
      bannerTitle,
      bannerText,
      hasDpo,
    };
  },
});
</script>

<style lang="stylus">
.profileSupportDpo {
  &__supportEmailBox-wrap {
    display: flex;
    justify-content: flex-end;
    padding: 25px;
  }

  &__supportEmailBox {
    display: flex;
    align-items: center;
    width: 70%;
    gap: 8px;
  }

  &__supportEmail {
    font-weight: bold;
    color: $colorPrimary;
    text-decoration: none;
  }

  &__icon {
    width: 32px;
    height: 24px;
  }
}

.dpo .profileSupportDpo {
  &__supportEmail {
    color: $colorPrimaryDpo;
  }
}
</style>
