<template>
  <LayoutBase>
    <div class="lessonPage">
      <h6 class="lessonPage__secondTitle">16.04.2024</h6>

      <div class="lessonPage__content">
        <h5 class="lessonPage__contentTitle lessonPage__contentTitle_green">
          test
        </h5>

        <div class="lessonPage__speakerBox">
          <img
            class="lessonPage__img"
            src="@/assets/img/speaker-test-img.jpg"
          />

          <div class="lessonPage__speakerInfo">
            <p class="lessonPage__speakerName">
              Пожарская<br />Наталья Владимировна,
            </p>

            <p>
              преподаватель профессиональных<br />дисциплин и модулей, д-р
              мед.наук, профессор
            </p>
          </div>
        </div>

        <CommonButtonPrimary class="lessonPage__button">
          Чат с преподавателем
        </CommonButtonPrimary>

        <div class="lessonPage__videoBox">
          <div class="lessonPage__videoBg"></div>
          <iframe
            class="lessonPage__video"
            src="https://rutube.ru/play/embed/9fa66fc2e001793df1af22f0f26947ba/"
            frameBorder="0"
            allow="clipboard-write; autoplay"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          />
        </div>

        <CommonButtonSecondary v-if="0" class="lessonPage__button">
          Прослушать лекцию 1
        </CommonButtonSecondary>

        <CommonButtonPrimary v-else-if="false" class="lessonPage__button">
          Домашнее задание №1
        </CommonButtonPrimary>

        <CommonButtonPrimary
          v-else
          class="lessonPage__button"
          @click="goToTest"
        >
          Перейти к итоговому тестированию
        </CommonButtonPrimary>

        <!-- <figure>
          <figcaption>Listen to the T-Rex:</figcaption>
          <audio controls src="/media/cc0-audio/t-rex-roar.mp3"></audio>
          <a href="/media/cc0-audio/t-rex-roar.mp3"> Download audio </a>
        </figure> -->

        <div>
          <CommonButtonSecondary
            class="lessonPage__btn"
            @click="toggleTextForVideo"
          >
            Сгенерировать текст к видео
          </CommonButtonSecondary>

          <p class="lessonPage__textForVideo" v-if="isShowTextForVideo">
            Роман "Обломов" - классический роман мировой литературы, вершина
            творчества Ивана Гончарова. В свое время он стал важнейшим
            общественнымсобытием, вызвал волну споров и дискуссий и стал
            своеобразным призывом к борьбе с застоем и апатией. Характер
            Обломова зачастую воспринимали как "знамение времени", а его имя
            стало нарицательным, породив понятие "обломовщина". Роман "Обломов"
            - классический роман мировой литературы, вершина творчества Ивана
            Гончарова. В свое время он стал важнейшим общественным событием,
            вызвал волну споров и дискуссий и стал своеобразным призывом к
            борьбе с застоем и апатией. Характер Обломова зачастую воспринимали
            как "знамение времени", а его имя стало нарицательным, породив
            понятие "обломовщина".
          </p>
        </div>

        <hr class="lessonPage__hr" />

        <div class="lessonPage__dropdownsBox">
          <CommonDropdown
            :label="dropdown"
            isElementDropdown
            v-for="dropdown in dropdownsList"
            :key="dropdown"
          >
            <div
              class="lessonPage__linkBox"
              v-for="(subLabel, i) in subLabelsList"
              :key="subLabel.title"
            >
              <!-- <CommonDropdownSecond
                :label="subLabel.title"
                v-if="isElementDropdown"
                class="lessonPage__link"
              >
                123123
              </CommonDropdownSecond> -->

              <a :href="subLabel.doc" download class="lessonPage__link">
                {{ subLabel.title }}
              </a>

              <hr
                class="lessonPage__linkHr"
                v-if="
                  i !== subLabelsList.length - 1 && subLabelsList.length > 1
                "
              />
            </div>
          </CommonDropdown>
        </div>
      </div>
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { CommonButtonSecondary } from "@/components/CommonButtonSecondary";
import { CommonDropdown } from "@/components/CommonDropdown";
import { subLabelsList, dropdownsList } from "./constants";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/RouteName";

export default defineComponent({
  name: "LessonPage",

  components: {
    LayoutBase,
    CommonButtonPrimary,
    CommonButtonSecondary,
    CommonDropdown,
  },

  setup() {
    const router = useRouter();

    const isShowTextForVideo = ref<boolean>(false);

    const toggleTextForVideo = () => {
      isShowTextForVideo.value = !isShowTextForVideo.value;
    };

    const goToTest = () => router.push({ name: RouteName.TEST });

    return {
      dropdownsList,
      subLabelsList,
      isShowTextForVideo,
      toggleTextForVideo,
      goToTest,
    };
  },
});
</script>

<style lang="stylus" scoped>
.lessonPage {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 35px;
    gap: 40px;
    background-color: $colorLightGrey;
  }

  &__contentTitle {
    &_green {
      text-align: center;
      color: $colorPrimary;
    }
  }

  &__speakerBox {
    display: flex;
    gap: 45px;
  }

  &__img {
    width: 138px;
    height: 138px;
    border-radius: 200px;
    border: 2px solid $colorPrimary;
  }

  &__speakerName {
    font-weight: bold;
  }

  &__videoBox {
    position: relative;
    width: 57.9%;
    height: 22.1vw;
  }

  &__videoBg {
    position: absolute;
    top: 0;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background-color: $colorPrimary;
  }

  &__video {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    border-radius: 40px;
  }

  &__btn {
    width: fit-content;
  }

  &__textForVideo {
    padding: 20px;
    border-radius: 10px;
    background-color: $colorFiller;
  }

  &__answerBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__download {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    text-wrap: nowrap;
    color: $colorPrimary;
  }

  &__hr {
    width: 100%;
    height: 3px;
    background-color: $colorPrimary;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__buttonsBox {
    display: flex;
    justify-content: center;
  }

  &__button {
    width: fit-content;
  }

  &__dropdownsBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__linkBox {
    padding: 10px 30px 0 30px;
    &:hover {
      background-color: $colorLightGrey;
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding-bottom: 10px;
  }

  &__linkHr {
    margin: 0;
    width: 100%;
    height: 3px;
    background-color: $colorDarkGrey;
    border: none;
  }

  +mediaTablet() {
    padding: 2.5vw;
    gap: 4vw;

    &__content {
      gap: 4vw;
    }

    &__linkBox {
      padding: 1vw 1vw 0 1vw;
    }
    &__link {
      padding-bottom: 1vw;
    }
  }
}
</style>
