<template>
  <div class="commonLocation">
    <div class="commonLocation__content">
      <p>Выдача документов осуществляется</p>

      <h6 class="commonLocation__title">Пн-Пт с 09:00 до 17:30</h6>

      <p>
        Удостоверения можно получить по адресу г. Москва, метро «Студенческая»,
        ул. Киевская, д. 20, первый этаж, кабинет № 2
      </p>
    </div>

    <img src="@/assets/img/location-img.jpg" class="commonLocation__img" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonLocation",
});
</script>

<style lang="stylus" scoped>
.commonLocation {
  display: flex;
  background-color: $colorPrimary;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $colorFiller;
    padding: 0 20px;
  }

  &__content,
  &__img {
    width: 50%;
  }

  &__img {
    max-width: 480px;
    max-height: 280px;
  }

  &__title {
    margin-top: 8px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  +mediaTablet() {
    &__title {
      margin-top: .4vw;
      margin-bottom: 1.5vw;
    }

    &__content {
      padding: 0 2vw;
    }
  }
}

.dpo .commonLocation {
  background-color: $colorPrimaryDpo;
}
</style>
