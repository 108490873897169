<template>
  <div class="practiceReports">
    <div class="practiceReports__inputBox">
      <CommonSelect
        :select-options="reportsSelect"
        :hidden-text="'Вид практики'"
      />

      <CommonDownloadFile label="Прикрепить файл" file="#" />
    </div>

    <div class="practiceReports__textareaBoxWrap">
      <div class="practiceReports__textareaBox">
        <label class="practiceReports__label">Отзыв</label>
        <textarea
          placeholder="Напишите ваш ответ"
          class="practiceReports__textarea"
        />
      </div>

      <CommonDownloadFile label="Прикрепить файл" file="#" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CommonDownloadFile } from "@/components/CommonDownloadFile";
import { CommonSelect } from "@/components/CommonSelect";
import { reportsSelect } from "./constants";

export default defineComponent({
  name: "PracticeReports",

  components: {
    CommonSelect,
    CommonDownloadFile,
  },

  setup() {
    return {
      reportsSelect,
    };
  },
});
</script>

<style lang="stylus">
.practiceReports {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;

  &__inputBox,
  &__textareaBoxWrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__textarea {
    padding: 30px;
    width: 100%;
    height: 10vw;
    resize: none;
    border-radius: 10px;

    &:focus {
      outline: none;
      border: 1px solid $colorPrimary;
    }
  }

  &__textareaBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__label {
    getFont("text_16");
  }

  +mediaTablet() {
    padding: 3vw 0;
    gap: 3vw;

    &__inputBox {
      gap: 1.1vw;
    }

    &__inputBox {
      max-width: 50%;
    }
  }
}
</style>
