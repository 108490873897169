<template>
  <div class="commonSelect">
    <VDropdown
      :distance="16"
      :autoSize="true"
      :shown="isShown"
      @apply-show="isShown = true"
      @apply-hide="isShown = false"
    >
      <CommonInput
        :value="selectedValue"
        :opacityText="selectedValue === hiddenText && !isShown"
        readonly
        style="cursor: pointer"
      >
        <slot>
          {{ label }}
        </slot>

        <template #suffix>
          <svg class="commonSelect__arrow" v-if="isShown">
            <use xlink:href="#arrow-top-icon--sprite" />
          </svg>

          <svg class="commonSelect__arrow" v-else>
            <use xlink:href="#arrow-bottom-icon--sprite" />
          </svg>
        </template>
      </CommonInput>

      <template #popper>
        <div class="commonSelect__selectContainer">
          <button
            :class="[
              'commonSelect__selectBtn',
              {
                commonSelect__selectBtn_selected: item.label === selectedValue,
              },
            ]"
            v-close-popper
            v-for="item in selectOptions"
            :key="item.value"
            @click="setSelectedValue(item.label)"
          >
            {{ item.label }}
          </button>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType } from "vue";
import type { CommonSelectProps } from "./types";
import { CommonInput } from "@/components/CommonInput";

import "@/assets/img/icons/arrow-top-icon.svg?sprite";
import "@/assets/img/icons/arrow-bottom-icon.svg?sprite";

export default defineComponent({
  name: "CommonSelect",

  components: {
    CommonInput,
  },

  props: {
    selectOptions: {
      type: Array as PropType<CommonSelectProps["selectOptions"]>,
      default: () => [],
      required: true,
    },
    hiddenText: {
      type: String as PropType<CommonSelectProps["hiddenText"]>,
      default: "",
    },
    label: {
      type: String as PropType<CommonSelectProps["label"]>,
      default: "",
    },
    value: {
      type: String as PropType<CommonSelectProps["value"]>,
      default: "",
    },
  },

  setup(props) {
    const isShown = ref<boolean>(false);

    const selectedValue = ref<string>(props.value || props.hiddenText);

    const setSelectedValue = (value: string) => {
      selectedValue.value = value;
    };

    return {
      selectedValue,
      isShown,
      setSelectedValue,
    };
  },
});
</script>

<style lang="stylus">
.v-popper__arrow-container {
  display: none;
}
</style>

<style lang="stylus" scoped>
.commonSelect {
  position: relative;
  &__arrow {
    width: 24px;
    height: 24px;
  }

  &__selectContainer {
    padding: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__selectBtn {
    padding: 10px 16px;
    background-color: $colorFiller;
    border: none;
    border-radius: 5px;
    text-align: left;
    &_selected,
    &:hover {
      background-color: $colorSelectGrey;
    }
  }
}
</style>
