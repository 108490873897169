export const reportsSelect = [
  {
    label: "Учебная практика",
    value: "Учебная практика",
  },
  {
    label: "Технологическая практика",
    value: "Технологическая практика",
  },
  {
    label: "Научно-исследовательская работа",
    value: "Научно-исследовательская работа",
  },
];
