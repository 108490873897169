<template>
  <button
    class="secondaryButton"
    @click="click"
    @mousedown="mousedown"
    @mouseup="mouseup"
    v-bind="$attrs"
  >
    <span class="secondaryButton__content">
      <slot />
    </span>
  </button>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonButtonSecondary",
  emits: ["click"],
  props: {},
  setup(_, { emit }) {
    const click = (e) => {
      emit("click", e);
    };

    const mousedown = () => {
      const secondaryButton = document.querySelector(".secondaryButton");
      secondaryButton.classList.add("secondaryButton__selected");
    };

    const mouseup = () => {
      const secondaryButton = document.querySelector(".secondaryButton");
      secondaryButton.classList.remove("secondaryButton__selected");
    };

    return {
      click,
      mousedown,
      mouseup,
    };
  },
});
</script>

<style lang="stylus" scoped>
.secondaryButton {
  padding: 12px 16px;
  user-select: none;

  +mediaTablet() {
    padding: .9vw 4.2vw;
  }

  border-radius: 10px;
  color: $colorPrimary;
  background-color: $colorFiller;
  border: 2px solid $colorPrimary;
  cursor: pointer;
  transition: all 0.1s;
  getFontText();

  &__selected {
    background-color: $colorThird!important;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $colorSecond;
    color: $colorFiller;
  }

  &:disabled {
    cursor: default;
    background-color: $colorLightGrey;
    color: $colorDarkGrey;
    opacity: 1;
  }

  &__content {
    opacity: 1;
  }
}

.dpo .secondaryButton {
  background-color: $colorGreenMedium;
  color: $colorFiller;
  border: none;

  &:hover {
    background-color: $colorGreenClickDpo;
  }
}
</style>
