<template>
  <LayoutBase>
    <div class="advertPage">
      <CommonNavigation :nav-list="navList" />

      <div class="advertPage__content">
        <div class="advertPage__header">
          <div class="advertPage__senderContainer">
            <div class="advertPage__senderIcon" />

            <div class="advertPage__sender">
              <b class="main-color-text">Пожарская Н. В.</b>

              <div class="advertPage__senderTab">Преподаватель</div>
            </div>
          </div>

          <p class="opacity-text">25.05.2024</p>
        </div>

        <div class="advertPage__text" v-html="advertText" />
      </div>
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { navList, advertText } from "./constants";
import { CommonNavigation } from "@/components/CommonNavigation";

export default defineComponent({
  name: "advertPage",

  components: {
    LayoutBase,
    CommonNavigation,
  },

  setup() {
    return {
      advertText,
      navList,
    };
  },
});
</script>

<style lang="stylus" scoped>
.advertPage {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 35px;
    gap: 40px;
    background-color: $colorLightGrey;
  }

  &__senderIcon {
    width: 90px;
    height: 90px;
    border: 1px solid $colorPrimary;
    border-radius: 100px;
    background-color: $colorDarkGrey;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__senderContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__sender {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__senderTab {
    padding: 5px;
    border-radius: 10px;
    background-color: $colorPrimary;
    color: $colorFiller;
  }

  +mediaTablet() {
    gap: 3vw;
  }
}
</style>
