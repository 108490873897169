import { RouteName } from "@/constants";

export const navList = [
  {
    id: 1,
    label: "Объявления",
    pathName: RouteName.ADVERT,
  },
  {
    id: 2,
    label: "Нормативные документы",
    pathName: RouteName.DOCS,
  },
];

export const advertText = `
  <p>Здравствуйте, Виталий!</p>
  <p>Рад приветствовать вас на курсе. Меня зовут Наталья, я буду проверять ваши работы. Для знакомства кратко расскажу о себе и своем профессиональном опыте. В отрасли ретуши и графической обработки я работаю уже более 10 лет. Профессиональный фотограф, основные направления – портретнаяи свадебная фотография. Закончил NYIP по направлению “Professional photography”, большое количество курсов по ретуши, цветокоррекции и обработке. Более 4 лет работал преподавателемпо фотографии и компьютерной обработке в СПб школе телевидения.</p>
`;
