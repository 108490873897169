<template>
  <div class="profileDpoAbout">
    <div class="profileDpoAbout__imgBox">
      <a
        href="#"
        class="profileDpoAbout__photo profileDpoAbout__addPhoto"
        v-if="1"
      >
        Добавить<br />фото
      </a>

      <img class="profileDpoAbout__photo" v-else />
    </div>

    <div class="profileDpoAbout__inputsBox">
      <CommonInput>Фамилия</CommonInput>

      <CommonInput>Имя</CommonInput>

      <CommonInput>Отчество</CommonInput>

      <CommonInput>Дата рождения</CommonInput>

      <CommonInput>Электронная почта</CommonInput>

      <CommonInput>Телефон</CommonInput>

      <CommonSelect :select-options="genderSelectOptions" :hidden-text="'Пол'">
        Пол
      </CommonSelect>
    </div>

    <CommonButtonPrimary>Сохранить</CommonButtonPrimary>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CommonInput } from "@/components/CommonInput";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { CommonSelect } from "@/components/CommonSelect";
import { genderSelectOptions } from "../../constants";

export default defineComponent({
  name: "ProfileDpoAbout",

  components: {
    CommonInput,
    CommonSelect,
    CommonButtonPrimary,
  },

  setup() {
    return {
      genderSelectOptions,
    };
  },
});
</script>

<style lang="stylus" scoped>
.profileDpoAbout {
  &__photo {
    width: 15vw;
    height: 15vw;
    border-radius: 200px;
  }

  &__addPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $colorDarkGrey;
    text-decoration: none;
    text-align: center;
  }

  &__inputsBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px 22px;
    margin: 32px 0;
  }
}
</style>
