export const questionsList = [
  {
    label: "В какое время суток вам кофмортнее обучаться?",
    answers: ["утром", "днем", "вечером"],
  },
  {
    label: "Есть ли у вас высшее образование?",
    answers: ["оконченное высшее", "незаконченное высшее", "нет"],
  },
  {
    label: "Связано ли ваше образование с медициной?",
    answers: ["да", "нет", "затрудняюсь ответить"],
  },
];
