export const genderSelectOptions = [
  {
    value: "male",
    label: "Мужской",
  },
  {
    value: "female",
    label: "Женский",
  },
];

export const educationLevel = [
  {
    value: "1",
    label: "Test1",
  },
  {
    value: "2",
    label: "Test2",
  },
  {
    value: "3",
    label: "Test3",
  },
  {
    value: "4",
    label: "Test4",
  },
];

export const educationSpeciality = [
  {
    value: "1",
    label: "Test1",
  },
  {
    value: "2",
    label: "Test2",
  },
  {
    value: "3",
    label: "Test3",
  },
  {
    value: "4",
    label: "Test4",
  },
];
