<template>
  <div class="commonChat">
    <div class="commonChat__header">
      <h6>Чат с {{ teacher }}</h6>

      <svg class="commonChat__headerClose" @click="$emit('closeChat')">
        <use xlink:href="#close-icon--sprite" />
      </svg>
    </div>

    <div class="commonChat__content">
      <div class="commonChat__contentItem" v-for="item in chat" :key="item.day">
        <p class="commonChat__day opacity-text">{{ item.day }}</p>

        <div
          class="commonChat__dayMessagesBox"
          v-for="message in item.messages"
          :key="message.time"
        >
          <div class="commonChat__senderIcon" />

          <div class="commonChat__message">
            <div class="commonChat__senderInfo">
              <p class="commonChat__sender">
                {{ message.sender ? "Пожарская Н. В." : "Иванов И. И." }}
              </p>

              <p class="opacity-text">{{ message.time }}</p>
            </div>

            <p>{{ message.message }}</p>
          </div>
        </div>
      </div>
    </div>

    <textarea class="commonChat__textarea" placeholder="Введите сообщение" />

    <div class="commonChat__buttonsBox">
      <CommonDownloadFile label="Прикрепить файл" />

      <CommonButtonPrimary>Отправить</CommonButtonPrimary>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType } from "vue";
import { CommonDownloadFile } from "../CommonDownloadFile";
import { CommonButtonPrimary } from "../CommonButtonPrimary";
import type { CommonChatProps } from "./types";

import "@/assets/img/icons/close-icon.svg?sprite";

export default defineComponent({
  name: "CommonChat",

  components: {
    CommonDownloadFile,
    CommonButtonPrimary,
  },

  props: {
    chat: {
      type: Array as PropType<CommonChatProps["chat"]>,
      default: () => [],
      required: true,
    },
    teacher: {
      type: String as PropType<CommonChatProps["teacher"]>,
      default: "",
    },
  },
});
</script>

<style lang="stylus">
.v-popper__arrow-container {
  display: none;
}
</style>

<style lang="stylus" scoped>
p {
  margin: 0;
}
.commonChat {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  box-shadow: 0 4px 12px 0 rgba(25, 135, 84, 0.25);
  border-radius: 10px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__headerClose {
    width: 15px;
    height: 15px;

    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__textarea,
  &__content {
    padding: 30px;
    width: 100%;
    resize: none;
    border-radius: 10px;
    border: 1px solid $colorFontBase;

    &:focus {
      outline: none;
      border: 1px solid $colorPrimary;
    }
  }

  &__textarea {
    height: 20%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
  }

  &__contentItem {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__day {
    text-align: center;
  }

  &__dayMessagesBox {
    display: flex;
    gap: 8px;
  }

  &__senderIcon {
    width: 35px;
    height: 35px;
    border: 1px solid $colorPrimary;
    border-radius: 100px;
    background-color: $colorDarkGrey;
  }

  &__senderInfo {
    display: flex;
    gap: 8px;
  }

  &__message {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__buttonsBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  +mediaTablet() {
    padding: 1vw;
  }
}

.dpo .commonChat {
  &__textarea,
  &__content {
    &:focus {
      border: 1px solid $colorPrimaryDpo;
    }
  }

  &__senderIcon {
    border: 1px solid $colorPrimaryDpo;
  }
}
</style>
