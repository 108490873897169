<template>
  <div class="commonQuestion-wrap">
    <div class="commonQuestion">
      <div class="commonQuestion__content">
        <p class="commonQuestion__question">{{ question }}</p>

        <div class="commonQuestion__answerBox" v-if="isOpenQuestion">
          <p class="commonQuestion__answer" v-if="!isSlotAnswer">
            {{ answer }}
          </p>

          <slot v-else />
        </div>
      </div>

      <svg
        class="commonQuestion__arrow"
        v-if="isOpenQuestion"
        @click="toggleQuestion"
      >
        <use xlink:href="#arrow-second-top-icon--sprite" />
      </svg>

      <svg class="commonQuestion__arrow" v-else @click="toggleQuestion">
        <use xlink:href="#arrow-second-bottom-icon--sprite" />
      </svg>
    </div>

    <hr class="commonQuestion__hr" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, computed } from "vue";
import type { CommonQuestionProps } from "./types";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";

import "@/assets/img/icons/arrow-second-top-icon.svg?sprite";
import "@/assets/img/icons/arrow-second-bottom-icon.svg?sprite";

export default defineComponent({
  name: "CommonQuestion",

  props: {
    question: {
      type: String as PropType<CommonQuestionProps["question"]>,
    },
    answer: {
      type: String as PropType<CommonQuestionProps["answer"]>,
    },
    isSlotAnswer: {
      type: Boolean as PropType<CommonQuestionProps["isSlotAnswer"]>,
      default: false,
    },
  },

  setup() {
    const route = useRoute();

    const isOpenQuestion = ref<boolean>(false);

    const hasDpo = computed(() => route.matched[0]?.name === RouteName.DPO);

    const toggleQuestion = () => {
      isOpenQuestion.value = !isOpenQuestion.value;
    };

    return {
      hasDpo,
      isOpenQuestion,
      toggleQuestion,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonQuestion {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;

  &__question {
    font-weight: bold;
  }

  &__arrow {
    width: 32px;
    height: 32px;
    cursor: pointer;
    min-width: 32px;

    &:hover {
      opacity: .8;
    }
  }
}
</style>
