<template>
  <header class="magistracy-header">
    <nav class="navbar">
      <router-link class="navbar-brand" :to="{ name: RouteName.HOME }">
        <svg class="magistracy__logo">
          <use xlink:href="#logo-white-icon--sprite" />
        </svg>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#magistracyMenu"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="magistracyMenu">
        <ul class="navbar-nav">
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY }"
            >
              Магистратура
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_ABOUT,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_ABOUT }"
            >
              О программе
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_PROGRAM,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_PROGRAM }"
            >
              Структура программы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY__ENTRANCE,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY__ENTRANCE }"
            >
              Приемная кампания
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_DOCUMENTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_DOCUMENTS }"
            >
              Документы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_CONTACTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_CONTACTS }"
            >
              Контакты
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <main>
    <div class="magistracy-page">
      <div class="container">
        <div class="page-content">
          <h1 class="page-title">О программе</h1>
          <div class="h4 mb-5">
            Программа для лидеров цифровой трансформации здравоохранения
          </div>

          <div class="mb-5">
            <h2 class="h3 font-weight-bolder mb-4">Цель программы</h2>
            <ul class="check-list lead text-uppercase">
              <li>
                Освоить ключевые digital-технологии для трансформации
                медицинских организаций;
              </li>
              <li>
                Изучить лучшие практики лидеров цифровизации здравоохранения.
              </li>
            </ul>
          </div>

          <div class="lead mb-5">
            <p>
              <b class="text-secondary">Мы готовим</b> лидеров цифровой
              трансформации здравоохранения, способных грамотно ставить и решать
              задачи по оптимизации информационных систем и технологий.
            </p>
            <p>
              <b class="text-secondary">Мы даём</b> полный набор инструментов
              для разработки эффективных информационных систем и применения
              digital-технологий в здравоохранении.
            </p>
            <p>
              <b class="text-secondary">Мы учим</b> находить и применять лучшие
              практики для цифровой трансформации здравоохранения.
            </p>
          </div>

          <div class="intro__invite">
            <h3 class="mb-4">
              Развивайтесь в цифровой модели здравоохранения Москвы
            </h3>
            <div class="row">
              <div class="invite-col">
                <img src="img/invite-point.svg" alt="" />
                <span>Постройте карьеру в&nbsp;IT в&nbsp;здравоохранении</span>
              </div>
              <div class="invite-col">
                <img src="img/invite-point.svg" alt="" />
                <span>Выбирайте новый уровень в&nbsp;профессии</span>
              </div>
              <div class="invite-col">
                <img src="img/invite-point.svg" alt="" />
                <span
                  >Станьте лидерами в&nbsp;цифровизации здравоохранения</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="intro__advantages magistracy-secton">
      <div class="container">
        <h2 class="magistracy-title">Преимущества</h2>
        <div class="advantages-row">
          <div class="advantages-col">
            <div class="advantage-card">
              Использование технологий искусственного интеллекта
            </div>
          </div>
          <div class="advantages-col">
            <div class="advantage-card">
              Практические кейсы по каждой дисциплине в Ваше портфолио
            </div>
          </div>
          <div class="advantages-col">
            <div class="advantage-card">
              Эксперты-практики передадут Вам реальный опыт проведения цифровой
              трансформации в сфере здравоохранения Москвы
            </div>
          </div>
          <div class="advantages-col">
            <div class="advantage-card">
              Индивидуальная цифровая траектория обучения
            </div>
          </div>
          <div class="advantages-col">
            <div class="advantage-card">
              Обучение онлайн – учитесь без отрыва от основной работы
            </div>
          </div>
          <div class="advantages-col">
            <div class="advantage-card">
              Баланс контактных занятий с преподавателями и самостоятельной
              работы
            </div>
          </div>
          <div class="advantages-col">
            <div class="advantage-card">
              Нетворкинг – мастер-классы от лидеров цифровизации здравоохранения
              г. Москвы
            </div>
          </div>
          <div class="advantages-col">
            <div class="advantage-card">
              Поддержка от индивидуального куратора курса и ИИ наставника
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="magistracy-skills">
      <div class="skills__body">
        <h2 class="magistracy-title">Вы научитесь</h2>
        <div class="skills__row">
          <div class="skills__item">
            <div class="skill">
              Моделировать информационные системы в&nbsp;здравоохранении
            </div>
          </div>
          <div class="skills__item">
            <div class="skill">
              Применять искусственный интеллект в&nbsp;здравоохранении
            </div>
          </div>
          <div class="skills__item">
            <div class="skill">
              Разрабатывать сетевые приложения для сферы здравоохранения
            </div>
          </div>
          <div class="skills__item">
            <div class="skill">Управлять IT-проектами</div>
          </div>
          <div class="skills__item">
            <div class="skill">Обрабатывать большие данные</div>
          </div>
          <div class="skills__item">
            <div class="skill">Обучать нейронные сети</div>
          </div>
          <div class="skills__item">
            <div class="skill">
              Управлять информационной безопасностью в&nbsp;здравоохранении
            </div>
          </div>
        </div>
        <div class="skills__note">
          Вы сможете сразу интегрировать полученные знания в текущую работу!
        </div>
      </div>
    </section>

    <section class="magistracy-career">
      <h2 class="magistracy-title">
        Возможности карьеры после окончания программы
      </h2>
      <div class="row">
        <div class="career__item">
          <div class="career-head">
            <svg class="magistracy__icon">
              <use xlink:href="#admin--sprite" />
            </svg>
            Администратор <br />информационных систем
          </div>
          <div class="career-body">
            Возможность занимать позиции администраторов информационных систем в
            медицинских учреждениях.
          </div>
        </div>
        <div class="career__item">
          <div class="career-head">
            <svg class="magistracy__icon">
              <use xlink:href="#analyst--sprite" />
            </svg>
            Аналитик <br />медицинских данных
          </div>
          <div class="career-body">
            Карьерные перспективы в области анализа медицинских данных и
            оптимизации информационных систем в здравоохранении.
          </div>
        </div>
        <div class="career__item">
          <div class="career-head">
            <svg class="magistracy__icon">
              <use xlink:href="#inform--sprite" />
            </svg>
            Руководитель управления по&nbsp;информатизации
            в&nbsp;здравоохранении
          </div>
          <div class="career-body">
            Возможность работы в качестве руководителя управления по
            информатизации медицинского учреждения.
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-training">
      <div class="container">
        <h2 class="magistracy-title">Как учиться в онлайн магистратуре</h2>
        <div class="training">
          <div class="training__item">
            <div class="training-card">
              <div class="training-head">Удобное расписание</div>
              <div class="training-body">
                Занятия 3 дня в неделю вечером. Записи лекций доступны
                в&nbsp;личном кабинете. Консультации с&nbsp;куратором и
                преподавателями по&nbsp;всем вопросам. В&nbsp;неделю
                12&nbsp;ак.&nbsp;часов.
              </div>
            </div>
          </div>
          <div class="training__item">
            <div class="training-card">
              <div class="training-head">Семинары в онлайн формате</div>
              <div class="training-body">
                Не нужно тратить время на дорогу. Занимайтесь там, где удобно.
                Подробный разбор с&nbsp;преподавателями практических кейсов,
                задач и алгоритмов работы с&nbsp;программным обеспечением.
                Ответы на&nbsp;все вопросы.
              </div>
            </div>
          </div>
          <div class="training__item">
            <div class="training-card">
              <div class="training-head">
                Практика с применением искусственного интеллекта
              </div>
              <div class="training-body">
                Будут рассмотрены реальные примеры использования ИИ и нейросетей
                от&nbsp;организаций московского здравоохранения. Вы сможете,
                обучаясь в&nbsp;магистратуре получить опыт реализации проектов
                цифровой трансформации здравоохранения в&nbsp;Москве.
              </div>
            </div>
          </div>
          <div class="training__item">
            <div class="training-card">
              <div class="training-head">Крутое портфолио у выпускников</div>
              <div class="training-body">
                Более 15 практических кейсов по&nbsp;всем дисциплинам
                в&nbsp;портфолио выпускника программы.
              </div>
            </div>
          </div>
        </div>

        <a
          href="#magistracyRegister"
          class="btn btn-magistracy"
          data-toggle="modal"
          >Подать заявку</a
        >
      </div>
    </section>
  </main>
  <footer class="footer-magistracy">
    <div class="container">
      <div class="contacts-title">Приемная комиссия</div>
      <div class="contacts">
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#clock--sprite" />
            </svg>
          </div>
          <div class="contact-name">Часы работы</div>
          <div class="contact-text">
            <p>Пн-Пт 09:00 – 17:30</p>
            <p>Сб-Вс Выходные</p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#email--sprite" />
            </svg>
          </div>
          <div class="contact-name">Почта</div>
          <div class="contact-text">
            <p>
              <a href="mailto:TolstyakovaOV1@zdrav.mos.ru"
                >TolstyakovaOV1@zdrav.mos.ru</a
              >
            </p>
            <p>
              <a href="mailto:PerunovaEA@zdrav.mos.ru"
                >PerunovaEA@zdrav.mos.ru</a
              >
            </p>
            <p>
              <a href="mailto:KuznetsovMY1@zdrav.mos.ru"
                >KuznetsovMY1@zdrav.mos.ru</a
              >
            </p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#phone--sprite" />
            </svg>
          </div>
          <div class="contact-name">Телефон</div>
          <div class="contact-text">
            <p>8 (499) 249-74-72<br />(доб. 574, 572, 571)</p>
            <p>8 (905) 734-17-14</p>
            <p>8 (977) 570-30-66</p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#map--sprite" />
            </svg>
          </div>
          <div class="contact-name">Адрес</div>
          <div class="contact-text">
            <p>
              121165, г. Москва,<br />
              ул. Киевская, д. 20
            </p>
            <p><a href="#" target="_blank">Карта</a></p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer__copyright">&copy; ГБУ «НИИОЗММ ДЗМ», 2024</div>
  </footer>
  <div class="modal fade" id="magistracyRegister" tabindex="-1">
    <div class="modal-dialog magistracy-register">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
          <h3 class="modal-title">Подать заявку</h3>

          <div class="register-intro js-register-types">
            <div class="buttons-group">
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#personRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#person"></use>
                  </svg>
                  <div class="card-body">Лично</div>
                </button>
              </div>
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#webformRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#webform"></use>
                  </svg>
                  <div class="card-body">
                    Через Личный кабинет на&nbsp;сайте
                  </div>
                </button>
              </div>
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#postalRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#postal"></use>
                  </svg>
                  <div class="card-body">Через операторов почтовой связи</div>
                </button>
              </div>
            </div>
          </div>

          <div class="register-tabs js-register-tabs">
            <div class="nav">
              <a class="nav-link" data-toggle="tab" href="#personRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#person"></use>
                </svg>
                Лично
              </a>
              <a class="nav-link" data-toggle="tab" href="#webformRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#webform"></use>
                </svg>
                На сайте
              </a>
              <a class="nav-link" data-toggle="tab" href="#postalRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#postal"></use>
                </svg>
                По почте
              </a>
            </div>
            <div class="tab-content">
              <div class="tab-pane" id="personRegister" role="tabpanel">
                <p>
                  <b>Вы можете подать документы лично по адресу:</b>
                  г.&nbsp;Москва, ул.&nbsp;Киевская, д.&nbsp;20, кабинет&nbsp;8.
                </p>
                <p><b>Часы работы:</b> Пн-Пт 09:00 – 17:30</p>
                <p>
                  <b
                    >Список документов для поступления на&nbsp;программу
                    магистратуры:</b
                  >
                </p>
                <ol>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/000/000ec0855d5336e3befb60fcf61f82a1.docx"
                      tarhet="_blank"
                      >Заявление на обучение</a
                    >
                  </li>
                  <li>
                    Копия паспорта (главная и регистрация по&nbsp;месту
                    жительства)
                  </li>
                  <li>
                    Копия диплома о высшем образовании с&nbsp;приложениями
                  </li>
                  <li>Копия СНИЛС</li>
                  <li>ИНН (копия или номер)</li>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/1b0/1b02e815b990b92a660bf53a78f855f2.docx"
                      target="_blank"
                      >Согласие на обработку персональных данных</a
                    >
                  </li>
                </ol>
                <div class="register-footer">
                  <button type="button" class="btn" data-dismiss="modal">
                    Понятно
                  </button>
                </div>
              </div>

              <div class="tab-pane" id="webformRegister" role="tabpanel">
                <p>
                  Для подачи документов в магистратуру Вы будете перенаправлены
                  на сайт Государственного бюджетного учреждения
                  Научно-исследовательский институт организации здравоохранения
                  и медицинского менеджмента Департамента здравоохранения г.
                  Москвы (ГБУ «НИИОЗММ ДЗМ»).
                </p>
                <ol class="divided">
                  <li>
                    Зарегистрируйтесь на сайте
                    <a href="https://niioz.ru/" target="_blank">www.niioz.ru</a>
                  </li>
                  <li>Подтвердите электронную почту по ссылке из письма</li>
                  <li>
                    Войдите в Личный кабинет в раздел
                    <a
                      href="https://niioz.ru/personal/magistracy/"
                      target="_blank"
                      >«Подать документы в магистратуру»</a
                    >
                  </li>
                  <li>Заполните форму, нажмите кнопку «Сохранить»</li>
                </ol>
                <p>
                  <a href="#" target="_blank">Скачать подробную инструкцию</a>
                </p>
                <div class="register-footer">
                  <a
                    href="https://niioz.ru/obrazovanie/magistratura/"
                    class="btn"
                    target="_blank"
                    >Продолжить</a
                  >
                </div>
              </div>

              <div class="tab-pane" id="postalRegister" role="tabpanel">
                <p>
                  Вы можете направить документы через оператора почтовой связи,
                  например, Почтой России.
                </p>
                <p>
                  <b>Адрес:</b> ул. Большая Татарская, д.&nbsp;30, помещение
                  1/2, Москва, 115184.
                </p>
                <p>
                  <b
                    >Список документов для поступления на&nbsp;программу
                    магистратуры:</b
                  >
                </p>
                <ol>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/000/000ec0855d5336e3befb60fcf61f82a1.docx"
                      target="_blank"
                      >Заявление на обучение</a
                    >
                  </li>
                  <li>
                    Копия паспорта (главная и регистрация по&nbsp;месту
                    жительства)
                  </li>
                  <li>
                    Копия диплома о высшем образовании с&nbsp;приложениями
                  </li>
                  <li>Копия СНИЛС</li>
                  <li>ИНН (копия или номер)</li>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/1b0/1b02e815b990b92a660bf53a78f855f2.docx"
                      target="_blank"
                      >Согласие на обработку персональных данных</a
                    >
                  </li>
                </ol>
                <div class="register-footer">
                  <button type="button" class="btn" data-dismiss="modal">
                    Понятно
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onBeforeUnmount } from "vue";
import { RouteName } from "@/constants";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "HomeMagistracyAboutPage",

  components: {},

  setup() {
    const route = useRoute();

    const setMainCss = () => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.id = "main-css";

      document.head.appendChild(link);

      link.href = "/assets/css/main.css";
    };

    const removeMainCss = () => {
      document.getElementById("main-css")?.remove();
    };

    onBeforeMount(() => setMainCss());

    onBeforeUnmount(() => removeMainCss());

    return {
      RouteName,
      route,
    };
  },
});
</script>

<style lang="stylus" scoped>
.magistracy__logo {
  width: 206px;
  height: 40px;
  min-width: 206px;
}
.navbar-collapse {
  background-color: transparent;
}
.magistracy-page::before {
  background-image: url("@/assets/img/magistracy-hand.png");
}
.magistracy-skills:before {
  background-image: url("@/assets/img/magistracy-bg.png");
}
</style>
