<template>
  <div class="commonBannerIiMessage">
    <div class="commonBannerIiMessage__imgBox">
      <img class="commonBannerIiMessage__img" src="@/assets/img/mentor.png" />
    </div>

    <div class="commonBannerIiMessage__content">
      <p class="commonBannerIiMessage__text">{{ title }}</p>

      <p class="commonBannerIiMessage__text">{{ text }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CommonBannerIiMessageProps } from "./types";

export default defineComponent({
  name: "CommonBannerIiMessage",

  props: {
    title: {
      type: String as PropType<CommonBannerIiMessageProps["title"]>,
    },
    text: {
      type: String as PropType<CommonBannerIiMessageProps["text"]>,
      required: true,
    },
  },
});
</script>

<style lang="stylus" scoped>
.commonBannerIiMessage {
  padding: 33px 25px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  background-color: $colorPrimary;
  color: $colorFiller;
  text-align: left;

  &__imgBox {
    position: absolute;
    left: 25px;
    top: -24px;
  }

  &__img {
    width: 13.5vw;
    height: 13.5vw;
    max-width: 220px;
    max-height: 220px;
  }

  &__content {
    width: 70%;
  }
}

.dpo .commonBannerIiMessage {
  background-color: $colorPrimaryDpo;
}
</style>
