import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TestIntro = _resolveComponent("TestIntro")!
  const _component_CommonTest = _resolveComponent("CommonTest")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!

  return (_openBlock(), _createBlock(_component_LayoutBase, null, {
    default: _withCtx(() => [
      (!_ctx.isOpenTest)
        ? (_openBlock(), _createBlock(_component_TestIntro, { key: 0 }))
        : (_openBlock(), _createBlock(_component_CommonTest, { key: 1 }))
    ]),
    _: 1
  }))
}