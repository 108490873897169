<template>
  <div class="profileDpoQualification">
    <div class="profileDpoQualification__container">
      <div
        class="profileDpoQualification__item"
        v-for="(education, i) in educationsList"
        :key="`education-${i}`"
      >
        <CommonInput type="date" class="profileDpoQualification__date">
          Дата окончания обучения
        </CommonInput>

        <CommonInput class="profileDpoQualification__input">
          Наименование программы
        </CommonInput>

        <CommonInput class="profileDpoQualification__input">
          Количество часов
        </CommonInput>
      </div>
    </div>

    <svg class="profileDpoQualification__moreBtn" @click="addMoreEducations">
      <use xlink:href="#add-more-icon--sprite" />
    </svg>

    <br />

    <CommonButtonPrimary>Сохранить</CommonButtonPrimary>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { CommonInput } from "@/components/CommonInput";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";

import "@/assets/img/icons/add-more-icon.svg?sprite";

export default defineComponent({
  name: "ProfileDpoQualification",

  components: {
    CommonInput,
    CommonButtonPrimary,
  },

  setup() {
    const educationsList = ref([
      {
        educationLevelValue: "test3",
        educationSpecialityValue: "test5",
      },
    ]);

    const addMoreEducations = () => {
      educationsList.value.push({
        educationLevelValue: "",
        educationSpecialityValue: "",
      });
    };

    return {
      educationsList,
      addMoreEducations,
    };
  },
});
</script>

<style lang="stylus" scoped>
.profileDpoQualification {
  max-width: 33.6vw;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 3vw;
  }

  &__date {
    max-width: 10vw;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }

  &__moreBtn {
    margin-top: 1.5vw;
    margin-bottom: 1vw;
    width: 2.5vw;
    height: 2.5vw;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
}
</style>
