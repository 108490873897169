import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0b2657ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "portfolioPage" }
const _hoisted_2 = {
  key: 0,
  class: "portfolioPage__link"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 2,
  class: "portfolioPage__linkHr"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonDownloadFile = _resolveComponent("CommonDownloadFile")!
  const _component_EducationWorks = _resolveComponent("EducationWorks")!
  const _component_PracticeReports = _resolveComponent("PracticeReports")!
  const _component_GraduationWork = _resolveComponent("GraduationWork")!
  const _component_PublicationsWorks = _resolveComponent("PublicationsWorks")!
  const _component_OtherWorks = _resolveComponent("OtherWorks")!
  const _component_CommonDropdownSecond = _resolveComponent("CommonDropdownSecond")!
  const _component_CommonDropdown = _resolveComponent("CommonDropdown")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!

  return (_openBlock(), _createBlock(_component_LayoutBase, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownsList, (dropdown, index) => {
          return (_openBlock(), _createBlock(_component_CommonDropdown, {
            label: dropdown.label,
            isSelectGrey: "",
            key: dropdown.label
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdown.subLabelsList, (subLabel, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "portfolioPage__linkBox",
                  key: subLabel.title
                }, [
                  (index === 2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_CommonDownloadFile, {
                          label: "Прикрепить файл",
                          file: "#"
                        })
                      ]))
                    : (_openBlock(), _createBlock(_component_CommonDropdownSecond, {
                        key: 1,
                        label: subLabel.title,
                        class: "portfolioPage__link"
                      }, {
                        default: _withCtx(() => [
                          (index === 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                (i === 0)
                                  ? (_openBlock(), _createBlock(_component_EducationWorks, { key: 0 }))
                                  : (i === 1)
                                    ? (_openBlock(), _createBlock(_component_PracticeReports, { key: 1 }))
                                    : (_openBlock(), _createBlock(_component_GraduationWork, { key: 2 }))
                              ]))
                            : (index === 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                  (i === 0)
                                    ? (_openBlock(), _createBlock(_component_PublicationsWorks, { key: 0 }))
                                    : (i === 1)
                                      ? (_openBlock(), _createBlock(_component_OtherWorks, { key: 1 }))
                                      : _createCommentVNode("", true)
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                  _createVNode(_component_CommonDownloadFile, {
                                    label: "Прикрепить файл",
                                    file: "#"
                                  })
                                ]))
                        ]),
                        _: 2
                      }, 1032, ["label"])),
                  (
              i !== dropdown.subLabelsList.length - 1 &&
              dropdown.subLabelsList.length > 1
            )
                    ? (_openBlock(), _createElementBlock("hr", _hoisted_6))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["label"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}