<template>
  <div class="profileDpoChangePass">
    <div class="profileDpoChangePass__inputsBox">
      <CommonInput>Логин</CommonInput>

      <CommonInput type="password">Текущий пароль</CommonInput>

      <CommonInput type="password">Новый пароль</CommonInput>

      <CommonInput type="password">Подтвердите новый пароль</CommonInput>
    </div>

    <CommonButtonPrimary>Сохранить новый пароль</CommonButtonPrimary>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CommonInput } from "@/components/CommonInput";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";

export default defineComponent({
  name: "ProfileDpoChangePass",

  components: {
    CommonInput,
    CommonButtonPrimary,
  },
});
</script>

<style lang="stylus" scoped>
.profileDpoChangePass {
  &__inputsBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px 22px;
    margin: 32px 0;
  }
}
</style>
