<template>
  <div class="commonDropdown-wrap">
    <div class="commonDropdown">
      <div class="commonDropdown__container" @click="setDropdownShown">
        <div class="commonDropdown__titleBox">
          <h6 class="commonDropdown__title">{{ label }}</h6>
        </div>

        <svg class="commonDropdown__arrow icon-grey" v-if="isShownDropdown">
          <use
            xlink:href="#arrow-dropdown-top-icon--sprite"
            class="icon-grey"
          />
        </svg>

        <svg class="commonDropdown__arrow icon-grey" v-else>
          <use
            xlink:href="#arrow-dropdown-bottom-icon--sprite"
            class="icon-grey"
          />
        </svg>
      </div>

      <div class="commonDropdown__selectContainer" v-if="isShownDropdown">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType } from "vue";
import type { CommonDropdownProps } from "./types";

import "@/assets/img/icons/arrow-dropdown-top-icon.svg?sprite";
import "@/assets/img/icons/arrow-dropdown-bottom-icon.svg?sprite";

export default defineComponent({
  name: "CommonDropdownSecond",

  props: {
    label: {
      type: String as PropType<CommonDropdownProps["label"]>,
      default: "",
    },
  },

  setup() {
    const isShownDropdown = ref<boolean>(false);

    const setDropdownShown = () => {
      isShownDropdown.value = !isShownDropdown.value;
    };

    return {
      isShownDropdown,
      setDropdownShown,
    };
  },
});
</script>

<style lang="stylus">
.v-popper__arrow-container {
  display: none;
}
</style>

<style lang="stylus" scoped>
.commonDropdown-wrap {
  align-items: flex-start;
}
.commonDropdown {
  position: relative;
  cursor: pointer;
  width: 100%;

  &__title {
    font-weight: bold;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding-bottom: 10px;
  }

  &__arrow {
    width: 33px;
    height: 21px;
  }

  // &__selectContainer {
  //   width: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   padding-top: 10px;
  //   position: relative;
  //   top: -10px;
  //   border-radius: 10px;
  //   background-color: $colorFiller;
  // }
}
</style>
