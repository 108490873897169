<template>
  <div>
    <CommonEducationDpoCourse :title="title" :subTitle="subTitle">
      <h2 class="homeworkCrossword__title main-color-text">Домашнее задание</h2>

      <h2 class="homeworkCrossword__title">
        Задание: заполните пропуски в тексте
      </h2>

      <p class="homeworkCrossword__text">{{ pageQuestion }}</p>

      <CommonCrossword :words="words" :questions="questions" />

      <CommonButtonPrimary class="homeworkCrossword__btn">
        Отправить ответ
      </CommonButtonPrimary>

      <CommonHomeworkStatus />
    </CommonEducationDpoCourse>
  </div>
</template>

<script type="ts">
import { defineComponent } from "vue";
import { CommonCrossword } from "@/components/CommonCrossword";
import { CommonEducationDpoCourse } from "@/components/CommonEducationDpoCourse";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { CommonHomeworkStatus } from "@/components/CommonHomeworkStatus";
import { title, subTitle, pageQuestion,  words, questions } from "./constants";

export default defineComponent({
  name: "HomeworkCrossword",

  components: {
    CommonCrossword,
    CommonEducationDpoCourse,
    CommonButtonPrimary,
    CommonHomeworkStatus,
  },

  setup() {

    return {
      title,
      subTitle,
      pageQuestion,
      words,
      questions,
    };
  },
});
</script>

<style lang="stylus" scoped>
.homeworkCrossword {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__secondTitle {
    margin-top: 15px;
  }

  &__title,
  &__text {
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__task {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }

  &__input {
    padding: 9px 18px;
    background-color: transparent;
    border-radius: 30px;
    border: 2px solid $colorDpoGrey;
    color: $colorDpoGrey;
  }

  &__btn {
    width: fit-content;
    margin: 0 auto;
  }

  +mediaTablet() {
    gap: 4vw;

    &__secondTitle,
    &__btn {
      margin-top: 1.5vw;
    }

    &__textForVideo {
      padding: 2vw;
    }
  }
}
</style>
