<template>
  <LayoutBase>
    <CommonTabs
      :tabs-list="tabsList"
      :current-tab="currentTab?.value || 'info'"
      @change-tab="changeTab"
    />

    <component class="profilePage__content" :is="currentTab?.component" />
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { prop } from "rambda";
import { tabsList } from "./constants";
import { LayoutBase } from "@/layouts/LayoutBase";
import { CommonTabs } from "@/components/CommonTabs/index";

export default defineComponent({
  name: "ProfilePage",

  components: {
    LayoutBase,
    CommonTabs,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const tab = computed(() => {
      const { tab = "" } = route.query;

      if (tabsList.map(prop("value")).includes(String(tab))) {
        return String(tab);
      }

      return "info";
    });

    const currentTab = computed(() =>
      tabsList.find(({ value }) => value === tab.value)
    );

    const changeTab = (name: string) => {
      router.push({ path: route.path, query: { tab: name } });
    };

    return {
      tabsList,
      currentTab,
      changeTab,
    };
  },
});
</script>

<style lang="stylus" scoped>
.profilePage {
  &__content {
    padding: 50px 0 80px 0;

    +mediaTablet() {
      padding: 5vw 0 8vw 0;
    }
  }
}
</style>
