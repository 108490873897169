export const chatsList = [
  {
    label: "1 семестр",
    chats: [
      {
        title: "Математическое и компьютерное моделирование",
        id: 1,
      },
      {
        title: "Технологии и средства разработки программного обеспечения",
        id: 2,
      },
      {
        title: "Анализ и синтез информационных систем",
        id: 3,
      },
      {
        title: "Модели и методы искусственного интеллекта",
        id: 4,
      },
      {
        title: "Основы менеджмента и маркетинга в медицинской организации",
        id: 5,
      },
      {
        title: "Компьютерное моделирование биологических систем",
        id: 6,
      },
    ],
  },
  {
    label: "2 семестр",
    chats: [
      {
        title: "Математическое и компьютерное моделирование",
        id: 1,
      },
      {
        title: "Технологии и средства разработки программного обеспечения",
        id: 2,
      },
      {
        title: "Анализ и синтез информационных систем",
        id: 3,
      },
      {
        title: "Модели и методы искусственного интеллекта",
        id: 4,
      },
      {
        title: "Основы менеджмента и маркетинга в медицинской организации",
        id: 5,
      },
      {
        title: "Компьютерное моделирование биологических систем",
        id: 6,
      },
    ],
  },
  {
    label: "3 семестр",
    chats: [
      {
        title: "Математическое и компьютерное моделирование",
        id: 1,
      },
      {
        title: "Технологии и средства разработки программного обеспечения",
        id: 2,
      },
      {
        title: "Анализ и синтез информационных систем",
        id: 3,
      },
      {
        title: "Модели и методы искусственного интеллекта",
        id: 4,
      },
      {
        title: "Основы менеджмента и маркетинга в медицинской организации",
        id: 5,
      },
      {
        title: "Компьютерное моделирование биологических систем",
        id: 6,
      },
    ],
  },
  {
    label: "4 семестр",
    chats: [
      {
        title: "Математическое и компьютерное моделирование",
        id: 1,
      },
      {
        title: "Технологии и средства разработки программного обеспечения",
        id: 2,
      },
      {
        title: "Анализ и синтез информационных систем",
        id: 3,
      },
      {
        title: "Модели и методы искусственного интеллекта",
        id: 4,
      },
      {
        title: "Основы менеджмента и маркетинга в медицинской организации",
        id: 5,
      },
      {
        title: "Компьютерное моделирование биологических систем",
        id: 6,
      },
    ],
  },
];

export const chat = [
  {
    day: "25.04.2024",
    messages: [
      {
        sender: 0,
        message: "1231",
        time: "14:22",
      },
      {
        sender: 1,
        message: "22222331",
        time: "14:22",
      },
    ],
  },
  {
    day: "26.04.2024",
    messages: [
      {
        sender: 0,
        message: "1231safadsfasd",
        time: "14:22",
      },
      {
        sender: 1,
        message: "22222331test",
        time: "14:22",
      },
    ],
  },
];
