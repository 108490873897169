import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "graduationWork" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonDownloadFile = _resolveComponent("CommonDownloadFile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputLabelsList, (input) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "graduationWork__inputBox",
        key: input
      }, [
        _createVNode(_component_CommonInput, {
          placeholder: "Напишите ваш ответ",
          class: "graduationWork__input"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(input), 1)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_CommonDownloadFile, {
          label: "Прикрепить файл",
          file: "#"
        })
      ]))
    }), 128))
  ]))
}