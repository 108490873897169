<template>
  <div class="otherWorks">
    <div
      class="otherWorks__inputBox"
      v-for="input in inputLabelsList"
      :key="input"
    >
      <CommonInput placeholder="Напишите ваш ответ" class="otherWorks__input">
        {{ input }}
      </CommonInput>

      <CommonDownloadFile label="Прикрепить файл" file="#" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CommonInput } from "@/components/CommonInput";
import { CommonDownloadFile } from "@/components/CommonDownloadFile";
import { inputLabelsList } from "./constants";

export default defineComponent({
  name: "OtherWorks",

  components: {
    CommonInput,
    CommonDownloadFile,
  },

  setup() {
    return {
      inputLabelsList,
    };
  },
});
</script>

<style lang="stylus">
.otherWorks {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;

  &__inputBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  +mediaTablet() {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 3vw 0;
    gap: 3vw;

    &__inputBox {
      width: 100%;
      gap: 1.1vw;
    }
  }
}
</style>
